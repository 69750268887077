import React from 'react'
import Header from '../Layouts/Header'
import Footer from '../Layouts/Footer'
//AboutUs component


const AboutUs = () => {
    return (
        <div>
            <Header />

            <section className="hero-banner hero-banner-height" style={{ backgroundImage: 'url(/assets/images/about-us.jpg)' }}>
                <div className="container">
                    <div className="row">
                        <div className="hero-banner-aboutus hero-banner-content">
                            <h2 className="heading-white">
                                About Us

                            </h2>
                            <p className="desc-white pt-4 text-center">
                                Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sports-list-wrap bg-grey ptb-90 about-us">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="heading-grey">
                                Get To Know More About Bussiness</h2>
                            <p className="desc-grey pt-4">
                                Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis fermentum justo,
                                sit amet convallis arcu<br />
                                interdum vel. Suspendisse vulputate posuere risus, quis consectetur neque scelerisque.
                            </p>
                        </div>
                        <div className="sports-list">
                            <ul>
                                <li className="about-page plr-70">
                                    <div className="col-md-6 col-12">
                                        <div className="pa-right-4 d-service-para pt-5">
                                            <p>
                                                Nam condimentum, nibh in lobortis aliquet, felis enim aliquet turpis, id pretium
                                                mauris nibh a augue. Duis condimentum diam ac enim maximus condimentum. Quisque non
                                                justo dapibus, laoreet mauris at, dictum massa. In eu velit rutrum, rhoncus odio at,
                                                aliquam purus.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="pa-left-4 d-service-para pt-5">
                                            <p>
                                                Nam condimentum, nibh in lobortis aliquet, felis enim aliquet turpis, id pretium
                                                mauris nibh a augue. Duis condimentum diam ac enim maximus condimentum. Quisque non
                                                justo dapibus, laoreet mauris at, dictum massa. In eu velit rutrum, rhoncus odio at,
                                                aliquam purus.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="col-sm-12 pt-4">
                                        <div className="about-gallery-wrp d-flex">
                                            <div className="about-gallery-box left">
                                                <figure className="abt-gall-img"
                                                    style={{ backgroundImage: "url(/assets/images/image-13.png)" }}></figure>

                                            </div>
                                            <div className="about-gallery-box middle">
                                                <div className="abt-middle-tow">
                                                    <div className="middle-img-gallery one">
                                                        <figure className="abt-gall-img"
                                                            style={{ backgroundImage: "url(/assets/images/image-01.png)" }}>
                                                        </figure>
                                                    </div>
                                                    <div className="middle-img-gallery two">
                                                        <figure className="abt-gall-img"
                                                            style={{ backgroundImage: "url(/assets/images/image-06.png)" }}>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="about-gallery-box right">
                                                <figure className="abt-gall-img"
                                                    style={{ backgroundImage: "url(/assets/images/image-14.png)" }}></figure>
                                            </div>
                                        </div>
                                    </div>

                                </li>
                                <li className="about-page plr-70">
                                    <div className="col-md-6 col-12">
                                        <div className="pa-right-4 d-service-para pt-5">
                                            <p>
                                                Donec non ligula ultrices, imperdiet neque sit amet, placerat felis. Interdum et
                                                malesuada fames ac ante ipsum primis in faucibus. Morbi laoreet tortor et lorem
                                                euismod, id feugiat urna egestas. Donec feugiat sagittis mauris auctor sagittis.
                                                Suspendisse vulputate faucibus mi, id molestie nisi venenatis eget. Maecenas at
                                                pretium mi, ut ullamcorper enim. Vestibulum bibendum ut ante vel semper.
                                                Pellentesque suscipit nunc tortor. Nulla ac nunc leo. Nullam placerat mauris et
                                                eleifend ornare. Nunc in dapibus enim. Cras sed erat tincidunt enim eleifend
                                                rhoncus. Praesent tincidunt dictum urna, at venenatis magna consectetur vel.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="pa-left-4 d-service-para pt-5">
                                            <p>
                                                Duis pellentesque in orci eget consectetur. Integer mollis lobortis congue.
                                                Suspendisse sit amet lectus elementum, vulputate metus ut, tincidunt quam. Donec
                                                lacinia dui non magna tempor, at lacinia quam iaculis. Integer fermentum urna id
                                                elementum euismod. Fusce cursus magna urna, sed laoreet ante dignissim id. Integer
                                                mattis, lectus sed viverra commodo, augue neque sagittis erat, id tempor lectus
                                                nulla finibus ex. Ut in odio lorem. In volutpat libero id sagittis vehicula.
                                                Interdum et malesuada fames ac ante ipsum primis in faucibus.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="what-we-section ptb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="heading-grey">
                                We Do What We Love</h2>
                            <p className="desc-grey pt-4">
                                Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis fermentum justo,
                                sit amet convallis arcu<br />
                                interdum vel. Suspendisse vulputate posuere risus, quis consectetur neque scelerisque.
                            </p>
                        </div>
                        <div className="sports-list pt-5 plr-70">
                            <ul>
                                <li>
                                    <div className="col-md-6 col-12">

                                        <div className="what-we-img">
                                            <figure className="p-0">
                                                <img src="assets/images/image-15.png" alt="img" className="img-fluid" />
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="plr-20">
                                            <p className="desc-grey text-justify">
                                                Nam condimentum, nibh in lobortis aliquet, felis enim aliquet turpis, id pretium
                                                mauris nibh a augue. Duis condimentum diam ac enim maximus condimentum. Quisque non
                                                justo dapibus, laoreet mauris at, dictum massa. In eu velit rutrum, rhoncus odio at,
                                                aliquam purus.
                                            </p>
                                            <h4 className="heading-grey-about text-left pt-5">
                                                What is playit</h4>
                                            <p className="pt-4 desc-grey text-justify">
                                                Nam condimentum, nibh in lobortis aliquet, felis enim aliquet turpis, id pretium
                                                mauris nibh a augue. Duis condimentum diam ac enim maximus condimentum. Quisque non
                                                justo dapibus, laoreet mauris at, dictum massa. In eu velit rutrum, rhoncus odio at,
                                                aliquam purus.
                                            </p>
                                            <div className="pt-4">
                                                <div className="desc-list d-flex pt-4 ">
                                                    <div className="float-left label-icon right-icon">
                                                        <span className="material-icons-outlined">
                                                            check_circle
                                                        </span>
                                                    </div>
                                                    <div className="float-right ml-4">
                                                        <p className="desc-grey desc-grey-li">
                                                            Donec non ligula ultrices, imperdiet neque sit amet, placerat felis.
                                                            Interdum et malesuada fames ac ante ipsum primis in faucibus. Morbi
                                                            laoreet tortor et lorem euismod, id feugiat urna egestas.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="desc-list d-flex pt-4">
                                                    <div className="float-left  label-icon right-icon">
                                                        <span className="material-icons-outlined">
                                                            check_circle
                                                        </span>
                                                    </div>
                                                    <div className="float-right ml-4">
                                                        <p className="desc-grey desc-grey-li">
                                                            Donec non ligula ultrices, imperdiet neque sit amet, placerat felis.
                                                            Interdum et malesuada fames ac ante ipsum primis in faucibus. Morbi
                                                            laoreet tortor et lorem euismod, id feugiat urna egestas.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="desc-list d-flex pt-4">
                                                    <div className="float-left label-icon">
                                                        <span className="material-icons-outlined">
                                                            check_circle
                                                        </span>
                                                    </div>
                                                    <div className="float-right ml-4">
                                                        <p className="desc-grey desc-grey-li">
                                                            Donec non ligula ultrices, imperdiet neque sit amet, placerat felis.
                                                            Interdum et malesuada fames ac ante ipsum primis in faucibus. Morbi
                                                            laoreet tortor et lorem euismod, id feugiat urna egestas.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </section>

            <Footer />
        </div>
    )
}

export default AboutUs
