import React, { useEffect, useState } from "react";
import callApi from "../service/callApi";
import serverApi from "../api.json";
import validator from 'validator'
import { toast } from "react-toastify";
import ErrorShow from "./Forms/ErrorShow";
import {useNavigate} from 'react-router-dom'

const Step3 = ({ setStep, mobile,onDone }) => {
  const [password, setPasswordOne] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState(null);
  const [isEye1, setEye1] = useState(false);
  const [isEye2, setEye2] = useState(false);
  const navigate = useNavigate()

  useEffect(()=>{
    return ()=>{
      setConfirmPassword("")
      setPasswordOne("")
      setErrors(null)
    }
  },[]) 

  const setNewPassword = async () => {
    if (!validator.isEmpty("" + password)) {
      if (
        !validator.isLength("" + password, { min: 8 }) ||
        !validator.isLength("" + password, { max: 16 }) ||
        !/[!@#$%^&*?_~]{1,}/.test(password) || !/[a-z]{1,}/.test(password) ||
        !/[A-Z]{1,}/.test(password) || !/[0-9]{1,}/.test(password)
      ) {
        setErrors((s) => ({ ...s, password: "Password must have atleast 8-16 characters, should contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric" }))
        setErrors((s) => ({ ...s, matchPass: "" }))
        return
      } else {
        setErrors((s) => ({ ...s, password: "" }))
      }
    }
    if(!validator.isEmpty(confirmPassword) && !validator.isEmpty(password) && confirmPassword!==password){
      setErrors((s)=>({...s,matchPass:"Password not matched"}))
      return
    }else{
      setErrors((s)=>({...s,matchPass:""}))
    }

    if (password && confirmPassword && (password === confirmPassword)) {
      setErrors(null) 
      let obj = { phone: mobile, password: password };
      try {
        let res= await callApi.post(serverApi.resetPassword, obj)
        if(res?.data?.message=="Customer not found."){
          toast.error("Customer not found.!")
          setStep(1)
        }else if(res?.data?.data==="No customer with this phone no."){
          toast.error("No customer with this phone no.")
          setStep(1)
        }else{
          toast.success("Password changed!")
          setStep(1)
          onDone()
        }
      } catch (e) {
        console.log(e);
        if (e.response.data) {
          toast.error(e.response.data)
        }
      }
    }
  };
  return (
    <div class="d-flex justify-content-center align-items-center">
      <div class="card login-card py-5 px-3">
        <div>
          <h2 class="m-0 text-center modal-heading">Reset your Password</h2>
          <span class=" mobile-text pt-4">
            Please set up a new password by filling the details below.
          </span>

          <form onSubmit={(e)=>e.preventDefault()}>
            <div class="row subscribe-form contact-details">
              <div class="col-md-12 pt-4">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="material-icons-outlined">lock</span>
                  </div>
                  <input
                    type={isEye1?'text':'password'}
                    style={{zIndex:0}}
                    class="form-control pl-value"
                    value={password}
                    placeholder="New Password"
                    onChange={(e) => setPasswordOne(e.target.value)}
                    required
                  />
                </div>
                <ErrorShow errorMsg={errors?.password} />
              </div>
              <div
                 style={{zIndex:999}}
                    class="input-group-append password"
                    onClick={()=>setEye1(!isEye1)}
                  >
                    <span
                      class="material-icons-outlined"
                      id="togglePassword"
                      style={{ marginRight: "27px", cursor: "pointer" }}
                    >
                      {isEye1 ? 'visibility' : 'visibility_off'}
                    </span>
                  </div>
              <div class="col-md-12 pt-4">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="material-icons-outlined">lock</span>
                  </div>
                  <input
                    type={isEye2?'text':'password'}
                    style={{zIndex:0}}
                    value={confirmPassword}
                    class="form-control"
                    placeholder="Confirm Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>
                <ErrorShow errorMsg={errors?.matchPass} />
              </div>
              <div
               style={{zIndex:999}}
                    class="input-group-append password"
                    onClick={()=>setEye2(!isEye2)}
                  >
                    <span
                      class="material-icons-outlined"
                      id="togglePassword"
                      style={{ marginRight: "27px", cursor: "pointer" }}
                    >
                       {isEye2 ? 'visibility' : 'visibility_off'}
                    </span>
                  </div>
            </div>
            <div class=" btn-wrap col-auto pt-4">
              <button
              disabled={(!password && !confirmPassword)}
                type="submit"
                class="red-btn-lg hvr-icon-push contact-button profile-btn"
                onClick={setNewPassword}
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Step3;
