import React from "react";
import {Modal,ModalBody} from 'reactstrap'


const LogoutModal = ({handleToggle,isModal,OkCb}) => {


    return (
        <Modal isOpen={isModal} toggle={handleToggle}>
            
            <ModalBody>
                <button
                type="button"
                className="close close-icon"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleToggle}
                >
                <span className="material-icons-outlined">
                    close
                </span>
                </button>

                <div class="d-flex justify-content-center align-items-center container">
                    <div class="card login-card py-5 px-3">
                                <h2 class="m-0 text-center modal-heading">Are you sure you want to logout?</h2>
                                <span class="mobile-text pt-4">Phasellus blandit, mauris eget
                                    pharetra tincidunt</span>

                                <div class="btn-wrap col-auto pt-4">
                                    <button type="submit" onClick={handleToggle} class="red-btn-lg hvr-icon-push common-btn">No</button>
                                    <button type="submit" onClick={OkCb} class="hvr-icon-push common-btn btn-yes ml-3">Yes</button>
                                </div>

                       </div>
                   </div>

            </ModalBody>
        </Modal>
    )
}

export default LogoutModal
