import React, { useEffect, useState } from "react";
import OtpModel from "./otpModal";
import callApi from "../service/callApi";
import serverApi from "../api.json";
const ForgotPass = () => {
  const [mobileNumber, setMobile] = useState("");
  const [isModalClosed, setCloseModalStatus] = useState("");
  const [targetValue, setTargetValue] = useState("");
  const resetMobile = () => {
    setMobile("");
  };
  const sendOtp = async () => {
    let otp;
    otp = await callApi.post(serverApi.sendOtp, {
      phone: mobileNumber,
    });
    let veri = false;
    // console.log(veri);

    if (veri) {
      // console.log("a");
      setCloseModalStatus((previousCount) => "modal");
      setTargetValue((previousCount) => "#otp-modal");
    }
  };
  useEffect(() => {
    setCloseModalStatus((previousCount) => "modal");
    setTargetValue((previousCount) => "#otp-modal");
  }, [isModalClosed, targetValue]);

  useEffect(() => {}, []);
  return (
    <React.Fragment>
      <div
        class="modal fade"
        id="forgot-pwd"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <button
              type="button"
              class="close-icon close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={resetMobile}
            >
              <span class="material-icons-outlined">close</span>
            </button>

            <div class="modal-body">
              <div class="d-flex justify-content-center align-items-center">
                <div class="card login-card py-5 px-3">
                  <h2 class="m-0 text-center modal-heading">
                    Forgot password?
                  </h2>
                  <span class="mobile-text pt-4">
                    Enter your Registered mobile number below to recieve an
                    OTP(One time password)
                  </span>
                  <div class="subscribe-form contact-details">
                    <div class="col-md-12 pt-4">
                      <div class="forgot-number">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="+91 8668483312"
                          value={mobileNumber}
                          onChange={(e) => setMobile(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div class=" btn-wrap col-auto pt-4">
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target={targetValue}
                      class="red-btn-lg hvr-icon-push contact-button profile-btn"
                      data-dismiss={isModalClosed}
                      aria-label="Close"
                      onClick={sendOtp}
                    >
                      Send
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OtpModel mobileNumber={mobileNumber} />
    </React.Fragment>
  );
};
export default ForgotPass;
