/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Footer from '../Layouts/Footer';
import Header from '../Layouts/Header';
import SportItem from './SportItem';
import callApi from '../../service/callApi'
import serverApi from '../../api.json'

const FavoriteSports = () => {

    const [sports, setSports] = useState([])
    const [isLoading, setLoader] = useState(false)
    useEffect(async () => {
        setLoader(true)
        await callApi.get(`${serverApi?.getfavoritesports}`)
            .then(({ data }) => {
                setLoader(false)

                if (data && data?.favoriteSports?.length !== 0) {
                    setSports(data?.favoriteSports)
                } else {
                    setSports([])
                }
            }).catch((e) => {
                setLoader(false)
                console.log(e)
            })
    }, [])

    return (
        <div>
            <Header />
            <section className="hero-banner hero-banner-height" style={{ backgroundColor: 'black' }}>
                <div className="container">
                    <div className="row">
                        <div className="hero-banner-aboutus hero-banner-content">
                            <h2 className="heading-white">
                                Favorite Sports

                            </h2>
                            <div className="desc-white pt-4">
                                <p className="text-center">
                                    Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis fermentum justo,
                                    sit amet convallis arcu
                                    interdum vel. Suspendisse vulputate posuere risus, quis consectetur neque scelerisque.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="sports-list-wrap bg-grey fav-sports-sec">
                <div className="container">
                    <div className="row">

                        <div className="sports-list">
                            <div className="row ptb-60">
                                {isLoading ? (
                                    <div className='mt-10' style={{ display: 'block', margin: 'auto' }}>
                                        <div class="spinner-border" role="status" style={{ padding: 30 }}>
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : sports?.length > 0 ? sports?.map((sport, key) => <SportItem key={key} sport={sport} />) : <p className="text-center">No Sports yet!</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
};

export default FavoriteSports;
