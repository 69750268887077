import Validator from "validator";
import moment from 'moment'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {CUSTOMER_LINK,GUEST_LINK} from '../config'

export const validate = (fields, validationRules) => {
  let errors = {},
    isValid = true;
  validationRules.forEach((obj) => {
    const { field, validations } = obj;
    for (let i = 0; i < validations.length; i++) {
      const rule = validations[i].split(":");
      switch (rule[0]) {
        case "numeric":
          if (
            fields[field] &&
            !Validator.isEmpty("" + fields[field]) &&
            !Validator.isNumeric("" + fields[field])
          ) {
            errors[field] = `${obj.name} must be numeric.`;
            isValid = false;
            continue;
          }
          break;
        case "alpha":
          if (
            !Validator.isEmpty("" + fields[field]) &&
            !Validator.isAlpha("" + fields[field])
          ) {
            errors[field] = `${obj.name} must be alphabetic.`;
            isValid = false;
            continue;
          }
          break;
        case "email":
          if (
            fields[field] &&
            !Validator.isEmpty("" + fields[field]) &&
            !Validator.isEmail("" + fields[field])
          ) {
            errors[field] = `${obj.name} is not a valid email.`;
            isValid = false;
            continue;
          }
          break;
        case "digit":
          const numOfDigits = 10;
          if (
            fields[field] &&
            !Validator.isEmpty("" + fields[field]) &&
            !Validator.isLength("" + fields[field], {
              min: numOfDigits,
              max: numOfDigits,
            })
          ) {
            errors[field] = `${obj.name} must be of ${numOfDigits} digits.`;
            isValid = false;
            continue;
          }
          break;
        case "accdigit":
          const numOfaccDigits = 16;
          if (
            fields[field] &&
            !Validator.isEmpty("" + fields[field]) &&
            !Validator.isLength("" + fields[field], {
              min: 16,
              max: 16,
            })
          ) {
            errors[field] = `${obj.name} must be of ${numOfaccDigits} digits.`;
            isValid = false;
            continue;
          }
          break;
        case "cvv":
          const cvvDigits = 16;
          if (
            fields[field] &&
            !Validator.isEmpty("" + fields[field]) &&
            !Validator.isLength("" + fields[field], {
              min: 3,
              max: 4,
            })
          ) {
            errors[field] = `${obj.name} must be of ${cvvDigits} digits.`;
            isValid = false;
            continue;
          }
          break;
        case "password":
          if (!Validator.isEmpty("" + fields[field])) {
            if (
              !Validator.isLength("" + fields[field], { min: 6 }) ||
              !Validator.isLength("" + fields[field], { max: 16 })
            ) {
              errors[field] = `${obj.name} must have atleast 6-16 characters`;
              isValid = false;
              continue;
            }
          }
          break;
        case "conf_password":
          if (
            !Validator.equals(
              "" + fields[field],
              "" + fields[field + "Confirm"]
            )
          ) {
            errors[field] = `${obj.name} did not match`;
            isValid = false;
            continue;
          }
          break;
        case "Expiry":
          if (Validator.isEmpty("" + fields[field])) {
            errors[field] = `${obj.name} is missing.`;
            isValid = false;
            continue;
          }
          break;
        case "alphanumeric":
          if (
            !Validator.isEmpty("" + fields[field]) &&
            !Validator.isAlphanumeric("" + fields[field])
          ) {
            errors[field] = `${obj.name} must be alpha numeric.`;
            isValid = false;
            continue;
          }
          break;
        case "required":
        default:
          if (Validator.isEmpty("" + fields[field])) {
            errors[field] = `${obj.name} is required.`;
            isValid = false;
            continue;
          }
          break;
        case 'alphaLen':
            if (Validator.isAlpha('' + fields[field]) && (""+fields[field]).trim()?.length!==0 && (""+fields[field]).trim()?.length<3) {
              errors[field] = `${obj.name} must have atleast 3-20 characters`;
              isValid = false;
              continue;
            }else if(Validator.isAlpha('' + fields[field]) && (""+fields[field]).trim()?.length>=20){
              errors[field] = `${obj.name} must have atleast 3-20 characters`;
              isValid = false;
              continue;
            }
            break;
      }
    }
  });

  return { isValid, errors };
}

export let isAuth = ()=>{
    return JSON.parse(localStorage.getItem("userData"))?.token?true:false
}

export let Logout = (cb)=>{
  if(isAuth()){
    localStorage.clear()
  }
}

export let getUserInfo = ()=>{
  return JSON.parse(localStorage.getItem("userData"))?.token?JSON.parse(localStorage.getItem("userData")):false
}

export let baseUrl=()=>{
  return isAuth()?`${CUSTOMER_LINK}`:`${GUEST_LINK}`
}

export let getCheckoutInfo = ()=>{
  return JSON.parse(localStorage.getItem("booking_checkout"))?.serviceClassId?JSON.parse(localStorage.getItem("booking_checkout")):false
}

export const isInArray=(array, value)=>{
  return !!array.find(item => {return moment(item).format('DD-MM-YYYY') == moment(value).format('DD-MM-YYYY')});
}

export const parseParams = (params = "") => {
  const rawParams = params.replace("?", "").split("&");
  const extractedParams = {};
  rawParams.forEach((item) => {
    item = item.split("=");
    extractedParams[item[0]] = item[1];
  });
  return extractedParams;
};

export const scrollNow =(name="scroll1")=>{
  var element = document.getElementById(name);
  element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
}
const convertTime12to24 = (time12h) => moment(time12h, 'hh:mm A').format('HH');

export const getTimeDate = (time)=>{
  const hours = parseInt(time?.split(":")[0])
  const min = parseInt(time?.split(":")[1])
  const myDate = new Date(2022,0,1,hours,min)
 return myDate
}

export const GettingTime=(time)=>{
    const currentHour = parseInt(convertTime12to24(time));
    console.log("currentHour",currentHour)
    return currentHour < 12 ? 'Morning' : currentHour < 18 ? 'Afternoon' : 'Evening';;
}


export const daysArray = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
]

export const downloadHTMLPDF = async(divId)=>{
  try{
    await html2canvas(document.querySelector("#"+divId)).then(canvas => {
      document.body.appendChild(canvas);  // if you want see your screenshot in body.
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save("download.pdf"); 
      canvas.style.display = 'none'
  })
  }catch(e){
    console.log('Error while downloading',e)
  }
}