/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import serverApi from "../../../api.json";
import callApi from "../../../service/callApi";
import FacilitieItem from './FacilitieItem';
import { Link, useNavigate } from 'react-router-dom';

const GuestFacilities = ({ filter }) => {
    const navigate = useNavigate()
    const [facilities, setFacilities] = useState([])
    const [isLoading, setLoader] = useState(false)

    useEffect(async () => {
        setLoader(true)
        await callApi.get(`${serverApi.getFacilities}?category=&city=&limit=${6}`)
            .then((res) => {
                setLoader(false)
                if (res?.data && res?.data?.data?.length > 0) {
                    setFacilities(res?.data?.data)
                } else {
                    setFacilities([])
                }
            }).catch((e) => {
                setLoader(false)
                console.log(e)
            })
    }, [])

    const GoToList = () => {
        let url = `/search_listing`
        // if(filter?.category){
        //     url = `${url}?category=${filter?.category}`
        // }
        navigate(url)
    }

    return (
        <React.Fragment>
            <div class="col-md-12">
                <h2 class="heading-grey">
                    Quisque Viverra Molestie
                </h2>
                <p class="desc-grey pt-4">
                    Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis fermentum justo, sit amet convallis arcu<br />
                    interdum vel. Suspendisse vulputate posuere risus, quis consectetur neque scelerisque.
                </p>
            </div>
            <div class="sports-list">
                <div class=" ptb-60">
                    {isLoading ? (
                        <div className="m-auto">
                            <div class="spinner-border p-5" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : facilities?.length > 0 ? facilities?.map((facility, index) => <FacilitieItem key={index} category={filter?.category} facility={facility} />) : (
                        <div className='col-lg-12' style={{ textAlign: 'center' }}>
                            <p class="desc-grey pt-4">No result.</p>
                        </div>
                    )}
                </div>
                <div class="btn-wrap">
                    <button class="viewall-btn red-btn-lg common-btn" onClick={() => GoToList()}>View More</button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default GuestFacilities
