import React from 'react'
import { Link } from 'react-router-dom'
import api from '../../../api.json'

const FacilitieItem = ({category,facility}) => {
    return (
            <div class="col-md-4 mb-4">
            <Link to={{pathname:`/search_listing?category=${facility?.categories?.length>0?facility?.categories[0]?.Category:category}&facility=${facility?._id}`,state:{A:1}}}>
                <div class="sports-list-card">
                    <div class="sports-img">
                        <figure>
                            <img src={(facility?.Images && facility?.Images?.length>0 && facility?.Images[0]) ? `${api?.base_url}/${facility?.Images[0]}` :"/assets/images/image-01.png"} alt="img" class="img-fluid" />
                        </figure>
                    </div>
                    <div class="sports-info">
                        <h3 class="sports-name">{facility?.categories?.length>0?facility?.categories[0]?.Category:category}</h3>
                        <p class="sports-location">{facility?.Name}</p>
                    </div>
                </div>
                </Link>
            </div>
    )
}

export default FacilitieItem
