/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import serverApi from '../../api.json'
import callApi from '../../service/callApi'
import { toast } from 'react-toastify'
import Loader from '../Layouts/Loader'
import CardItem from '../Checkout/cardItems'

const CardModal = ({ isModal, handleToggle,onCardSelect }) => {

    const [isLoader, setLoading] = useState(false)
    const [cards, setMyCard] = useState([])

    useEffect(async () => {
        setLoading(true)
        await callApi.get(serverApi?.getMyCards)
            .then((res) => {
                setLoading(false)
                if (res && res?.data && res?.data?.cardsList?.length > 0) {
                    setMyCard(res?.data?.cardsList)
                } else {
                    setMyCard([])
                }
            }).catch((e) => {
                setLoading(false)
                if (e?.response && e?.response?.data?.error) {
                    toast.error(e?.response?.data?.error)
                } else {
                    toast.error("Error! while processing your request")
                }
            })
    }, [])

    // const Container = ({ children }) => {
    //     return (
    //         <div className="card-div" style={{marginTop:1}}>
    //             <div className="row">
    //             {children}
    //             </div>
    //         </div>
    //     )
    // }

    return (
        <Modal isOpen={cards?.length>0 && isModal} toggle={handleToggle} style={{ width: '100%' }}>
            <ModalBody>
                <button
                    type="button"
                    class="close-icon close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleToggle}
                >
                    <span class="material-icons-outlined">close</span>
                </button>
               <div className="container-fluid">
               <div style={{display:'block',margin:'auto'}}>
                   <div className="row">
                   <div className="top-section">
                        <h4 className="heading-grey prof-heading text-center">Card Details</h4>
                        <p className="desc-grey text-center pt-4">Choose your card given below.</p>
                    </div>
                   {
                            isLoader ? <div className="col-md-12 pt-4"><Loader /></div> : cards.map((card, key) => <CardItem key={key} onCardSelect={onCardSelect} card={card} />)
                    }
                   </div>
                   </div>
               </div>
            </ModalBody>
        </Modal>
    )
}

export default CardModal