/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import serverApi from "../../../api.json";
import callApi from "../../../service/callApi";
import BookingCard2 from './BookingCard2';
import {useNavigate} from 'react-router-dom'

const ProtectedHome = () => {

    const [booking, setBooking] = useState([])
    const [isLoading, setLoader] = useState(false)
    const navigate = useNavigate()
    useEffect(async () => {
        setLoader(true)
        await callApi.get(`${serverApi.previousBooking}?name=&city=&limit=${3}`)
            .then((res) => {
                setLoader(false)
                if (res?.data.data && res?.data?.data?.length > 0) {
                    setBooking(res?.data?.data)
                } else {
                    setBooking([])
                }
            }).catch((e) => {
                setLoader(false)
                console.log(e)
            })
    }, [])

    return (
        <div className='container'>
            <div className="row">
                <div class="col-md-12">
                    <div class="top-section">
                        <h2 class="heading-grey">
                            Previous Booking
                        </h2>
                        <p class="desc-grey pt-4">
                            Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis fermentum justo, sit amet convallis arcu<br />
                            interdum vel. Suspendisse vulputate posuere risus, quis consectetur neque scelerisque.
                        </p>
                    </div>
                </div>

                <div class="sports-list booking-list">
                    <div class="row ptb-70-90">
                    {isLoading ? (
                                <div className="m-auto">
                                    <div class="spinner-border p-5" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : booking?.length > 0 ? booking?.map((b, index) => <BookingCard2 key={index} info={b} />) : <div className='col-lg-12' style={{ textAlign: 'center' }}>
                                <p class="desc-grey pt-4">No booking available.</p>
                            </div>}
                    </div>
           <div class="btn-wrap" >
               <button class="viewall-btn common-btn red-btn-lg" onClick={()=>navigate("/my_booking")}>View All Previous Booking</button>
           </div>
       </div>

            </div>
        </div>
    )
}

export default ProtectedHome
