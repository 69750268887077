import React from 'react'
import MailchimpSubscribe from "react-mailchimp-subscribe"
import SubscribeForm from './SubcribeForm'
import {toast}  from 'react-toastify'

const BottomSubscribe = () => {



    return (
        <section class="subscribe-wrap bg-grey ptb-90">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="subscribe-content">
                                <span class="title-grey">Fill Out And Send The Form</span>
                                <h2 class="heading-grey">
                                    Subscribe For Updates
                                </h2>
                                <p class="desc-grey pt-4">
                                    Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis fermentum justo, sit amet convallis arcu<br />
                                    interdum vel. Suspendisse vulputate posuere risus, quis consectetur neque scelerisque.
                                </p>
                            </div>
                            {/* form starts */}
                            <MailchimpSubscribe
                                url={"https://gmail.us14.list-manage.com/subscribe/post?u=a4408e5554f6831ced6b1172f&amp;id=37d91865c2"}
                                render={({ subscribe, status, message }) => (
                                <div>
                                    <SubscribeForm onSubmitted={formData => subscribe(formData)} />
                                    {status === "sending" && <div style={{ color: "blue" }} onLoad={()=>toast.warn("sending")}>sending...</div>}
                                    {status === "error" && <div style={{ color: "red" }} onLoad={()=>toast.error("Can not subcribe, Please try later")} dangerouslySetInnerHTML={{__html: message}}/>}
                                    {status === "success" && <div style={{ color: "green" }} onLoad={()=>toast.success("Subscribed!")}>Subscribed !</div>}
                                </div>
                                )}
                            />
                            {/* form ends */}
                        </div>
                    </div>
                </div>
            </section>
    )
}

export default BottomSubscribe
