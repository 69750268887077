import React from 'react';

const Loader = () => {
  return <div style={{display:'block',marginLeft:'auto',marginRight:'auto'}}>
      <div class="spinner-border" role="status" style={{padding:30}}>
          <span class="sr-only">Loading...</span>
      </div>
  </div>;
};

export default Loader;
