import React, { useEffect, useState } from "react";
import {Modal,ModalBody,ModalHeader} from 'reactstrap'
import callApi from "../../service/callApi";
import serverApi from "../../api.json";
import { useNavigate } from "react-router";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { toast } from "react-toastify";
import validator from 'validator'

const ProfileOTPModal = ({data,handleToggle,isModal,resendOtp,doneCB}) => {

  let [otp1, setOtp1] = useState("");
  let [otp2, setOtp2] = useState("");
  let [otp3, setOtp3] = useState("");
  let [otp4, setOtp4] = useState("");
  const [areaOfModal, setAreaModal] = useState(true);
  const [optError, setOtpError] = useState("");
  const [errorOtp, setErrorOtp] = useState("");
  const [successfully, setSuccess] = useState(false);
  const [successCreateCustomer, setSuccessMessage] = useState("");
  const [failedCreationCustomer, setFailedMessage] = useState("");

  const [counter, setCounter] = useState(60);
  const navigate = useNavigate();

  useEffect(() => {
    
    // const timer =
    //   counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    // return () => clearInterval(timer);
  
    if(!isModal){
      setCounter(0)
      // console.log("Timer Stopped")
    }else{
      setCounter(60)
      // console.log("Timer started")
    }
    return ()=>{
      setCounter(0)
      setOtp1("");
      setOtp2("");
      setOtp3("");
      setOtp4("");
      // console.log("Timer Stopped")
    }
  }, [isModal]);


  const otpVerification = (e, index) => {
    // console.log(e, index);
    if(validator.isNumeric(e+"")){
    if (index == 1) {
      setOtp1(e);
    } else if (index == 2) {
      setOtp2(e);
    } else if (index == 3) {
      setOtp3(e);
    } else {
      setOtp4(e);
    }
  }else{
    if (index == 1) {
      setOtp1("");
    } else if (index == 2) {
      setOtp2("");
    } else if (index == 3) {
      setOtp3("");
    } else {
      setOtp4("");
    }
  }
  };

  const verifyOtp = async () => {
    let otp = otp1 + otp2 + otp3 + otp4;
    if(!validator.isEmpty(otp1+"") &&  !validator.isEmpty(otp2+"") && !validator.isEmpty(otp3+"") && !validator.isEmpty(otp4+"")){
    let obj = { phone: data.phone, otp: otp, type: "Forgot" };
    let verification = await callApi.post(serverApi.verifyOtp, obj);
    if (verification.data.data == "OTP successfully verified") {
        toast.success(verification.data.data)
        doneCB()
    } else if(verification.data.data==null||verification.data.message=='jwt expired'){
        toast.error("OTP is expired, please resend the otp.")
        setCounter(0)
    } else if(verification.data.data=='OTP is incorrect'){
      toast.error("OTP is incorrect")

    }
   }else{
    toast.error("OTP is required")
   }
  };

  const resendOtp2 = () => {
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    setErrorOtp("");
    setOtpError(false);
    setSuccess(false);
    setSuccessMessage("");
    setFailedMessage("");
  };

  const resetOtp2 = () => {
    resendOtp();

    // navigate("/home");
  };
  useEffect(() => {}, []);

  const inputfocus = (elmnt)=>{
    if (elmnt.key === "Delete" || elmnt.key === "Backspace" || elmnt.key==="ArrowLeft") {
     if (elmnt.target.previousSibling) {
       elmnt.target.previousSibling.focus();
      }
    }
    else if(elmnt.key==="ArrowLeft"){
      if (elmnt.target.nextSibling) {
          elmnt.target.nextSibling.focus();
      }
    }else{
      if (elmnt.target.nextSibling) {
        elmnt.target.nextSibling.focus();
     }
    }
  }

    return (
        // <Modal isOpen={isModal} toggle={handleToggle}>
        <Modal isOpen={isModal} toggle={handleToggle}>
            
            <ModalBody>
            <button
              type="button"
              className="close close-icon"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleToggle}
            >
              <span className="material-icons-outlined">
                close
              </span>
            </button>
              <div>
                {data.phone == "" ? (
                  <h5>Please enter your full details or your mobile number</h5>
                ) : (
                  <div className="modal-body">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="card login-card py-5 px-3">
                        {failedCreationCustomer ? (
                          <h5 className="m-0 text-center modal-heading text-danger">
                            {failedCreationCustomer}
                          </h5>
                        ) : null}
                        {successfully ? null : (
                          <h5 className="m-0 text-center modal-heading">
                            Enter Your Verification code
                          </h5>
                        )}

                          <div>
                            <span className="mobile-text text-center">
                              We sent it on your mobile phone
                              <p className="text-dark pt-4 text-center">
                                <strong>{data.phone}</strong>
                                <span style={{float:'right'}}>
                              {/* timer */}
                            {
                              counter!=0 && (
                                <CountdownCircleTimer
                                onComplete={()=>setCounter(0)}
                                size={40}
                                key={counter}
                                strokeWidth={4}
                                isPlaying={counter!==0}
                                duration={60}
                                colors={[
                                  ['#004777', 0.33],
                                  ['#F7B801', 0.33],
                                  ['#A30000', 0.33],
                                ]}
                              >
                              {({ remainingTime }) => {
                                return remainingTime
                              }}
                      </CountdownCircleTimer>
                              )
                            }
                              {/* timerEnd */} 
                           </span>
                              </p>
                            </span>
                            
                            <div style={{marginLeft:'auto',marginRight:'auto',display:'auto'}}>
                              <div className="d-flex flex-row pt-4 text-center otp-input">

                           

                                <input
                                  type="text"
                                  className="form-control text-center "
                                  placeholder="0"
                                  autoFocus=""
                                  maxLength="1"
                                  minLength={1}
                                  value={otp1}
                                  onKeyUp={e => inputfocus(e)}
                                  onChange={(e) =>
                                    otpVerification(e.target.value, 1)
                                  }
                                />
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="0"
                                  maxLength="1"
                                  minLength={1}
                                  value={otp2}
                                  onKeyUp={e => inputfocus(e)}
                                  onChange={(e) =>
                                    otpVerification(e.target.value, 2)
                                  }
                                />
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="0"
                                  maxLength="1"
                                  minLength={1}
                                  value={otp3}
                                  onKeyUp={e => inputfocus(e)}
                                  onChange={(e) =>
                                    otpVerification(e.target.value, 3)
                                  }
                                />
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="0"
                                  maxLength="1"
                                  minLength={1}
                                  value={otp4}
                                  onKeyUp={e => inputfocus(e)}
                                  onChange={(e) =>
                                    otpVerification(e.target.value, 4)
                                  }
                                />
                              </div>
                            </div>
                            {/* <p className="text-danger desc-grey" id="timer">
                              {counter} Sec
                            </p> */}

                            <div className="btn-wrap col-auto mt-4">
                              <button
                               disabled={(!otp1 || !otp2 || !otp3 || !otp4)}
                                className="red-btn-lg hvr-icon-push contact-button profile-btn"
                                onClick={() => verifyOtp()}
                              >
                             
                                Verify and proceed
                              
                              </button>
                            </div>
                            <div className="text-center pt-4 resend-code">
                              <span className="d-block mobile-text">
                                Didn't receive the code?
                              </span>
                              <button
                                type="submit"
                                className="#"
                                disabled={counter>0}
                                onClick={() => resendOtp()}
                              >
                                Resend code
                              </button>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </ModalBody>
        </Modal>
    )
}

export default ProfileOTPModal
