/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import ErrorShow from './Forms/ErrorShow'
import Footer from './Layouts/Footer'
import Header from './Layouts/Header'
import validator from "validator";
import serverApi from "../api.json";
import callApi from "../service/callApi";
import { toast } from 'react-toastify';
import moment from 'moment'
import ProfileOTPModal from './Modal/ProfileOtpModal';
import NewMailUpdateForm from './Modal/NewMailUpdateForm';
import { useLocation, useNavigate } from 'react-router-dom'
import { CUSTOMER_LINK } from '../config';

const Profile = () => {
    const navigate = useNavigate()
    const [isModal, setModal] = useState(false)
    const [isModal2, setModal2] = useState(false)
    const [localPic, setLocalPic] = useState(undefined)
    const [userInfo, setUserInfo] = useState({
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        profilePic: "",
        dob: "",
        gender: "",
        currentPass: "",
        newPass: '',
        confirmPass: '',
        token: ""
    })

    const [oldPhone, setOldPhone] = useState("")
    const [token, setToken] = useState("")
    const location = useLocation()

    const updateEmailVerify = async (newToken) => {
        await callApi.put(`${serverApi.sendEmailVerifiy2}/${newToken}`)
            .then((res) => {
                if (res?.data && res?.data?.message) {
                    toast.success(res?.data?.message)
                    setEmailToLocal(res?.data?.email)
                    setTimeout(() => navigate("/my_profile"), 1000)
                }
            }).catch((e) => {
                if (e?.response && e?.response?.data?.error) {
                    toast.error(e?.response?.data?.error)
                } else {
                    toast.error("Error while updating details")
                }
            })
    }

    useEffect(() => {
        if (location?.search) {
            const token = location?.search?.split("=")
            if (token?.length > 0 && token[0] == "?updateToken" && token[1]) {
                setToken(token[1])
                setModal2(true)
            } else if (token?.length > 0 && token[0] == "?newToken" && token[1]) {
                updateEmailVerify(token[1])
            } else {
                setModal2(false)
            }
        }
    }, [location])


    useEffect(() => {
        if (localStorage.getItem("userData")) {
            const user = JSON.parse(localStorage.getItem("userData"))
            setUserInfo({
                firstName: user?.customerDetails.firstName,
                email: user?.customerDetails.email,
                lastName: user?.customerDetails.lastName,
                phone: (user?.customerDetails.phone + ""),
                profilePic: user?.customerDetails.profilePic,
                dob: user?.customerDetails.dob ? moment(user?.customerDetails.dob).format("YYYY-MM-DD") : "",
                gender: user?.customerDetails?.gender,
                currentPass: "",
                newPass: '',
                confirmPass: '',
            })
            setOldPhone((user?.customerDetails.phone + ""))
        }else{
            window.location = `${CUSTOMER_LINK}/login`
        }
    }, [localStorage.getItem("userData")])


    const [errors, setErrors] = useState({
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
        dob: null,
        gender: null,
        matchPass: null,
        currentPass: null,
        newPass: null,
        confirmPass: null,
    })

    const [toggle1, setToggle1] = useState(false)
    const [toggle2, setToggle2] = useState(false)
    const [toggle3, setToggle3] = useState(false)
    const [isLoading, setLoader] = useState(false)
    const [isLoading2, setLoader2] = useState(false)

    const validFields = () => {
        if (validator.isEmpty(userInfo?.firstName + "")) {
            setErrors((s) => ({ ...s, firstName: "First name is required" }))
            return false
        } else if (!validator.isEmpty(userInfo?.firstName) && !validator.isAlpha(userInfo?.firstName)) {
            setErrors((s) => ({ ...s, firstName: "First name must be alphabet" }))
            return false
        } else {
            setErrors((s) => ({ ...s, firstName: null }))
        }

        if (validator.isEmpty(userInfo?.lastName + "")) {
            setErrors((s) => ({ ...s, lastName: "Last name is required" }))
            return false
        } else if (!validator.isEmpty(userInfo?.lastName) && !validator.isAlpha(userInfo?.lastName)) {
            setErrors((s) => ({ ...s, lastName: "Last name must be alphabet" }))
            return false
        } else {
            setErrors((s) => ({ ...s, lastName: null }))
        }


        if (validator.isEmpty(userInfo?.phone + "")) {
            setErrors((s) => ({ ...s, phone: "Phone no. is required" }))
            return false
        } else if (!validator.isEmpty(userInfo?.phone+"") && ((userInfo?.phone+"")?.length<7 || (userInfo?.phone+"")?.length>10)) {
            setErrors((s) => ({ ...s, phone: "Phone no. must be 7 or 10 digit!" }))
            return false
        }else {
            setErrors((s) => ({ ...s, phone: null }))
        }

        if (validator.isEmpty(userInfo?.gender + "")) {
            setErrors((s) => ({ ...s, gender: "Gender is required" }))
            return false
        } else {
            setErrors((s) => ({ ...s, gender: null }))
        }


        if (validator.isEmpty(userInfo?.dob + "")) {
            setErrors((s) => ({ ...s, dob: "Dob is required" }))
            return false
        } else {
            setErrors((s) => ({ ...s, dob: null }))
        }

        if (!validator.isEmpty(userInfo?.currentPass + "") && validator.isEmpty(userInfo?.confirmPass + "") && validator.isEmpty(userInfo?.newPass + "")) {
            setErrors((s) => ({ ...s, newPass: "New password is required" }))
            return false
        } else {
            setErrors((s) => ({ ...s, newPass: null }))
        }

        if (!validator.isEmpty(userInfo?.confirmPass + "") && validator.isEmpty(userInfo?.newPass + "") && validator.isEmpty(userInfo?.currentPass + "")) {
            setErrors((s) => ({ ...s, currentPass: "New password is required" }))
            return false
        } else {
            setErrors((s) => ({ ...s, currentPass: null }))
        }

        if (!validator.isEmpty(userInfo?.newPass + "") && validator.isEmpty(userInfo?.currentPass + "") && validator.isEmpty(userInfo?.confirmPass + "")) {
            setErrors((s) => ({ ...s, newPass: "New password is required" }))
            return false
        } else {
            setErrors((s) => ({ ...s, newPass: null }))
        }

        if (!validator.isEmpty(userInfo?.confirmPass + "") && !validator.isEmpty(userInfo?.newPass + "") && userInfo?.confirmPass !== userInfo?.newPass) {
            setErrors((s) => ({ ...s, matchPass: "Password not matched" }))
            return false
        } else {
            setErrors((s) => ({ ...s, matchPass: null }))
        }



        if (userInfo?.currentPass !== "" && !validator.isEmpty("" + userInfo?.currentPass)) {
            if (
                !validator.isLength("" + userInfo?.currentPass, { min: 8 }) ||
                !validator.isLength("" + userInfo?.currentPass, { max: 16 }) ||
                !/[!@#$%^&*?_~]{1,}/.test(userInfo?.currentPass) || !/[a-z]{1,}/.test(userInfo?.currentPass + "") ||
                !/[A-Z]{1,}/.test(userInfo?.currentPass + "") || !/[0-9]{1,}/.test(userInfo?.currentPass + "")
            ) {
                setErrors((s) => ({ ...s, currentPass: "Password must have atleast 8-16 characters, should contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric" }))
                return false
            } else {
                setErrors((s) => ({ ...s, currentPass: null }))
            }
        }

        if (userInfo?.newPass !== "" && !validator.isEmpty("" + userInfo?.newPass)) {
            if (
                !validator.isLength("" + userInfo?.newPass, { min: 8 }) ||
                !validator.isLength("" + userInfo?.newPass, { max: 16 }) ||
                !/[!@#$%^&*?_~]{1,}/.test(userInfo?.newPass + "") || !/[a-z]{1,}/.test(userInfo?.newPass + "") ||
                !/[A-Z]{1,}/.test(userInfo?.newPass + "") || !/[0-9]{1,}/.test(userInfo?.newPass + "")
            ) {
                setErrors((s) => ({ ...s, newPass: "Password must have atleast 8-16 characters, should contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric" }))
                setErrors((s) => ({ ...s, matchPass: null }))
                return false
            } else {
                setErrors((s) => ({ ...s, newPass: null }))
            }
        }
        return true
    }

    const isValid = () => {
        let valid = true
        validFields()
        Object.keys(errors).forEach((key) => {
            if (errors[key] != null) {
                return valid = false
            }
        })
        return valid
    }

    const validFields2 = () => {

        if (!validator.isEmpty(userInfo?.currentPass + "") && validator.isEmpty(userInfo?.confirmPass + "") && validator.isEmpty(userInfo?.newPass + "")) {
            setErrors((s) => ({ ...s, newPass: "New password is required" }))
            return false
        } else {
            setErrors((s) => ({ ...s, newPass: null }))
        }

        if (!validator.isEmpty(userInfo?.confirmPass + "") && validator.isEmpty(userInfo?.newPass + "") && validator.isEmpty(userInfo?.currentPass + "")) {
            setErrors((s) => ({ ...s, currentPass: "New password is required" }))
            return false
        } else {
            setErrors((s) => ({ ...s, currentPass: null }))
        }

        if (!validator.isEmpty(userInfo?.newPass + "") && validator.isEmpty(userInfo?.currentPass + "") && validator.isEmpty(userInfo?.confirmPass + "")) {
            setErrors((s) => ({ ...s, newPass: "New password is required" }))
            return false
        } else {
            setErrors((s) => ({ ...s, newPass: null }))
        }

        if (!validator.isEmpty(userInfo?.confirmPass + "") && !validator.isEmpty(userInfo?.newPass + "") && userInfo?.confirmPass !== userInfo?.newPass) {
            setErrors((s) => ({ ...s, matchPass: "Password not matched" }))
            return false
        } else {
            setErrors((s) => ({ ...s, matchPass: null }))
        }



        if (userInfo?.currentPass !== "" && !validator.isEmpty("" + userInfo?.currentPass)) {
            if (
                !validator.isLength("" + userInfo?.currentPass, { min: 8 }) ||
                !validator.isLength("" + userInfo?.currentPass, { max: 16 }) ||
                !/[!@#$%^&*?_~]{1,}/.test(userInfo?.currentPass) || !/[a-z]{1,}/.test(userInfo?.currentPass + "") ||
                !/[A-Z]{1,}/.test(userInfo?.currentPass + "") || !/[0-9]{1,}/.test(userInfo?.currentPass + "")
            ) {
                setErrors((s) => ({ ...s, currentPass: "Password must have atleast 8-16 characters, should contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric" }))
                return false
            } else {
                setErrors((s) => ({ ...s, currentPass: null }))
            }
        }

        if (userInfo?.newPass !== "" && !validator.isEmpty("" + userInfo?.newPass)) {
            if (
                !validator.isLength("" + userInfo?.newPass, { min: 8 }) ||
                !validator.isLength("" + userInfo?.newPass, { max: 16 }) ||
                !/[!@#$%^&*?_~]{1,}/.test(userInfo?.newPass + "") || !/[a-z]{1,}/.test(userInfo?.newPass + "") ||
                !/[A-Z]{1,}/.test(userInfo?.newPass + "") || !/[0-9]{1,}/.test(userInfo?.newPass + "")
            ) {
                setErrors((s) => ({ ...s, newPass: "Password must have atleast 8-16 characters, should contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric" }))
                setErrors((s) => ({ ...s, matchPass: null }))
                return false
            } else {
                setErrors((s) => ({ ...s, newPass: null }))
            }
        }
        return true
    }

    const isValid2 = () => {
        let valid = true
        validFields2()
        Object.keys(errors).forEach((key) => {
            if ((key=="currentPass"||key=="newPass"||key=="confirmPass") && errors[key] != null) {
                return valid = false
            }
        })
        return valid
    }


    const handleChange = (e) => {
        setUserInfo((info) => ({ ...info, [e.target.name]: e.target.value }))
    }


    const updateProfile = (e) => {
        e.preventDefault()
        if (validFields()) {
            if (oldPhone !== userInfo.phone) {
                sendOtp()
            } else {
                updateDetails()
            }
        }
    }

    const sendOtp = async () => {
        setModal(false)
        let otp = await callApi.post(serverApi.sendOtp, {
            phone: userInfo.phone,
            type: "updateprofile",
        })
        if (otp.data.data == "User Already Registered") {
            toast.warn(otp.data.data)
            setModal(false)
        } else {
            setModal(true)
        }
    }

    const setPhoneToLocal = () => {
        if (localStorage.getItem("userData")) {
            const userData = JSON.parse(localStorage.getItem("userData"))
            userData.customerDetails.phone = userInfo.phone
            localStorage.setItem("userData", JSON.stringify(userData))
        }
    }

    const setEmailToLocal = (email) => {
        if (localStorage.getItem("userData")) {
            setUserInfo((s) => ({ ...s, email }))
            const userData = JSON.parse(localStorage.getItem("userData"))
            userData.customerDetails.email = email
            localStorage.setItem("userData", JSON.stringify(userData))
        }
    }

    const updateDetails = async () => {
        setLoader(true)
        const form = new FormData()
        form.append('dob',userInfo?.dob)
        form.append("email",userInfo?.email)
        form.append("firstName",userInfo?.firstName)
        form.append("gender",userInfo?.gender)
        form.append("lastName",userInfo?.lastName)
        form.append("phone",userInfo?.phone)
        if(localPic){
            form.append("profilePic",localPic)
        }else if(userInfo?.profilePic==""){
            form.append("profilePic","")
        }

        await callApi.put(serverApi.updateProfile, form).then((res) => {
                setLoader(false)
                if (res?.data && res?.data?.data) {
                    
                    if (localStorage.getItem("userData")) {
                        const userData = JSON.parse(localStorage.getItem("userData"))
                        userData.customerDetails.firstName = userInfo.firstName
                        userData.customerDetails.lastName = userInfo.lastName
                        userData.customerDetails.gender = userInfo.gender
                        if(res?.data?.data?.profilePic){
                            userData.customerDetails.profilePic = res?.data?.data?.profilePic
                        }else if(res?.data?.data?.profilePic==""){
                            userData.customerDetails.profilePic = ""
                        }
                        userData.customerDetails.dob = userInfo.dob
                        localStorage.setItem("userData", JSON.stringify(userData))
                        toast.success(res?.data?.message)
                        setUserInfo((u) => ({
                            ...u,
                            currentPass: "",
                            newPass: '',
                            confirmPass: '',
                        }))
                        setModal(false)
                    }
                }
            }).catch((e) => {
                setLoader(false)
                if (e?.response && e?.response?.data?.error) {
                    toast.error(e?.response?.data?.error)
                } else {
                    toast.error("Error while updating details")
                }
            })
    }

    const updatePassword = async () => {
        setLoader2(true)
        await callApi.put(serverApi.profile_changepassword, {
            newPass:userInfo?.newPass,
            currentPass:userInfo?.currentPass,
            confirmPass:userInfo?.confirmPass,
            changePassword:true
        }).then((res) => {
                setLoader2(false)
                if (res?.data && res?.data) {
                    toast.success(res?.data?.message)
                    setUserInfo((u) => ({
                        ...u,
                        currentPass: "",
                        newPass: '',
                        confirmPass: '',
                    }))
                    setModal(false)
                }
            }).catch((e) => {
                setLoader2(false)
                if (e?.response && e?.response?.data?.error) {
                    toast.error(e?.response?.data?.error)
                } else {
                    toast.error("Error while updating details")
                }
            })
    }

    const sendMailVerifiyOld = async () => {
        if (userInfo?.email) {
            await callApi.put(serverApi.sendEmailReq, { email: userInfo.email })
                .then((res) => {
                    if (res?.data && res?.data?.message) {
                        toast.success(res?.data?.message)
                    }
                }).catch((e) => {
                    if (e?.response && e?.response?.data?.error) {
                        toast.error(e?.response?.data?.error)
                    } else {
                        toast.error("Error while updating details")
                    }
                })
        }
    }

    const changePassword = (e)=>{
        e.preventDefault()
        if (validFields2()) {
            updatePassword()
        }
    }

    const handleImage = e=>{
        if(e.target.files?.length>0){
            // console.log("images",e.target.files)
            setLocalPic(e.target.files[0])
        }
    }

    const removePic=()=>{
        setLocalPic(undefined)
        setUserInfo((info) => ({ ...info, 'profilePic': "" }))
    }


    return (
        <div>
            <Header />

            <section class="sports-list-wrap bg-grey pb-5">
                <section class="hero-banner hero-banner-height" style={{ backgroundColor: 'black' }}>
                    <div class="container">
                        <div class="row">
                            <div class="hero-banner-aboutus hero-banner-content">
                                <h2 class="heading-white ">
                                    My Profile

                                </h2>
                                <p class="desc-white pt-4 text-center">
                                    Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis fermentum
                                    justo,
                                    sit amet convallis arcu
                                    interdum vel. Suspendisse vulputate posuere risus, quis consectetur neque scelerisque.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="sports-list-wrap bg-grey pt-5 plr-70">
                    <div class="container">
                        <div class="card-div">
                            <div class="row">
                                <div class="top-section">
                                    <h2 class="heading-grey prof-heading text-left">
                                        Personal Details
                                    </h2>
                                    <p class="desc-grey-about  text-left pt-4">
                                        Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis
                                        fermentum
                                        justo, sit amet convallis arcu interdum vel. Suspendisse vulputate posuere risus, quis
                                        consectetur neque scelerisque.
                                    </p>


                                    <form class="subscribe-form contact-details profile-details pt-4" onSubmit={(e) =>updateProfile(e)} autoComplete='off' >
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div class="d-flex pic-img">
                                                    <figure>
                                                        {localPic?<img src={URL.createObjectURL(localPic)} alt="logo" />:<img src={userInfo?.profilePic ? `${serverApi.profilePicCustomer}/${userInfo?.profilePic}` : "/assets/images/pic_placeholder.png"} alt="logo" style={{height:140,width:140,marginRight:10}} />}
                                                        
                                                    </figure>

                                                    <div class="pa-left-4">
                                                        <p class="mobile-text text-left p-0">Profile picture</p>
                                                        <div class="button justify-content-center d-flex ">
                                                            <div class="upload-btn-wrapper">
                                                                <button class="btn upload-btn file-btn">Upload Image</button>
                                                                <input type="file" onChange={handleImage}  value={localPic?.filename?localPic?.filename:""} name="myfile" />
                                                            </div>
                                                            <span class="btn btn-light remove-btn file-btn" disabled={(!localPic||!userInfo?.profilePic)} onClick={()=>removePic()}>Remove</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <label>First Name</label>
                                                <div class="input-group">

                                                    <div class="input-group-prepend">

                                                        <span class="material-icons-outlined">person_outline</span>
                                                    </div>
                                                    <input type="text" onChange={handleChange} name="firstName" value={userInfo?.firstName} required class="form-control pl-value" placeholder="First Name" />
                                                </div>
                                                <ErrorShow errorMsg={errors.firstName} />
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Last Name </label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">

                                                        <span class="material-icons-outlined">person_outline</span>
                                                    </div>
                                                    <input type="text" onChange={handleChange} name="lastName" value={userInfo?.lastName} class="form-control pl-value" placeholder="Last Name" required />
                                                </div>
                                                <ErrorShow errorMsg={errors.lastName} />
                                            </div>
                                        </div>
                                        <div className="row p-2">
                                            <div className="col-lg-6">
                                                <label>Phone number </label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="material-icons-outlined">
                                                            phone
                                                        </span>
                                                    </div>
                                                    <input value={userInfo?.phone} maxLength={10} min={7} onChange={handleChange} name="phone" required type="text" class="form-control pl-value"
                                                        placeholder="Phone No." />
                                                </div>
                                                <ErrorShow errorMsg={errors.phone} />
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Gender</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="material-icons-outlined">
                                                            male
                                                        </span>
                                                    </div>
                                                    {/* <!-- <p class="desc-grey pa-left-40 desc-contact ">Gender</p><br /> --> */}



                                                    <div class="input-field d-flex opt-div pl-5 ml-4">
                                                        <input type="radio" onChange={handleChange} name="gender" value="male" checked={userInfo?.gender == "male"} /><span
                                                            class="desc-grey-about pr-4">Male</span>
                                                        <input type="radio" onChange={handleChange} required name="gender" value="female" checked={userInfo?.gender == "female"} /><span
                                                            class="desc-grey-about pr-4">Female</span>
                                                    </div>
                                                    <ErrorShow errorMsg={errors.gender} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <label>Date of birth</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">

                                                        <span class="material-icons-outlined">
                                                            cake
                                                        </span>
                                                    </div>

                                                    <input type="date" onChange={handleChange} name="dob" onKeyDown={(e) => e.preventDefault()}  max={new Date()?.getFullYear()-1+'-01-01'} min={new Date()?.getFullYear()-100+'-01-01'} required value={userInfo?.dob} class="form-control pl-value" placeholder="Date Of Birth" />

                                                    <div class="input-group-append date-icon ">
                                                        <span class="material-icons-outlined">
                                                            event
                                                        </span>
                                                    </div>
                                                </div>
                                                <ErrorShow errorMsg={errors.dob} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                    <div class="btn-wrap justify-content-start pt-4" style={{ cursor: 'pointer' }}>
                                                        {isLoading ? (
                                                            <div class="spinner-border" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        ) : <button type="submit"
                                                            class="hvr-icon-push blue-btn-lg profile-btn common-btn float-right">Update details</button>}
                                                    </div>
                                                </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="sports-list-wrap bg-grey mt-3 plr-70">
                    <div class="container ">
                        <div class="card-div-one card-div">
                            <div class="row top-section">
                                <div class="col-md-6">
                                    <h2 class="heading-grey prof-heading text-left">
                                        Email Id
                                    </h2>
                                    <p class="desc-grey-about  text-left pt-4">
                                        Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis
                                        fermentum

                                    </p>
                                </div>

                            </div>

                            <form class="subscribe-form contact-details  pt-4 mr-0" autoComplete='off'>
                                <div class="row">
                                    <div class="col-md-6 pt-4">
                                        <label>Email address</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="material-icons-outlined">mail_outline</span>
                                            </div>
                                            <input type="email" value={userInfo?.email} class="form-control pl-value"
                                                placeholder="Elsie.a122@dan.net" disabled readOnly />
                                        </div>
                                    </div>
                                    <div class="col-md-6 pt-4">
                                        <div class="btn-wrap justify-content-end" style={{cursor:'pointer'}} onClick={() => sendMailVerifiyOld()}>
                                            <span href="" data-toggle="modal" data-target="#exampleModalLong-two"
                                                class="submit-btn hvr-icon-push blue-btn-lg profile-btn common-btn float-right">Update
                                                New
                                                Mail Id</span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <section class="sports-list-wrap bg-grey mt-3  plr-70">
                    <div class="container ">
                        <div class="card-div-two card-div">
                            <div class="top-section">
                                <h2 class="heading-grey prof-heading text-left">
                                    Password
                                </h2>
                                <p class="desc-grey-about  text-left pt-4">
                                    Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis fermentum

                                </p>


                                <form class="subscribe-form contact-details profile-details pt-4 mr-0" onSubmit={(e) => changePassword(e)}>
                                    <div class="row">

                                        <div class="col-md-12">
                                            <div class="pt-4">
                                                <label>Enter Current Password</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="material-icons-outlined">
                                                            lock
                                                        </span>
                                                    </div>
                                                    <input style={{zIndex:0}} onChange={handleChange} name="currentPass" value={userInfo?.currentPass} type={toggle1 ? 'text' : 'password'} class="form-control pl-value"
                                                        placeholder="Old Password" required/>
                                                    <div style={{ zIndex:999,cursor: 'pointer', color: 'blue' }} onClick={() => setToggle1(!toggle1)} class="input-group-append password-create password">
                                                        <span class="material-icons-outlined " >
                                                            {toggle1 ? 'visibility' : 'visibility_off'}
                                                        </span>
                                                    </div>
                                                </div>
                                                    <ErrorShow errorMsg={errors.currentPass} />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="pt-4">
                                                <label>Enter New Password</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="material-icons-outlined">
                                                            lock
                                                        </span>
                                                    </div>
                                                    <input style={{zIndex:0}} onChange={handleChange} name="newPass" type={toggle2 ? 'text' : 'password'} class="form-control pl-value"
                                                        placeholder="New Password" value={userInfo?.newPass} required/>
                                                    <div style={{ zIndex:999,cursor: 'pointer', color: 'blue' }}  class="input-group-append password-create password">
                                                        <span class="material-icons-outlined" onClick={() => setToggle2(!toggle2)} >
                                                            {toggle2 ? 'visibility' : 'visibility_off'}
                                                        </span>
                                                    </div>
                                                </div>
                                                <ErrorShow errorMsg={errors.newPass} />
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="pt-4">
                                                <label>Enter Confirm Password</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="material-icons-outlined">
                                                            lock
                                                        </span>
                                                    </div>
                                                    <input style={{zIndex:0}} onChange={handleChange} name="confirmPass" value={userInfo?.confirmPass} type={toggle3 ? 'text' : 'password'} class="form-control pl-value"
                                                        placeholder="Confirm Password" required/>
                                                    <div style={{ zIndex:999,cursor: 'pointer', color: 'blue' }}  class="input-group-append password-create password">
                                                        <span class="material-icons-outlined" onClick={() => setToggle3(!toggle3)}>
                                                            {toggle3 ? 'visibility' : 'visibility_off'}
                                                        </span>
                                                    </div>
                                                </div>
                                                <ErrorShow errorMsg={errors.matchPass} />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="btn-wrap justify-content-start pt-4" style={{ cursor: 'pointer' }}>
                                                {isLoading2 ? (
                                                    <div class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                ) : <button type="submit"
                                                    class="hvr-icon-push blue-btn-lg profile-btn common-btn float-right">Change Password</button>}

                                            </div>

                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </section>
            </section>
            <ProfileOTPModal data={userInfo} doneCB={() => { setPhoneToLocal(); updateDetails() }} resendOtp={() => sendOtp()} handleToggle={() => setModal(!isModal)} isModal={isModal} />
            <NewMailUpdateForm data={{ userInfo, token }} handleToggle={() => setModal2(!isModal2)} isModal={isModal2} doneCB={() => { }} />
            <Footer />
        </div>
    )
}

export default Profile
