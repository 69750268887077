import React from 'react'
import { Link } from 'react-router-dom'
import api from '../../../api.json'

const BookingCard2 = ({ info }) => {
    // console.log("info", info)
    return (
        <div class="col-md-12 col-lg-4 mb-4">
            <div class="sports-list-card bookingListcard">
                <div class="sports-img">
                    <figure>
                        <img src={(info?.serviceClassDetails && info?.serviceClassDetails?.Images?.length > 0) ? `${api?.base_url}/${info?.serviceClassDetails?.Images[0]}` : "assets/images/image-01.png"} alt="img" class="img-fluid" />
                    </figure>
                </div>
                <div class="sports-info">
                    <h3 class="sports-name">{info?.facilitiesDetails?.Name}</h3>
                    <p class="sports-location">{info?.serviceClassDetails?.Address}</p>
                    <div class="sports-eminities">
                    <p className="text-center"><strong>{`${info?.serviceClassDetails?.type} - ${info?.serviceClassDetails?.Name}`}</strong></p>
                    <ul>
                        {(info?.amenities && info?.amenities?.length>0)?info?.amenities?.map((amenity,i)=>(
                            <li key={i}>
                                <div class="eminities-content">
                                    <span class="material-icons-outlined check-icon">
                                        done
                                    </span>
                                    <p>
                                     {amenity?.name}
                                    </p>
                                </div>
                            </li>
                        )):null}
                    </ul>
                    </div>
                    <div class="hidden-booking">
                        <div class="sports-booking-wrap">
                            <div class="booking-content-left bg-red">
                                <h2 class="booking-price-heading">
                                    Booking
                                    <span>PRICE</span>
                                </h2>
                                <p class="price">$<b>  {(info?.serviceClassDetails?.Price)?.toFixed(2)}</b></p>
                            </div>
                            <div class="booking-content-right bg-blue">
                                <Link to={`/service_class_details/${info?.serviceClassDetails?._id}`}><a>Book Now ! <span class="material-icons-outlined arrow-right">east</span></a> </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingCard2
