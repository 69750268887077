import React, { useEffect, useState } from 'react'
import Footer from '../Layouts/Footer'
import Header from '../Layouts/Header'
import Banner from './Banner'
import EminitiesItem from './EminitiesItem'
import { getCheckoutInfo } from '../../helpers'
import TimeDateItem from './TimeDateItem'
import api from '../../api.json'
import CardUI from './CardUI'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
import callApi from '../../service/callApi'
import Loader from '../Layouts/Loader'

const Index = () => {

    const navigate = useNavigate()
    const [isLoading, setLoader] = useState(false)
    useEffect(() => {
        if (!getCheckoutInfo()) {
            navigate('/')
        }
    }, [])

    const HandlePayNow = async () => {
        const body = getCheckoutInfo()
        setLoader(true)
        await callApi.post(api.order_confirm, body)
            .then((res) => {
                setLoader(false)
                if (res?.data && res?.data?.success) {
                    toast.success(res?.data?.success)
                    if (body) {
                        localStorage.removeItem('booking_checkout')
                    }
                    setTimeout(() => navigate("/my_booking"), 1500)
                }
            }).catch((e) => {
                console.log('error', e);
                setLoader(false)
                if (e?.response && e?.response?.data?.error) {
                    toast.error(e?.response?.data?.error)
                } else {
                    toast.error("Error! while processing your request")
                }
            })

    }

    const cancelOrder = () => {
        if (getCheckoutInfo() != false) {
            // localStorage.removeItem("booking_checkout")
            toast.error("Your order is been cancelled!")
            const id = getCheckoutInfo()?.serviceClassId
            if (id) {
                setTimeout(() => navigate(`/service_class_details/${id}`), 2000)
            }
        }
    }

    return (
        <div>
            <Header />

            <Banner />

            <section className="sports-list-wrap bg-grey plr-70 ptb-90">
                <div className="container">
                    <div className="card-div ml-0 mr-0">
                        <div className="row">
                            <div className="top-section">
                                <h2 className="heading-grey order-heading text-left">
                                    Booking Details
                                </h2>
                                <p className="desc-grey-about  text-left pt-4">
                                    {getCheckoutInfo()?.bookingDetails?.Name ? `${getCheckoutInfo()?.bookingDetails?.type=='service'?"Service":"Class"} - ${getCheckoutInfo()?.bookingDetails?.Name}` : ""}
                                </p>


                                <form onSubmit={(e) => e.preventDefault()} className="subscribe-form contact-details profile-details pt-4">
                                    <div className="row">
                                        <div className="col-lg-4">

                                            <div className="confirm-img">
                                                <figure>
                                                    <img style={{ width: 400, height: 200 }} src={getCheckoutInfo()?.bookingDetails?.Images?.length != 0 ? `${api.base_url}/${getCheckoutInfo()?.bookingDetails?.Images[0]}` : "assets/images/order-image.png"} alt="logo" className="img-fluid" />
                                                </figure>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div className="">
                                                <div className="sports-info text-left">
                                                    <h2 className="heading-grey order-heading text-left">{getCheckoutInfo()?.bookingDetails?.facilitiesDetails?.length != 0 ? getCheckoutInfo()?.bookingDetails?.facilitiesDetails[0]?.Name : ""}</h2>

                                                    <div className="order-confirm">
                                                        <p className="">{getCheckoutInfo()?.bookingDetails?.facilitiesDetails?.length != 0 ? getCheckoutInfo()?.bookingDetails?.facilitiesDetails[0]?.Address : ""}</p>
                                                    </div>
                                                    <div className="billing-list d-flex pt-3">
                                                        <ul>
                                                            {
                                                                getCheckoutInfo()?.bookingDetails?.Court?.CourtName ? (
                                                                    <li className="p-0">
                                                                        <p className="order-grey text-left m-0">Court
                                                                        </p>
                                                                        <p className="order-p text-left"> {getCheckoutInfo()?.bookingDetails?.Court?.CourtName ? getCheckoutInfo()?.bookingDetails?.Court?.CourtName : ""}</p>
                                                                    </li>
                                                                ) : null
                                                            }

                                                            {
                                                                (getCheckoutInfo()?.bookingDetails?.Court?.Surface) ? (
                                                                    <li className="">
                                                                        <p className="order-grey text-left m-0">Surface
                                                                        </p>
                                                                        <p className="order-p text-left">{getCheckoutInfo()?.bookingDetails?.Court?.Surface}</p>
                                                                    </li>
                                                                ) : null
                                                            }

                                                            {
                                                                getCheckoutInfo()?.bookingDetails?.type !== "service" ? (
                                                                    <li className="">
                                                                        <p className="order-grey text-left m-0"> No. of people
                                                                        </p>
                                                                        <p className="order-p  text-left"> {getCheckoutInfo()?.quantity ? getCheckoutInfo()?.quantity : null}</p>
                                                                    </li>
                                                                ) : <li className="">
                                                                <p className="order-grey text-left m-0"> Total Hours
                                                                </p>
                                                                <p className="order-p  text-left"> {getCheckoutInfo()?.total_hours ? getCheckoutInfo()?.total_hours : null}</p>
                                                            </li>
                                                            }

                                                            <li className="">
                                                                <p className="order-grey text-left m-0">Booking Price
                                                                </p>
                                                                <p className="order-p text-left"> QR {getCheckoutInfo()?.Price}</p>
                                                            </li>
                                                            <li className="">

                                                            </li>

                                                        </ul>


                                                    </div>
                                                    <div className="sports-eminities float-left">
                                                    <p className="order-grey text-left m-0">Amenities</p>
                                                        <div className="row p-0">
                                                            {getCheckoutInfo()?.bookingDetails?.amenities?.length != 0 ? getCheckoutInfo()?.bookingDetails?.amenities?.map((a, key) => <EminitiesItem key={key} item={a} />) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="">
                                                <div className="sports-info text-left">
                                                    {getCheckoutInfo() ? (
                                                        <div className='response-table'>
                                                            {(getCheckoutInfo()?.slots && Object.keys(getCheckoutInfo()?.slots)?.length != 0) ? (
                                                                <table className='table'>
                                                                    <tr>
                                                                        <th className='text-left' style={{ width: 150 }}>Booked Dates</th>
                                                                        <th className='text-left'>Time Slots</th>
                                                                    </tr>
                                                                    {Object.entries(getCheckoutInfo()?.slots)?.map((s, index) => <TimeDateItem item={s} key={index} />)}
                                                                </table>
                                                            ) : null}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                                <form onSubmit={(e) => e.preventDefault()} className="subscribe-form contact-details profile-details pt-4">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <h2 className="heading-grey order-heading  text-left">
                                                Payment Method
                                            </h2>
                                            <div className="order-confirm">
                                                {
                                                    getCheckoutInfo()?.paymentDetails?.payment_mode == 'card' ? <p className="pt-2 text-left">
                                                        Your card details
                                                    </p> : <p className="pt-2 text-left">Your have selected cash payment</p>
                                                }

                                            </div>
                                            <p className="order-grey pt-4  text-left">
                                                {getCheckoutInfo()?.paymentDetails?.payment_mode == "card" ? 'Card Payment' : 'Cash Payment'}
                                            </p>
                                            {
                                                getCheckoutInfo()?.paymentDetails?.payment_mode == 'card' ? (
                                                    <div className="">
                                                        <figure>
                                                            <CardUI card={getCheckoutInfo()?.paymentDetails} />
                                                        </figure>
                                                    </div>
                                                ) : null
                                            }

                                            {/* <div className="order-confirm">
                                                <p className="pt-2">Nunc vitae mi aliquam, cursus urna sit amet, efficitur ipsum.
                                                    In at auctor est, vitae pharetra quam. </p>
                                            </div> */}
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <h2 className="heading-grey order-heading  text-left">
                                                Billing Address
                                            </h2>
                                            <div className="order-confirm">
                                                <p className="pt-2 text-left">
                                                    Your billing address details
                                                </p>
                                            </div>
                                            <div className="billing-list d-flex pt-4">
                                                <div className="col-md-4 p-0">
                                                    <p className="order-grey text-left">Street</p>
                                                    <div className="order-confirm">
                                                        <p className='text-left'>{getCheckoutInfo()?.billingAddress?.street ? getCheckoutInfo()?.billingAddress?.street : null}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 p-0">
                                                    <p className="order-grey text-left">Appartment</p>
                                                    <div className="order-confirm">
                                                        <p className='text-left'>{getCheckoutInfo()?.billingAddress?.appartment ? getCheckoutInfo()?.billingAddress?.appartment : null}</p>

                                                    </div>
                                                </div>
                                                <div className="col-md-4 p-0">
                                                    <p className="order-grey text-left">Country</p>
                                                    <div className="order-confirm">
                                                        <p className='text-left'>{getCheckoutInfo()?.billingAddress?.country ? getCheckoutInfo()?.billingAddress?.country : null}</p>
                                                    </div>
                                                </div>



                                            </div>
                                            <div className="billing-list d-flex">

                                                <div className="col-md-4 p-0">
                                                    <p className="order-grey text-left">State</p>
                                                    <div className="order-confirm">
                                                        <p className='text-left'>{getCheckoutInfo()?.billingAddress?.state ? getCheckoutInfo()?.billingAddress?.state : null}</p>

                                                    </div>

                                                </div>
                                                <div className="col-md-4 p-0">
                                                    <p className="order-grey text-left">City</p>
                                                    <div className="order-confirm">
                                                        <p className='text-left'>{getCheckoutInfo()?.billingAddress?.city ? getCheckoutInfo()?.billingAddress?.city : null}</p>

                                                    </div>
                                                </div>
                                                <div className="col-md-4 p-0">

                                                    <p className="order-grey text-left">Zip Code</p>
                                                    <div className="order-confirm">
                                                        <p className='text-left'>{getCheckoutInfo()?.billingAddress?.zip_code ? getCheckoutInfo()?.billingAddress?.zip_code : null}</p>
                                                    </div>

                                                </div>
                                            </div>
                                            {/* <div className="order-confirm">
                                                <p>Pellentesque interdum lacus sit amet convallis bibendum. Donec a turpis in
                                                    risus
                                                    tristique facilisis. Maecenas sagittis ullamcorper ligula vel dignissim.
                                                    Aenean
                                                    feugiat diam et dolor pharetra elementum. </p>
                                            </div> */}



                                        </div>

                                    </div>
                                </form>
                                <form onSubmit={(e) => e.preventDefault()} className="subscribe-form contact-details profile-details pt-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h2 className="heading-grey order-heading  text-left">
                                                Total Cost
                                            </h2>
                                            {/* <div className="order-confirm">
                                                <p className="pt-2">
                                                    In iaculis aliquet libero, id pellentesque felis luctus non.

                                                </p>
                                            </div> */}





                                            <div className="billing-list d-flex pt-2">
                                                <div className="col-md-2 p-0">
                                                    <p className="desc-grey-about text-left">Order Item</p>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="order-confirm">
                                                        {
                                                            getCheckoutInfo()?.bookingDetails?.type == "service" ? <p className='text-left'>{getCheckoutInfo()?.bookingDetails?.Name ? `Service - ${getCheckoutInfo()?.bookingDetails?.Name}` : ""}</p> : <p className='text-left'>{getCheckoutInfo()?.bookingDetails?.Name ? `Class - ${getCheckoutInfo()?.bookingDetails?.Name}` : ""}</p>
                                                        }

                                                    </div>



                                                </div>
                                                <div className="col-md-2 p-0">
                                                    <p className="order-grey"><span
                                                        className="pa-right text-danger">QR</span> {getCheckoutInfo()?.Price ? getCheckoutInfo()?.Price : null}
                                                    </p>
                                                </div>
                                            </div>


                                            <hr />
                                            <div className="billing-list d-flex pt-3">
                                                <div className="col-md-10">
                                                    <div className="discount-cost justify-content-start">
                                                        <p className="text-left">Total</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 p-0">
                                                    <p className="order-grey"><span className="pa-right text-danger">QR</span> {getCheckoutInfo()?.Price ? getCheckoutInfo()?.Price : null}
                                                    </p>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="order-btn-col">
                                        <div className="row">
                                            {
                                                isLoading ? <Loader /> : (
                                                    <>
                                                        <div className="col-md-6">
                                                            <div className="btn-wrap justify-content-center pt-4">
                                                                <button onClick={() => cancelOrder()} className="red-btn-lg remove-btn hvr-icon-push contact-button" >Cancel Order</button>

                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="btn-wrap justify-content-center pt-4">
                                                                <button className="blue-btn-lg hvr-icon-push  contact-button" onClick={() => HandlePayNow()}>Pay Now</button>
                                                                {/* <!-- <a href="my-card.html" data-toggle="modal" data-target="#exampleModalLong-two"
                                        className="blue-btn-lg hvr-icon-push  contact-button ">Pay Now</a> --> */}
                                                            </div>

                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>

                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default Index