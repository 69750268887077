import React, { useState, useEffect } from "react";
import Navbar_Logo from "./Navbar/Navbar_Logo";
import callApi from "../service/callApi";
import serverApi from "../api.json";
import { Link } from "react-router-dom";
import validator from "validator";
import { toast } from "react-toastify";

// import $ from 'jquery'
import "react-datepicker/dist/react-datepicker.css";
import ErrorShow from "./Forms/ErrorShow";
import SignupModel from "./Modal/SignupModel";
import { useNavigate } from "react-router-dom";
import { getCheckoutInfo } from "../helpers";
import { CUSTOMER_LINK, GUEST_LINK } from "../config";

const SignUp = (props) => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termCondition, setTermsCondition] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [customerData, setCustomerData] = useState({});
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [userStatus, setUSerStatus] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isModal, setModal] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("userData")) {
      navigate("/");
    }
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "",
    dob: "",
    password: "",
    confirmPassword: "",
    matchPass: "",
    termCondition: "",
    date: "",
  });

  const removeProfilePic = () => {
    // console.log("Remove");
  };

  const resetFields = () => {
    setModal(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setGender("");
    setConfirmPassword("");
    setDob("");
    setProfilePic("");
    setTermsCondition("");
    setPhone("");
    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      gender: "",
      dob: "",
      password: "",
      confirmPassword: "",
      matchPass: "",
      termCondition: "",
      date: "",
    });
    if (getCheckoutInfo() == false) {
      navigate("/home");
    } else {
      let id = getCheckoutInfo()?.serviceClassId;
      if (id) {
        navigate(`/service_class_details/${id}`);
      }
    }
  };

  const sendOtp = async () => {
    if (validFields() && termCondition) {
      setModal(false);
      // console.log("Cool ready to go now")
      setLoading(true);
      let otp = await callApi.post(serverApi.sendOtp, {
        phone: phone,
        email: email,
        type: "New",
      });
      setLoading(false);
      // console.log("data--->",otp.data)
      if (otp.data.data == "User Already Registered") {
        toast.warn(otp.data.data);
        setUSerStatus(true);
      } else {
        setUSerStatus(false);
      }

      setCustomerData({
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        gender: gender,
        dob: dob,
        password: password,
        termCondition: termCondition ? "Yes" : "No",
        profilePic: profilePic ? profilePic : "",
      });

      if (otp.data.data == "OTP SENT") {
        toast.success(otp.data.data);
        setModal(true);
        setLoading(false);
        // document.getElementById("vehicle1").checked = false;
        // if (gender == "male") {
        //   document.getElementById("male").checked = false;
        // } else {
        //   document.getElementById("female").checked = false;
        // }

        // setPhone("");
        // setTest1("modal");
        // setTest2("#exampleModalLong");
      }
    } else {
      setLoading(false);
      setModal(false);
    }
  };
  const handleShowPassword1 = () => {
    // console.log("a");
    let toggle = toggle1;
    toggle = !toggle;
    setToggle1(toggle);
  };
  const handleShowPassword2 = () => {
    // console.log("a");
    handleShowPassword1();
    let toggle = toggle2;
    toggle = !toggle;

    setToggle2(toggle);

    // console.log(toggle);
  };

  const validFields = () => {
    if (validator.isEmpty(firstName + "")) {
      setErrors((s) => ({ ...s, firstName: "First name is required" }));
      return false;
    } else if (
      !validator.isEmpty(firstName + "") &&
      !validator.isAlpha(firstName + "")
    ) {
      setErrors((s) => ({ ...s, firstName: "First name must be alphabet" }));
      return false;
    } else {
      setErrors((s) => ({ ...s, firstName: "" }));
    }

    if (validator.isEmpty(lastName + "")) {
      setErrors((s) => ({ ...s, lastName: "Last name is required" }));
      return false;
    } else if (
      !validator.isEmpty(lastName + "") &&
      !validator.isAlpha(lastName + "")
    ) {
      setErrors((s) => ({ ...s, lastName: "Last name must be alphabet" }));
      return false;
    } else {
      setErrors((s) => ({ ...s, lastName: "" }));
    }

    if (validator.isEmpty(email + "")) {
      setErrors((s) => ({ ...s, email: "Email is required" }));
      return false;
    } else if (
      !validator.isEmpty(email + "") &&
      !validator.isEmail(email + "")
    ) {
      setErrors((s) => ({ ...s, email: "Invalid email address" }));
      return false;
    } else {
      setErrors((s) => ({ ...s, email: "" }));
    }

    if (validator.isEmpty(phone + "")) {
      setErrors((s) => ({ ...s, phone: "Phone no. is required" }));
      return false;
    } else if (
      !validator.isEmpty(phone + "") &&
      !validator.isNumeric(phone + "")
    ) {
      setErrors((s) => ({ ...s, phone: "Invalid phone no." }));
      return false;
    } else if (
      !validator.isEmpty(phone + "") &&
      validator.isNumeric(phone + "") &&
      ((phone + "")?.length < 7 || (phone + "")?.length > 10)
    ) {
      setErrors((s) => ({ ...s, phone: "Phone no. must be 7 or 10 digit" }));
      return false;
    } else {
      setErrors((s) => ({ ...s, phone: "" }));
    }

    if (validator.isEmpty(gender + "")) {
      setErrors((s) => ({ ...s, gender: "Gender is required" }));
      return false;
    } else {
      setErrors((s) => ({ ...s, gender: "" }));
    }
    if (validator.isEmpty(password + "")) {
      setErrors((s) => ({ ...s, password: "Password is required" }));
      return false;
    } else {
      setErrors((s) => ({ ...s, password: "" }));
    }
    if (!validator.isEmpty("" + password + "")) {
      if (
        !validator.isLength("" + password, { min: 8 }) ||
        !validator.isLength("" + password, { max: 16 }) ||
        !/[!@#$%^&*?_~]{1,}/.test(password) ||
        !/[a-z]{1,}/.test(password) ||
        !/[A-Z]{1,}/.test(password) ||
        !/[0-9]{1,}/.test(password)
      ) {
        setErrors((s) => ({
          ...s,
          password:
            "Password must have atleast 8-16 characters, should contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric",
        }));
        setErrors((s) => ({ ...s, matchPass: "" }));
        return false;
      } else {
        setErrors((s) => ({ ...s, password: "" }));
      }
    }

    if (validator.isEmpty(confirmPassword + "")) {
      setErrors((s) => ({
        ...s,
        confirmPassword: "Confirm password is required",
      }));
      return false;
    } else {
      setErrors((s) => ({ ...s, confirmPassword: "" }));
    }

    if (validator.isEmpty(dob + "")) {
      setErrors((s) => ({ ...s, dob: "Dob is required" }));
      return false;
    } else {
      setErrors((s) => ({ ...s, dob: "" }));
    }

    if (!termCondition) {
      setErrors((s) => ({ ...s, termCondition: "Term condition is required" }));
      return false;
    } else {
      setErrors((s) => ({ ...s, termCondition: "" }));
    }

    if (
      !validator.isEmpty(confirmPassword + "") &&
      !validator.isEmpty(password + "") &&
      confirmPassword != password
    ) {
      setErrors((s) => ({ ...s, matchPass: "Password not matched" }));
      return false;
    } else {
      setErrors((s) => ({ ...s, matchPass: "" }));
    }

    // if (!validator.isEmpty("" + confirmPassword)){
    //   if (
    //     !validator.isLength("" + confirmPassword, { min: 8 }) ||
    //     !validator.isLength("" + confirmPassword, { max: 16 }) ||
    //     !/[!@#$%^&*?_~]{1,}/.test(confirmPassword) || !/[a-z]{1,}/.test(confirmPassword) ||
    //     !/[A-Z]{1,}/.test(confirmPassword) || !/[0-9]{1,}/.test(confirmPassword)
    //   ) {
    //     setErrors((s)=>({...s,password:"Confirm password must have atleast 8-16 characters, should contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric"}))
    //     setErrors((s)=>({...s,matchPass:""}))
    //   }else{
    //     setErrors((s)=>({...s,confirmPassword:""}))
    //   }
    // }

    return true;
  };

  const handlePhone = (e) => {
    if (validator.isNumeric(e.target.value)) {
      setPhone(e.target.value);
      setErrors((s) => ({ ...s, phone: "" }));
    } else {
      setPhone(e.target.value);
      setErrors((s) => ({ ...s, phone: "Phone number must be numeric" }));
    }
  };

  return (
    <React.Fragment>
      <section className="signin-body">
        <div className="container-fluid">
          <div className="row p-0">
            <div className="col-md-6 signup-page sign-in-form">
              <div className="navbar-brand">
                <a href={`${GUEST_LINK}`}>
                  <img
                    src="/assets/images/logo.png"
                    style={{ height: 60, width: 55 }}
                    alt="logo"
                  />
                </a>
              </div>
              <div className="heading pt-5">
                <h2 className="heading-grey text-left ">Sign up to Playit </h2>
                <h2 className="al-text pt-4 text-left">
                  Already have an account?{" "}
                  <a href={`${CUSTOMER_LINK}/login`}>Login</a>
                </h2>
              </div>

              <form
                className="subscribe-form ptb-60"
                autoComplete="off"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="row p-0">
                  <div className="col-md-12">
                    <div className="d-flex pic-img">
                      <div className="upload-img">
                        {profilePic ? (
                          <img
                            src={URL.createObjectURL(profilePic)}
                            style={{ width: 140, height: 140 }}
                          />
                        ) : (
                          <img
                            src="assets/images/pic_placeholder.png"
                            alt="logo"
                          />
                        )}
                      </div>
                      <div className="pa-left-4 profile-pic-upload">
                        <p className="mobile-text text-left p-0">
                          Profile picture
                        </p>
                        <div className="button justify-content-center d-flex">
                          <div className="upload-btn-wrapper">
                            <button className="btn upload-btn file-btn">
                              Upload Image
                            </button>
                            <input
                              type="file"
                              name="myfile"
                              value={
                                profilePic?.filename ? profilePic?.filename : ""
                              }
                              onChange={(e) =>
                                setProfilePic(
                                  e.target.files?.length > 0
                                    ? e.target.files[0]
                                    : ""
                                )
                              }
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-light remove-btn file-btn "
                            disabled={!profilePic}
                            onClick={() => setProfilePic("")}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 pt-4">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="material-icons-outlined">
                          person_outline
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) =>
                          setFirstName((e.target.value + "")?.trim())
                        }
                      />
                    </div>
                    <ErrorShow errorMsg={errors.firstName} />
                  </div>
                  <div className="col-md-6 pt-4">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="material-icons-outlined">
                          person_outline
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) =>
                          setLastName((e.target.value + "")?.trim())
                        }
                      />
                    </div>
                    <ErrorShow errorMsg={errors.lastName} />
                  </div>
                </div>
                <div className="row pt-4">
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="material-icons-outlined">
                          mail_outline
                        </span>
                      </div>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email Id"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <ErrorShow errorMsg={errors.email} />
                  </div>

                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="material-icons-outlined">phone</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone number"
                        maxLength={10}
                        value={phone}
                        onChange={(e) => handlePhone(e)}
                      />
                    </div>
                    <ErrorShow errorMsg={errors.phone} />
                  </div>
                </div>
                <div className="row pt-4">
                  <div className="col-lg-6 col-md-12">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="material-icons-outlined ">male</span>
                      </div>
                      <p className="desc-grey pa-left-40 desc-contact ">
                        Gender
                      </p>
                      <br />
                    </div>
                    <div className="input-field d-flex gender-btn pa-left-40 pt-4 mt-4 pb-2 text-left">
                      <div className="checkmark-one">
                        <input
                          type="radio"
                          name="gender"
                          id="male"
                          value="male"
                          onChange={(e) => setGender(e.target.value)}
                        />
                        Male
                      </div>
                      <div className="checkmark-two ">
                        <input
                          type="radio"
                          name="gender"
                          id="female"
                          value="female"
                          onChange={(e) => setGender(e.target.value)}
                        />{" "}
                        Female
                      </div>
                    </div>{" "}
                    <ErrorShow errorMsg={errors.gender} />
                  </div>

                  <div className="col-lg-6 col-md-12 dob-col">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="material-icons-outlined">cake</span>
                      </div>
                      <p className="desc-grey desc-contact pa-left-40">
                        Date of birth
                      </p>
                    </div>
                    <div className="input-group" style={{ zIndex: 2 }}>
                      <input
                        type="date"
                        className="form-control"
                        max={new Date()?.getFullYear() - 1 + "-01-01"}
                        min={new Date()?.getFullYear() - 100 + "-01-01"}
                        placeholder="DOB"
                        onKeyDown={(e) => e.preventDefault()}
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                      />

                      <div className="input-group-append date-icon ">
                        <span className="material-icons-outlined">event</span>
                      </div>
                    </div>
                    <ErrorShow errorMsg={errors.dob} />
                  </div>
                </div>
                <div className="row pt-4">
                  <div className="col-lg-6 col-md-12">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="material-icons-outlined">lock</span>
                      </div>
                      <input
                        type={toggle1 ? "text" : "password"}
                        className="form-control"
                        placeholder="Password"
                        maxLength={10}
                        minLength={8}
                        value={password}
                        onChange={(e) =>
                          setPassword((e.target.value + "")?.trim())
                        }
                      />
                    </div>
                    <ErrorShow errorMsg={errors.password} />
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="material-icons-outlined">lock</span>
                      </div>
                      <input
                        type={toggle2 ? "text" : "password"}
                        className="form-control"
                        placeholder="Confirm Password"
                        maxLength="10"
                        value={confirmPassword}
                        onChange={(e) =>
                          setConfirmPassword((e.target.value + "")?.trim())
                        }
                      />
                      <ErrorShow errorMsg={errors.confirmPassword} />
                    </div>
                  </div>

                  <div
                    className="input-group-append"
                    onClick={handleShowPassword2}
                  >
                    <span
                      className="material-icons-outlined text-danger"
                      style={
                        errors.confirmPassword
                          ? {
                              marginRight: "27px",
                              cursor: "pointer",
                              marginBottom: 50,
                            }
                          : {
                              marginRight: "27px",
                              cursor: "pointer",
                              marginBottom: 0,
                            }
                      }
                    >
                      {toggle2 ? "visibility" : "visibility_off"}
                    </span>
                  </div>
                </div>
                <ErrorShow errorMsg={errors.matchPass} />

                <div className="row pt-3 pb-3">
                  <div className="col-md-12">
                    <div className="input-group pt-4">
                      <div className="input-group-prepend">
                        <div className="checkmark">
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Yes"
                            onChange={(e) =>
                              setTermsCondition(e.currentTarget.checked)
                            }
                          />
                        </div>

                        <label htmlFor="vehicle1" className="check-para">
                          I agree to <Link to="/tnc">terms and conditions</Link>
                        </label>
                      </div>
                    </div>
                    <ErrorShow errorMsg={errors.termCondition} />
                  </div>
                </div>
                {isLoading ? (
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <div className="btn-wrap col-auto pt-4">
                    <button
                      className="red-btn-lg hvr-icon-push contact-button"
                      onClick={() => sendOtp()}
                    >
                      {" "}
                      Create an account Now!
                    </button>
                  </div>
                )}

                {/* <div className="separator-login"></div> */}
                {/* <div className=" socail-tag">
                  <div className="float-left btn-wrap col-lg-6 col-md-12 ">
                    <a
                      href="https://twitter.com/"
                      className="red-btn-lg hvr-icon-push twt-btn common-btn"
                    >
                      <img
                        src="assets/images/twitter.png"
                        alt="logo"
                        className="img-fluid social-img"
                      />
                      Sign Up with Twitter
                    </a>
                  </div>
                  <div className="float-right btn-wrap col-lg-6 col-md-12">
                    <a
                      href="https://www.facebook.com/"
                      className="red-btn-lg hvr-icon-push fb-btn common-btn "
                    >
                      <img
                        src="assets/images/facebook.png"
                        alt="logo"
                        className="img-fluid social-img"
                      />
                      Sign Up with Facebook
                    </a>
                  </div>
                </div> */}
              </form>
            </div>
            <div className="col-md-6 banner-signup p-0">
              <div className="image-banner">
                <img
                  src="assets/images/banner-signup.png"
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <SignupModel
        data={{ customerData, userStatus }}
        doneCB={() => resetFields()}
        resendOtp={() => sendOtp()}
        handleToggle={() => setModal(!isModal)}
        isModal={isModal}
      />
    </React.Fragment>
  );
};

export default SignUp;
