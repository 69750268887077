/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import serverApi from "../../../api.json";
import { scrollNow } from '../../../helpers';
import callApi from "../../../service/callApi";
import BookingCard from './BookingCard';

const ProtectedRecommendedLayout = () => {

    const [booking, setBooking] = useState([])
    const [isLoading, setLoader] = useState(false)
    const [showAll,setShowAll] = useState(false)


    useEffect(async () => {
        setLoader(true)
        let url = ""
        if(showAll){
            url = `${serverApi.recommendedBooking}`
        }else{
            url = `${serverApi.recommendedBooking}?limit=${3}`
        }
        await callApi.get(url)
            .then((res) => {
                setLoader(false)
                if (res?.data.data && res?.data?.data?.length > 0) {
                    setBooking(res?.data?.data)
                } else {
                    setBooking([])
                }
            }).catch((e) => {
                setLoader(false)
                console.log(e)
            })
    }, [showAll])

    



    return (
        <section class="sports-list-wrap bg-dark-grey ptb-80-100">
            <div class="container" id="scroll1">
                <div class="row">
                    <div class="col-md-12">
                        <div class="top-section">
                            <h2 class="heading-grey">
                                Recommended Booking
                            </h2>
                            <p class="desc-grey pt-4">
                                Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis fermentum justo, sit amet convallis arcu<br />
                                interdum vel. Suspendisse vulputate posuere risus, quis consectetur neque scelerisque.
                            </p>
                        </div>

                    </div>
                    <div class="sports-list booking-list">
                        <div className="ptb-70-90 row">

                            {isLoading ? (
                                <div className="m-auto">
                                    <div class="spinner-border p-5" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : booking?.length > 0 ? booking?.map((b, index) => <BookingCard key={index} info={b} />) : <div className='col-lg-12' style={{ textAlign: 'center' }}>
                                <p class="desc-grey pt-4">No booking available.</p>
                            </div>}
                        </div>
                        {(booking?.length>=3) && (
                            <div class="btn-wrap" onClick={()=>{setShowAll(!showAll);scrollNow("scroll1")}}>
                            <button class="viewall-btn common-btn blue-btn-lg">{!showAll?"View All Recommended Booking":"Show Less Recommended Booking"}</button>
                        </div>
                        )}
                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProtectedRecommendedLayout
