import React, { useEffect, useState } from 'react';
import callApi from '../../service/callApi';
import BookItem from './BookItem2';
import api from '../../api.json'
import { toast } from 'react-toastify';
// import moment from 'moment';

const Upcomming = ({downloadPDF,isReloadCb}) => {

    const [bookings, setBooking] = useState([])
    const [isLoader, setLoader] = useState(false)
    const [isReload, setReload] = useState(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        setLoader(true)
        await callApi.get(api.getUpcommingBooking)
            .then((res) => {
                setLoader(false)
                if (res?.data && res?.data?.data?.length > 0) {
                    setBooking(res?.data?.data)
                } else {
                    setBooking([])
                }
            }).catch((e) => {
                setLoader(false)
                console.log(e);
                if (e?.response && e?.response?.data?.error) {
                    toast.error(e?.response?.data?.error)
                } else {
                    toast.error("Error while processing your request")
                }
            })
    }, [isReload])

    const cancelBooking =async(e)=>{
        try{
            const Administrator = e?.facilitiesDetails?.Administrator
        const FinanceUser = e?.facilitiesDetails?.FinanceUser
        const Supervisor = e?.serviceClassDetails?.Supervisor
        let arr = []
        if(Administrator){
            arr.push(Administrator)
        }
        if(FinanceUser){
            arr.push(FinanceUser)
        }
        if(Supervisor){
            arr.push(Supervisor)
        }
        const req = {
            facilityName:e?.facilitiesDetails?.Name,
            facilityId:e?.facilitiesDetails?._id,
        }
        const res = await callApi.post(`${api.bookingCancellation}/${e?._id}`,{
            ids:arr,
            details:req
        })
        toast.success("Booking is cancelled successfully!")
        setReload(!isReload)
        isReloadCb(!isReload)
        }catch(e){
            console.log(e)
            toast.error("Unable to cancel the booking!")
        }
    }



    return <div className="tab-pane fade" id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
             {isLoader ? <div style={{display:'block',margin:'auto'}}>
            <div class="spinner-border" role="status" style={{padding:30}}>
            <span class="sr-only">Loading...</span>
        </div> 
        </div>: bookings?.length > 0 ? bookings?.map((data, index) => <BookItem key={index} data={data} downloadPDF={downloadPDF} handleCancellation={(e)=>cancelBooking(e)} />) : <p className='text-center'>No upcoming booking</p>}
    </div>
};

export default Upcomming;
