import React from 'react';

const AmenityItem = ({item}) => {
  return (
    <li className="p-0">
    <div className="eminities-content">
        <span className="material-icons-outlined check-icon">
            done
        </span>
        <p>{item?.name}</p>
    </div>
</li>
  )
};

export default AmenityItem;
