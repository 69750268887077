import React from 'react'

const EminitiesItem = ({item}) => {
  return (
    <div className="col-md-6 eminities-content pb-1">
    <span className="material-icons-outlined check-icon">
        done
    </span>
    <p>
        {item?.name}
    </p>
</div>
  )
}

export default EminitiesItem