import React from 'react'
import moment from 'moment'

const TimeDateItem = ({item,index}) => {
    // console.log("item",item)
  return (
    <tr>
      <th className='text-left'>{item[0]?moment(item[0]).format("DD-MM-YYYY"):""}</th>
     <td className='text-left'>{item[1]?item[1]?.map((s,key)=><span key={key}>{`${s?.start} - ${s?.end}, `}</span>):""}</td>
    </tr>
  )
}

export default TimeDateItem