import React from 'react';
import moment from 'moment'
import AmenityItem from './AmenityItem';
import TimeDateItem from '../OrderConfirmation/TimeDateItem'
import api from '../../api.json'

const BookItem = ({data,downloadPDF}) => {
    return (
        <div className="tab-details mt-3">
        <div className="billing-list d-flex prev-booking pt-3">
            <ul className="booking-ul">
                <li className="p-0">
                    <div className="checkout-img booking-img">
                        <figure>
                            <img src={(data?.facilitiesDetails && data?.facilitiesDetails?.Images?.length > 0) ? `${api?.base_url}/${data?.facilitiesDetails?.Images[0]}` : "assets/images/image-01.png"} alt="logo" className="img-fluid" />
                        </figure>
                    </div>
                </li>
                <li className="">
                    <div className="sports-info">

                        <h3 className="m-0 text-left">{`${data?.ServiceName}`}</h3>
                        
                        <div className="desc-grey-about p-2">
                            <p className="sports-location text-left p-0 m-0">{data?.facilitiesDetails?.Address}</p>
                        </div>
                        <div className="btn-wrap tag-btn justify-content-start">
                            <button type="button" className="btn title-btn text-blue">{`${data?.serviceClassDetails?.type=='service'?'Service':'Classes'}`}</button>&nbsp;&nbsp;
                            <button type="button" className="btn title-btn text-grey">{data?.categories?.Category}</button>
                        </div>

                    </div>
                </li>
                <li className="">
                    <p className="terms-heading text-left m-0">Booking date and time
                    </p>
                    <p className="desc-grey-about text-left">{moment(data?.CreationDate).format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                    {
                        data?.cancelledDate?(
                            <>
                             <p className="terms-heading text-left m-0">Booking cancelled date and time</p>
                            <p className="desc-grey-about text-left">{moment(data?.cancelledDate).format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                            </>
                        ):null
                    }
                </li>
                
                <li className="booking-status">
                    <p className="terms-heading m-0">Status
                    </p>

                    <button type="button" className={`status ${(data?.Status=='Completed'||data?.Status=='Active')?'status-paid':data?.Status=='In Progress'?'status-inactive':'status-unpaid'}`}>{data?.Status}</button>
                </li>
                <li className="prev-booking-list">
                        <div className="pa-left-4">
                            <p className="terms-heading text-left m-0">Booking Price
                            </p>
                            <p className="text-left d-flex align-items-center m-0"><span className="text-danger terms-heading"> QR</span> <span className="prev-p">{(data?.Price)?.toFixed(2)}</span></p>
                          
                            {/* <p className="order-p">Discount applied : $ {data?.Price}</p> */}
                        </div>
                        <div className="btn">
                                <button type="button" onClick={()=>downloadPDF(data)} className="red-btn-lg hvr-icon-push checkout-btn"> <span className='fa fa-file-pdf-o'></span> Print Details</button>
                        </div>
                    </li>
                <li>
                    <div className="dropdown">
                        <button className="btn dropdown-toggle booking-dropdown-btn" onclick="myFunction()" type="button" id="dropdownMenuButton-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                        </button>

                    </div>
                </li>

            </ul>
        </div>
        <div className="hidden-sec hidden-booking hidden-div">
            <hr />
            <div className="hide-prev-list billing-list d-flex prev-booking plr-70">
                <ul className="booking-ul">
                    <li className="p-0">
                        <div className="sports-eminities">
                            <p className="terms-heading text-left m-0">Amenities
                            </p>
                            <ul className="justify-content-start p-0">
                              {data?.amenities?.length>0 && (
                                data?.amenities?.map((item,key)=><AmenityItem key={key} item={item}/>)
                              )}
                            </ul>
                        </div>
                    </li>
                    {
                        data?.serviceClassDetails?.type!='service'?(
                            <li className="">
                        <p className="terms-heading text-left m-0">No. of people

                        </p>
                        <p className="desc-grey-about text-left">{data?.BookedQuantity} </p>
                         </li>
                        ):null
                    }

                    {
                        data?.serviceClassDetails?.type!='service'?(
                            <li className="">
                        <p className="terms-heading text-left m-0">Class duration</p>
                        <p className="desc-grey-about text-left" style={{width:220}}>{`${moment(data?.serviceClassDetails?.StartDate).format("DD-MM-YYYY")} to ${moment(data?.serviceClassDetails?.EndDate).format("DD-MM-YYYY")}`} </p>
                         </li>
                        ):null
                    }
                   
                    <li className="plr-70">
                        <p className="terms-heading text-left m-0">Payment mode
                        </p>
                        <p className="desc-grey-about text-left">{data?.paymentMode}</p>
                    </li>
                </ul>

            </div>
            {
                        data?.slots?(
                            <div className='response-table'>
                                {(data?.slots && Object.keys(data?.slots)?.length!=0)?(
                                    <table className='table'>
                                        <tr>
                                            <th className='text-left'>Booked Dates</th>
                                            <th className='text-left'>Time Slots</th>
                                        </tr>
                                      {Object.entries(data?.slots)?.map((s,index)=><TimeDateItem item={s}  key={index} />)}
                                    </table>
                                ):null}
                            </div>
                        ):null
                    }
        </div>
    </div>
    )
};

export default BookItem;
