/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Footer from '../Layouts/Footer';
import Header from '../Layouts/Header';
import ImageSlider from '../Layouts/ImageSlider';
import validator from 'validator'
import MyCalendar from './Calendar';
// import { GettingTime } from '../../helpers';
import moment from 'moment'
import api from '../../api.json'
import callApi from "../../service/callApi";
import { toast } from 'react-toastify';
import ImageItem from './ImageItem';
import EminitiesItem from './EminitiesItem';
import Loader from '../Layouts/Loader'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getUserInfo, isAuth } from '../../helpers';
import { CUSTOMER_LINK, MAP_KEY } from '../../config'
// import Map from './Map';


const Booking = (props) => {

    // console.log("MAP_KEY",MAP_KEY)
    const [quantity, setQuantity] = React.useState(0)
    const [selectedDate, setSelectDate] = React.useState(new Date())
    const [availableSlot, setAvailableSlot] = React.useState([])
    const [bookingDetails, setBookingDetails] = React.useState({})
    const [slotDateSelected, setSlotDate] = React.useState({})
    const [availableSlots, setAvailableSlots] = React.useState([])
    const [isLoader, setLoading] = React.useState(false)
    const params = useParams()
    const navigate = useNavigate()
    const [oldDates, setoldDates] = React.useState([])
    const [oldSlots, setoldSlots] = React.useState([])
    const [customerLimit, setCustomerLimit] = React.useState(null)

    // console.log("params", params)

    // const type="service"

    // const availableSlots = [
    //     { name: "Monday", time: [{ start: '10:00 AM', end: '11:00 AM' },{ start: '11:00 AM', end: '12:00 AM' }],date:ISODate("2022-02-14T00:00:00.000Z") },
    //     { name: "Tuesday", time: [{ start: '5:00 PM', end: '6:00 PM' },{ start: '6:00 PM', end: '7:00 PM' }] ,date:ISODate("2022-02-15T00:00:00.000Z")},
    //     { name: "Wednesday", time: [{ start: '2:00 PM', end: '3:00 PM' },{ start: '3:00 PM', end: '4:00 PM' }],date:ISODate("2022-02-16T00:00:00.000Z") },
    //     { name: "Thursday", time: [{ start: '12:00 PM', end: '1:00 PM' },{ start: '02:00 PM', end: '3:00 PM' }],date:ISODate("2022-02-17T00:00:00.000Z") },
    //     { name: "Friday", time: [{ start: '4:00 PM', end: '5:00 PM' },{ start: '6:00 PM', end: '7:00 PM' }] ,date:ISODate("2022-02-18T00:00:00.000Z")},
    //     { name: "Saturday", time: [{ start: '5:00 AM', end: '6:00 AM' },{ start: '7:00 AM', end: '8:00 AM' }] ,date:ISODate("2022-02-19T00:00:00.000Z")}
    // ]

    const getDate = (date) => {
        let d = new Date(date)
        d.setHours(0)
        d.setMinutes(0)
        return new Date(d)
    }


    useEffect(async () => {
        if (params?._id) {
            setLoading(true)
            let url = `${api.bookingDetails}/${params?._id}`
            if (isAuth() && getUserInfo()?.customerDetails?._id) {
                url = `${url}?client_id=${getUserInfo()?.customerDetails?._id}`
            }
            await callApi.get(url)
                .then((res) => {
                    setLoading(false)
                    if (res?.data && res?.data?.details) {
                        setBookingDetails(res?.data?.details)
                        const bookingInfo = res?.data?.details?.bookingInfo
                        const details = res?.data?.details
                        if (bookingInfo && bookingInfo?.length != 0) {
                            let dates = []
                            let slots = []
                            bookingInfo?.map((list) => {
                                if (list?.dates?.length > 0 && Object.keys(list?.slots)?.length > 0) {
                                    dates = dates.concat(list?.dates)
                                }
                            })
                            bookingInfo?.map((list) => {
                                if (list?.slots && Object.keys(list?.slots)?.length > 0) {
                                    slots.push(list?.slots)
                                }
                            })

                            if (dates?.length != 0) {
                                setoldDates(dates.map((d) => getDate(d)))
                            }
                            if (slots?.length != 0) {
                                setoldSlots(slots)
                            }
                        }
                        if (details && details?.TotalCustomer) {
                            setCustomerLimit(parseInt(details?.TotalCustomer))
                        } else {
                            setCustomerLimit(null)
                        }
                        if (res?.data?.details && res?.data?.details?.TimeSetting?.length > 0) {

                            let timeset = res?.data?.details?.TimeSetting.map((data) => ({ date: getDate(data?.date), name: data?.name, time: data?.time }))
                            // console.log("timeset", timeset)
                            setAvailableSlots(timeset)
                        } else {
                            setAvailableSlots([])
                        }

                    } else {
                        setAvailableSlots([])
                        setBookingDetails({})
                    }
                }).catch((e) => {
                    setLoading(false)
                    console.log('error', e);
                    if (e?.response && e?.response?.data?.error) {
                        toast.error(e?.response?.data?.error)
                    } else {
                        toast.error("Error! while processing your request")
                    }
                })
        }
    }, [])

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    useEffect(() => {
        if (selectedDate && new Date().getDate() + 1 !== selectedDate.getDate()) {
            const day = days[new Date(selectedDate).getDay()]

            let filteredSlot = availableSlots.filter((s) => moment(s.date).format("DD-MM-YYYY") == moment(selectedDate).format("DD-MM-YYYY"))
            // console.log("filteredSlot", filteredSlot)
            if (filteredSlot && filteredSlot?.length !== 0 && filteredSlot[0]?.time?.length !== 0) {
                setAvailableSlot(filteredSlot[0]?.time)
            } else {
                setAvailableSlot([])
            }
        } else {
            setAvailableSlot([])
        }
    }, [selectedDate])


    const checkOldSlots = (selectedSlot) => {

        let validSlots = true
        if (oldSlots && oldSlots?.length != 0) {
            oldSlots.forEach((value) => {
                const slots = value[selectedDate]
                // console.log('selectedDate',selectedDate)
                // console.log('slots',slots)
                // console.log('value',value)
                const exists = (slots && slots?.length > 0) ? slots?.filter((s) => s?.start === selectedSlot?.start && s?.end === selectedSlot?.end) : []
                if (exists && exists?.length != 0) {
                    validSlots = false
                    return false
                }
            })
        }
        return validSlots
    }


    const handleSlotPick = (selectedSlot) => {
        // console.log(selectedDate, checkOldSlots(selectedSlot))
        if (selectedDate && selectedSlot && checkOldSlots(selectedSlot)) {
            if (slotDateSelected && slotDateSelected[selectedDate]) {
                const slots = slotDateSelected[selectedDate]
                //   console.log("slotsDate",selectedDate,slots)
                const exists = (slots && slots?.length > 0) ? slots?.filter((s) => s?.start === selectedSlot?.start && s?.end === selectedSlot?.end) : []
                if (exists?.length != 0) {
                    const exists2 = (slots && slots?.length > 0) ? slots?.filter((s) => s?.start !== selectedSlot?.start && s?.end !== selectedSlot?.end) : []
                    // console.log("exists2",exists2)
                    setSlotDate((s) => ({ ...s, [selectedDate]: exists2 }))
                } else {
                    const old = slotDateSelected[selectedDate]
                    setSlotDate((s) => ({ ...s, [selectedDate]: [...old, selectedSlot] }))
                }
            } else {
                setSlotDate((s) => ({ ...s, [selectedDate]: [selectedSlot] }))
            }
        }
    }

    useEffect(() => {
        // console.log("totalSlots------", selectedDate)

    }, [slotDateSelected, selectedDate])

    const changeSelectColor = (slot) => {
        let colorClass = "timing-list"
        if (!checkOldSlots(slot)) {
            colorClass = 'unable-list'
        } else if (slotDateSelected[selectedDate]) {
            [...slotDateSelected[selectedDate]]?.forEach((s) => {
                if (slot?.start === s?.start && slot?.end === s?.end) {
                    colorClass = 'able-list'
                }
            })
        }
        return colorClass
    }


    const handleQuantity = (e) => {
        let val = e.target.value
        // console.log('val', val);
        if (!validator.isEmpty(val) && validator.isNumeric(val)) {
            setQuantity(parseInt(val))
        } else if (!val) {
            setQuantity(0)
        }
    }

    const totalSlots = () => {
        let totalSlots = 0
        if (slotDateSelected) {
            for (const [key, value] of Object.entries(slotDateSelected)) {
                if (value?.length != 0) {
                    totalSlots += value?.length
                }
            }
        }
        return totalSlots
    }

    const validate = () => {
        if (totalSlots() == 0) {
            toast.warn("Please select a available time slot!")
            return false
        }
        return true
    }

    const validate2 = () => {
        if (parseInt(quantity) === 0) {
            toast.warn("Please provide a quantity!")
            return false
        } else {
            return true
        }
    }

    const onProceed = () => {
        if (bookingDetails?.type == "service") {
            if (validate()) {
                let slots = {}
                for (const [key, value] of Object.entries(slotDateSelected)) {
                    if (value?.length != 0) {
                        slots[key] = value
                    }
                }
                let request = {
                    serviceClassId: bookingDetails?._id,
                    Price: CalculatePrice((parseFloat(bookingDetails?.Price)).toFixed(2)),
                    slots: slots,
                    total_hours: totalSlots(),
                    bookingDetails
                }
                // console.log("bookingDetails",request)
                localStorage.setItem("booking_checkout", JSON.stringify(request))
                if (isAuth()) {
                    navigate("/checkout")
                } else {
                    toast.info("Need to login/sign up before checkout!")
                    // navigate("/login")
                    window.location = `${CUSTOMER_LINK}/login`
                }
            }
        } else if (validate() && validate2()) {
            let slots = {}
            for (const [key, value] of Object.entries(slotDateSelected)) {
                if (value?.length != 0) {
                    slots[key] = value
                }
            }
            let request = {
                serviceClassId: bookingDetails?._id,
                Price: (bookingDetails?.Price * parseInt(quantity)).toFixed(2),
                quantity: quantity,
                slots: slots,
                bookingDetails
            }
            localStorage.setItem("booking_checkout", JSON.stringify(request))
            if (isAuth()) {
                navigate("/checkout")
            } else {
                toast.info("Need to login/sign up before checkout!")
                // navigate("/login")
                window.location = `${CUSTOMER_LINK}/login`
            }
            // console.log("bookingDetails",request)
        }
    }

    const CalculatePrice = (price = 0) => {
        return totalSlots() == 0 ? parseFloat(bookingDetails?.Price)?.toFixed(2) : (price * totalSlots())?.toFixed(2)
    }


    const handleAddQuantity = () => {
        // console.log("quantity", quantity, customerLimit)
        let newNum = parseInt(quantity) + 1
        if (customerLimit && (newNum > customerLimit || (newNum === 0 && customerLimit == 0))) {
            toast.warn("No. of people limit is exceeded")
        } else {
            setQuantity(newNum)
        }

    }

    return (
        <div>
            <Header />


            <section className="hero-banner hero-banner-height" style={{ backgroundImage: 'url(/assets/images/about-us.jpg)' }}>
                <div className="container">
                    <div className="row">
                        <div className="hero-banner-aboutus hero-banner-content">
                            <h2 className="heading-white">
                                {(bookingDetails?.facilitiesDetails && bookingDetails?.facilitiesDetails?.length > 0) ? bookingDetails?.facilitiesDetails[0]?.Name : null}
                            </h2>
                            <p className="desc-white pt-4 text-center">
                                {bookingDetails?.categories?.length > 0 ? bookingDetails?.categories[0]?.Category : null}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {isLoader ? <Loader /> : (
                <>
                    <section className="bg-grey ptb-70-90">
                        <div className="container">
                            <div className="plr-70 service-class-section">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12">
                                        <div className="slider-div">
                                            <div className="slideshow">
                                                {(bookingDetails?.Images && bookingDetails?.Images?.length > 0) ? (
                                                    <ImageSlider>
                                                        {bookingDetails?.Images?.map((i, key) => <ImageItem file={i} key={key} />)}
                                                    </ImageSlider>
                                                ) : (
                                                    <ImageSlider>
                                                        {[null]?.map((i, key) => <ImageItem file={i} key={key} />)}
                                                    </ImageSlider>
                                                )}

                                            </div>

                                            {/* <div className="slideshow">
                                    <div className="slide">
                                        <figure>
                                            <img src="/assets/images/order-image.png" alt="img" className="img-fluid" />
                                        </figure>

                                    </div>
                                    <div className="slide">
                                        <figure>
                                            <img src="/assets/images/order-image.png" alt="img" className="img-fluid" />
                                        </figure>
                                    </div>
                                    <div className="slide">
                                        <figure>
                                            <img src="/assets/images/order-image.png" alt="img" className="img-fluid" />
                                        </figure>
                                    </div>
                                    <div className="slide">
                                        <figure>
                                            <img src="/assets/images/order-image.png" alt="img" className="img-fluid" />
                                        </figure>
                                    </div>
                                </div> */}
                                        </div>

                                        <div className="book-online-section">
                                            <div className="serive-className-para mt-2">
                                                <p className='text-left'><strong>Book Online at {(bookingDetails?.facilitiesDetails && bookingDetails?.facilitiesDetails?.length > 0 && bookingDetails?.facilitiesDetails[0]?.Name) ? bookingDetails?.facilitiesDetails[0]?.Name : null}</strong></p>
                                            </div>


                                            {
                                                bookingDetails?.type === 'class' ? (
                                                    <div className="calender">
                                                        <div className="wrapper text-left">
                                                            <div id="calendari"></div>
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <th>Class name</th>
                                                                        <td>{bookingDetails?.Name ? bookingDetails?.Name : "---"}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Start date</th>
                                                                        <td>{bookingDetails?.StartDate ? moment(bookingDetails?.StartDate).format("DD-MM-YY") : "---"}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>End date</th>
                                                                        <td>{bookingDetails?.EndDate ? moment(bookingDetails?.EndDate).format("DD-MM-YY") : "---"}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Court name</th>
                                                                        <td>{bookingDetails?.Court?.CourtName ? bookingDetails?.Court?.CourtName : "---"}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Court format</th>
                                                                        <td>{bookingDetails?.Court?.Format ? bookingDetails?.Court?.Format : "---"}</td>
                                                                    </tr>
                                                                    {
                                                                        bookingDetails?.providerInfo?.ShowProvider && <tr>
                                                                            <th>Provider name</th>
                                                                            <td>{bookingDetails?.providerInfo?.Name}</td>
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                ) : <div className="calender">
                                                    <div className="wrapper text-left">
                                                        <div id="calendari"></div>
                                                        <table class="table">
                                                            <tbody>
                                                                <tr>
                                                                    <th>Service name</th>
                                                                    <td>{bookingDetails?.Name ? bookingDetails?.Name : "---"}</td>
                                                                </tr>
                                                                {/* <tr>
                                                                    <th>Start date</th>
                                                                    <td>{bookingDetails?.StartDate ? moment(bookingDetails?.StartDate).format("DD-MM-YY") : "---"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>End date</th>
                                                                    <td>{bookingDetails?.EndDate ? moment(bookingDetails?.EndDate).format("DD-MM-YY") : "---"}</td>
                                                                </tr> */}
                                                                <tr>
                                                                    <th>Court name</th>
                                                                    <td>{bookingDetails?.Court?.CourtName ? bookingDetails?.Court?.CourtName : "---"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Court format</th>
                                                                    <td>{bookingDetails?.Court?.Format ? bookingDetails?.Court?.Format : "---"}</td>
                                                                </tr>
                                                                {
                                                                    bookingDetails?.providerInfo?.ShowProvider && <tr>
                                                                        <th>Provider name</th>
                                                                        <td>{bookingDetails?.providerInfo?.Name}</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                moment(bookingDetails?.EndDate).startOf('day') <= moment().startOf('day') ? <div className="alert alert-danger" role="alert">Bookings not available</div> : null
                                            }

                                            <div className="calender">
                                                <div className="wrapper">
                                                    <div id="calendari"></div>
                                                    <figure>
                                                        <MyCalendar slotDateSelected={slotDateSelected} EndDate={bookingDetails?.EndDate} availableSlots={availableSlots} StartDate={bookingDetails?.StartDate} oldDates={oldDates} selectedDate={(e) => setSelectDate(e)} />
                                                        {/* <img src="/assets/images/time-slot.png" alt="img" className="img-fluid" /> */}
                                                    </figure>
                                                </div>
                                            </div>

                                        </div>
                                        {
                                            <div className="slot-ava-div">
                                                <div className="common-para-service">
                                                    <p className="text-left">Availabe Time Slot</p>
                                                    <div className="row">
                                                        {
                                                            availableSlot?.length > 0 && availableSlot?.map((slot, key) => (
                                                                <div className="col-md-4" key={key} onClick={() => handleSlotPick(slot)} style={{ cursor: 'pointer' }}>
                                                                    {/* {slot?.name? <span className="text-center"><strong>{GettingTime(slot?.time?.start)}</strong></span> : null} */}
                                                                    <div className={`${changeSelectColor(slot)} common-time-list`}>
                                                                        {(slot?.start && slot?.end) ? <p style={{ fontSize: 12 }}>{`${slot?.start} - ${slot?.end}`}</p> : null}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    {/* {
                                            availableSlot && <ul className="d-flex">
                                                <li>
                                                    <h2 className="text-center">{GettingTime(availableSlot?.time?.start)}</h2>
                                                    <div className={`timing-list common-time-list`}>
                                                        {(availableSlot?.time?.start && availableSlot?.time?.end) ? <p>{`${availableSlot?.time?.start} - ${availableSlot?.time?.end}`}</p> : null}
                                                    </div>
                                                </li>
                                            </ul>
                                        } */}
                                                </div>
                                            </div>
                                        }

                                    </div>

                                    <div className="col-lg-4 col-md-12">
                                        <div className="google-map">
                                            <div className="responsive-map">
                                                {
                                                    bookingDetails?.facilitiesDetails?.length > 0 ? (
                                                        <iframe src={`https://www.google.com/maps/embed/v1/place?key=${MAP_KEY}&q=${bookingDetails?.facilitiesDetails[0]?.MarkerPosition?.lat},${bookingDetails?.facilitiesDetails[0]?.MarkerPosition?.lng}&zoom=12`} loading="lazy" width="600" height="450" style={{ border: 0 }} allowFullScreen></iframe>
                                                    ) : null
                                                }
                                            </div>
                                        </div>

                                        <div className="quantity-section pt-4">
                                            <div className="serive-className-para">
                                                {bookingDetails?.type == "service" ? <p>Price</p> : <p>No. of people / Price</p>}
                                                <div className="flex-container">
                                                    {
                                                        bookingDetails?.type != "service" && (
                                                            <div className="flex-item-left">
                                                                <div className="content-wrap-left">
                                                                    <form className="subscribe-form service-form">
                                                                        <div className="input-group">
                                                                            <div className="input-group-prepend" onClick={() => setQuantity(parseInt(quantity) > 0 ? parseInt(quantity) - 1 : 0)} style={{ zIndex: 9, cursor: 'pointer' }}>
                                                                                <span className="material-icons-outlined minus-icon">
                                                                                    remove
                                                                                </span>
                                                                            </div>
                                                                            <input type="text" id="quantity" style={{ zIndex: 0 }} name="quantity" className="form-control input-number" defaultValue={0} onChange={handleQuantity} value={quantity} readOnly />
                                                                            <div className="input-group-append" style={{ zIndex: 9, cursor: 'pointer' }} onClick={() => handleAddQuantity()}>
                                                                                <span className="material-icons-outlined plus-icon">
                                                                                    add
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        bookingDetails?.type == "service" ? (
                                                            <>
                                                                <div className="flex-item-left float-center">

                                                                    <div className="content-wrap-center">

                                                                        <p><span className="text-danger">QR </span><span className="quantity-value">{CalculatePrice((parseFloat(bookingDetails?.Price)).toFixed(2))}
                                                                        </span></p>
                                                                    </div>

                                                                </div>
                                                            </>
                                                        ) : <div className="flex-item-right float-right">
                                                            <div className="content-wrap-right">
                                                                <p><span className="text-danger">QR </span><span className="quantity-value">{quantity ? (bookingDetails?.Price * parseInt(quantity)).toFixed(2) : (parseFloat(bookingDetails?.Price)).toFixed(2)}
                                                                </span></p>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                            <hr />
                                            <div className="address-section">
                                                <div className="common-para-service">
                                                    <p className="text-left"> Address</p>
                                                </div>
                                                {/* <!-- <p className="desc-grey text-left"></p> --> */}
                                                <p className="desc-grey-about">{(bookingDetails?.facilitiesDetails && bookingDetails?.facilitiesDetails?.length > 0 && bookingDetails?.facilitiesDetails[0]?.Address) ? bookingDetails?.facilitiesDetails[0]?.Address : ""}</p>
                                            </div>
                                            <hr />
                                            <div className="address-section">
                                                <div className="common-para-service">
                                                    <p className="text-left">Surface</p>
                                                </div>
                                                <ul className="d-flex football-data">
                                                    {bookingDetails?.Court?.Surface ? <li className="football-list"><p>{bookingDetails?.Court?.Surface}</p></li> : '----'}
                                                </ul>
                                            </div>
                                            <hr />
                                            <div className="address-section">
                                                <div className="common-para-service">
                                                    <p className="text-left">Amenities
                                                    </p>
                                                </div>

                                                <div className="sports-eminities service-className-ul">
                                                    <div className="row m-0">
                                                        {(bookingDetails?.amenities && bookingDetails?.amenities?.length > 0) ? bookingDetails?.amenities?.map((item, key) => <EminitiesItem item={item} key={key} />) : null}
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="btn-wrap pt-4">
                                                {moment(bookingDetails?.EndDate).startOf('day') >= moment().startOf('day') && <button onClick={() => onProceed()} className="viewall-btn red-btn-lg profile-btn common-btn">Proceed for checkout</button>}

                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-md-12">
                                        <div className="pt-4">
                                            <div className="common-para-service">
                                                <p className="text-left">Description of the facility</p>
                                            </div>
                                            <div className="d-service-para">
                                                <p className="">{(bookingDetails?.facilitiesDetails && bookingDetails?.facilitiesDetails?.length > 0 && bookingDetails?.facilitiesDetails[0]?.Description) ? bookingDetails?.facilitiesDetails[0]?.Description : "---"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
            <Footer />
        </div>
    )
};

export default Booking;
