import React, { useState, useEffect } from "react";
import callApi from "../service/callApi";
import serverApi from "../api.json";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { toast } from "react-toastify";
import validator from "validator";

const Step2 = ({ setStep, mobile }) => {
  let [otp1, setOtp1] = useState("");
  let [otp2, setOtp2] = useState("");
  let [otp3, setOtp3] = useState("");
  let [otp4, setOtp4] = useState("");
  const [areaOfModal, setAreaModal] = useState(true);
  const [optError, setOtpError] = useState(false);
  const [errorOtp, setErrorOtp] = useState("");
  const [isModalClosed, setCloseModalStatus] = useState("");
  const [targetValue, setTargetValue] = useState("");
  const [test, setTest] = useState(false);

  const [counter, setCounter] = useState(60);

  // useEffect(() => {
  //   const timer =
  //     counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  //   return () => clearInterval(timer);
  // }, [counter]);

  useEffect(() => {
    setCounter(60)
    return ()=>{
      setCounter(0)
      setOtp1("");
      setOtp2("");
      setOtp3("");
      setOtp4("");
      // console.log("Timer Stopped")
    }
  }, []);

  const otpVerification = (e, index) => {
    // console.log(e, index);
    if(validator.isNumeric(e+"")){
    if (index == 1) {
      setOtp1(e);
    } else if (index == 2) {
      setOtp2(e);
    } else if (index == 3) {
      setOtp3(e);
    } else {
      setOtp4(e);
    }
   }else{
    if (index == 1) {
      setOtp1("");
    } else if (index == 2) {
      setOtp2("");
    } else if (index == 3) {
      setOtp3("");
    } else {
      setOtp4("");
    }
   }
  }

  const inputfocus = (num,elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace" || elmnt.key === "ArrowLeft") {
      if (elmnt.target.previousSibling) {
        elmnt.target.previousSibling.focus();
      }
    }
    else if (elmnt.key === "ArrowLeft") {
      if (elmnt.target.nextSibling) {
        elmnt.target.nextSibling.focus();
      }
    } else {
      if (elmnt.target.nextSibling) {
        elmnt.target.nextSibling.focus();
      }
    }
  }

  const verifyOtp = async () => {
    // console.log(otp1, otp2, otp3, otp4);
    let finalOtp = otp1 + otp2 + otp3 + otp4;
    if(!validator.isEmpty(otp1+"") &&  !validator.isEmpty(otp2+"") && !validator.isEmpty(otp3+"") && !validator.isEmpty(otp4+"")){
    let obj = {
      otp: otp1 + otp2 + otp3 + otp4,
      type: "Forgot",
      phone: mobile,
    };
    let verifyOtp;
    if (obj.otp) {
      verifyOtp = await callApi.post(serverApi.verifyOtp, obj);
      if (verifyOtp.data.message == "OTP successfully verified") {
        toast.success(verifyOtp.data.message);
        setStep(3);
      } else if (verifyOtp.data.message == "Invalid OTP") {
        toast.error(verifyOtp.data.message);
      } else if (verifyOtp.data.message == "No Email") {
        toast.error("No customer with this phone no.");
      } else if (verifyOtp.data.message == "OTP is incorrect") {
        toast.error(verifyOtp.data.message);
      } else if (verifyOtp.data.message == "jwt expired") {
        toast.error("OTP is expired, Please try to resend otp!");
        setCounter(0)
      } else {
        toast.error("Some issue with OTP.");
      }
    } else {
      toast.error("Please Enter OTP");
    }
   }else{
    toast.error("OTP is required")
   }
  };

  const resendOtp = async () => {
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    setErrorOtp("");
    setOtpError(false);
    setCounter(0);
    try{
    await callApi.post(serverApi.sendOtp, {
      phone: mobile,
      type: "Forgot",
    });
    setCounter(60);
   }catch(e){
     console.log(e)
     if(e.response.data.error){
      toast.error(e.response.data.error)
     }else{
      toast.error("Error while processing your request!")
     }
   }
  };

  const backToSendOtp = () => {
    setStep(1);
  };

  return (
    <React.Fragment>
      <div
        class="material-icons-outlined text-danger  float-left"
        onClick={backToSendOtp}
      >
        arrow_back
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div className="card login-card py-5 px-3">
          <h5 className="m-0 text-center modal-heading">
            Enter Your Verification code
          </h5>
          <span className="mobile-text text-center pt-4">
            We sent it on your mobile phone
            <p className="text-dark pt-4 text-center">
              <strong>{mobile}</strong>
              <span style={{ float: 'right' }}>
                {/* timer */}
                {
                  counter != 0 && (
                    <CountdownCircleTimer
                      onComplete={() => setCounter(0)}
                      size={40}
                      key={counter}
                      strokeWidth={4}
                      isPlaying={counter !== 0}
                      duration={60}
                      colors={[
                        ['#004777', 0.33],
                        ['#F7B801', 0.33],
                        ['#A30000', 0.33],
                      ]}
                    >
                      {({ remainingTime }) => {
                        return remainingTime
                      }}
                    </CountdownCircleTimer>
                  )
                }
                {/* timerEnd */}
              </span>
            </p>
          </span>

          <div className="">
            <div className="d-flex flex-row pt-4 text-center otp-input">
              <input
                type="text"
                className="form-control text-center "
                placeholder="0"
                autoFocus=""
                maxLength="1"
                value={otp1}
                onKeyUp={e => inputfocus("1",e)}
                onChange={(e) => otpVerification(e.target.value, 1)}
              />
              <input
                type="text"
                className="form-control"
                placeholder="0"
                maxLength="1"
                value={otp2}
                onKeyUp={e => inputfocus("2",e)}
                onChange={(e) => otpVerification(e.target.value, 2)}
              />
              <input
                type="text"
                className="form-control"
                placeholder="0"
                maxLength="1"
                value={otp3}
                onKeyUp={e => inputfocus("3",e)}
                onChange={(e) => otpVerification(e.target.value, 3)}
              />
              <input
                type="text"
                className="form-control"
                placeholder="0"
                maxLength="1"
                value={otp4}
                onKeyUp={e => inputfocus("4",e)}
                onChange={(e) => otpVerification(e.target.value, 4)}
              />
            </div>
          </div>


          <div className="btn-wrap col-auto pt-4">
            <button
              className="red-btn-lg hvr-icon-push contact-button profile-btn"
              data-toggle="modal"
              data-target={""}
              class="red-btn-lg hvr-icon-push contact-button profile-btn"
              data-dismiss={""}
              aria-label="Close"
              disabled={(!otp1 || !otp2 || !otp3 || !otp4)}
              onClick={() => verifyOtp()}
            >
              Verify and proceed
            </button>
          </div>
          <div className="text-center pt-4 resend-code">
            <span className="d-block mobile-text">
              Didn't receive the code?
            </span>
            <button type="submit" className="#" disabled={counter>0} onClick={() => resendOtp()}>
              Resend code
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Step2;
