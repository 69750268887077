/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect, useState} from 'react';
import Footer from '../Layouts/Footer';
import Header from '../Layouts/Header';
import CardItem from './CardItem';
import AddCard from './AddCard'
import {toast} from 'react-toastify'
import serverApi from "../../api.json";
import callApi from "../../service/callApi";
import Loader from '../Layouts/Loader'

const MyCard = () => {
    const [selected,setSelected] = useState(null)
    const [isAddCard,setAddnewCard] = useState(false)
    const [isLoading,setLoader] = useState(false)
    const [refresh,setRefresh] = useState(false)
    const [cards,setMyCard] = useState([])

    useEffect(async()=>{
        setLoader(true)
        await callApi.get(serverApi?.getMyCards)
        .then((res)=>{
            setLoader(false)
            if(res && res?.data && res?.data?.cardsList?.length>0){
                 setMyCard(res?.data?.cardsList)
            }else{
                setMyCard([])
            }
        }).catch((e)=>{
            setLoader(false)
            if(e?.response && e?.response?.data?.error){
                toast.error(e?.response?.data?.error)
            }else{
                toast.error("Error! while processing your request")
            }
        })
    },[refresh])

const deleteCard = async()=>{
    if(selected){
        setLoader(true)
        await callApi.delete(`${serverApi?.removemycard}/${selected}`)
        .then((res)=>{
            setLoader(false)
            setRefresh(!refresh)
            if(res && res?.data && res?.data?.message?.length>0){
                toast.success(res?.data?.message)
            }
        }).catch((e)=>{
            setLoader(false)
            if(e?.response && e?.response?.data?.error){
                toast.error(e?.response?.data?.error)
            }else{
                toast.error("Error! while processing your request")
            }
        })
    }else{
        toast.error("Select a card")
    }
}




  return (
    <div>
        <Header/>
        <section className="hero-banner hero-banner-height" style={{backgroundColor:'black'}}>
        <div className="container">
            <div className="row">
                <div className="hero-banner-aboutus hero-banner-content">
                    <h2 className="heading-white">
                         My Card

                    </h2>
                    <p className="desc-white pt-4 text-center">
                        Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis fermentum justo,
                        sit amet convallis arcu
                        interdum vel. Suspendisse vulputate posuere risus, quis consectetur neque scelerisque.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section className="sports-list-wrap bg-grey ptb-90">
        <div className="container">
            <div className="card-div">
                <div className="row">
                    <div className="top-section">
                        <h2 className="heading-grey prof-heading text-left">
                            Card Details
                        </h2>
                        <p className="desc-grey  text-left pt-4">
                            Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis fermentum
                            justo, sit amet convallis arcu<br />
                            interdum vel. Suspendisse vulputate posuere risus, quis consectetur neque scelerisque.
                        </p>
                    </div>
                   {
                       isLoading?<div className="col-md-4 pt-4"><Loader/></div>:cards.map((card,key)=><CardItem  key={key} setSelected={setSelected} selected={selected} card={card} />)
                   }
                    <div className="col-md-4 pt-4">
                        <div className="card-one add-card" style={{cursor:'pointer'}} onClick={()=>setAddnewCard(!isAddCard)}>
                            <div className="add-card-border">
                                    <a className="text-center">
                                    <span className="material-icons-outlined">
                                        {!isAddCard?'add':'remove'}
                                        </span>
                                        <p className="desc-grey-about">
                                            {isAddCard?'Cancel new card':'Add New Card'}
                                        </p></a>
                            </div>
                            

                        </div>
                    </div>

                </div>
                {selected?(
                    <div className="button justify-content-center d-flex pt-4">
                    {/* <button type="submit" className="btn btn-light save-btn common-btn-card">Save</button> */}
                <button type="submit" className="btn  btn-danger  common-btn-card ml-4" onClick={()=>deleteCard()}>Delete</button>
            </div>
                ):null}
                
               {isAddCard?<AddCard doneCb={()=>{setRefresh(!refresh);setAddnewCard(!isAddCard)}}/>:null}
          
            </div>
        </div>
    </section>
        <Footer/>
    </div>
  )
}

export default MyCard;
