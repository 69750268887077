import React, { useEffect, useState } from 'react'
import Footer from '../Layouts/Footer'
import Header from '../Layouts/Header'
import { useNavigate } from 'react-router-dom'
import EminitiesItem from './EminitiesItem'
import { PaymentInputsContainer, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { toast } from 'react-toastify'
import IntakeQues from './IntakeQues'
import { getCheckoutInfo, getUserInfo, isAuth } from '../../helpers'
import CardModal from '../Modal/CardModal'
import api from '../../api.json'
import callApi from "../../service/callApi";
import { CUSTOMER_LINK } from '../../config'

const Checkout = () => {

    const [booking_checkout, setCheckoutInfo] = useState({})
    const navigate = useNavigate()
    const { meta, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps } = usePaymentInputs();

    const [couponCode, setCouponCode] = useState('')
    const [loading, setLoading] = useState(false)
    const [discountRate, setDiscountRate] = useState(0)
    const [discountPrice, setDiscountPrice] = useState(0)
    const [finalPrice, setFinalPrice] = useState(0)

    const [paymentDetails, setDetails] = useState({
        card_no: getCheckoutInfo()?.paymentDetails?.card_no ? getCheckoutInfo()?.paymentDetails?.card_no : "",
        card_holder_name: getCheckoutInfo()?.paymentDetails?.card_holder_name ? getCheckoutInfo()?.paymentDetails?.card_holder_name : "",
        expiry_date: getCheckoutInfo()?.paymentDetails?.expiry_date ? getCheckoutInfo()?.paymentDetails?.expiry_date : "",
        cvv: getCheckoutInfo()?.paymentDetails?.cvv ? getCheckoutInfo()?.paymentDetails?.cvv : "",
        cardType: getCheckoutInfo()?.paymentDetails?.cardType ? getCheckoutInfo()?.paymentDetails?.cardType : "",
        payment_mode: getCheckoutInfo()?.paymentDetails?.payment_mode ? getCheckoutInfo()?.paymentDetails?.payment_mode : "card",
        save_card: getCheckoutInfo()?.paymentDetails?.save_card ? getCheckoutInfo()?.paymentDetails?.save_card : false,
    })

    const [cardModal, setCardModal] = useState(true)

    const [billingAddress, setBillingAddress] = useState({
        street: getCheckoutInfo()?.billingAddress?.street ? getCheckoutInfo()?.billingAddress?.street : "",
        appartment: getCheckoutInfo()?.billingAddress?.appartment ? getCheckoutInfo()?.billingAddress?.appartment : "",
        country: getCheckoutInfo()?.billingAddress?.country ? getCheckoutInfo()?.billingAddress?.country : "",
        state: getCheckoutInfo()?.billingAddress?.state ? getCheckoutInfo()?.billingAddress?.state : "",
        city: getCheckoutInfo()?.billingAddress?.city ? getCheckoutInfo()?.billingAddress?.city : "",
        zip_code: getCheckoutInfo()?.billingAddress?.zip_code ? getCheckoutInfo()?.billingAddress?.zip_code : "",
        new_same: getCheckoutInfo()?.billingAddress?.new_same ? getCheckoutInfo()?.billingAddress?.new_same : "same"
    })

    const [IntakeQuestion, setIntakeQuestion] = useState([
        { "Question": "Test", "Answer": "" },
        { "Question": "Test", "Answer": "" }
    ])

    // useEffect(()=>{
    //     if(getCheckoutInfo()?.IntakeQuestion?.length!=0){
    //         setIntakeQuestion(getCheckoutInfo()?.IntakeQuestion)
    //     }
    // },[getCheckoutInfo()?.IntakeQuestion])


    useEffect(() => {
        if (localStorage?.getItem("booking_checkout")) {
            // console.log("localStorage?.getItem(booking_checkout)", localStorage?.getItem("booking_checkout"))
            let data = JSON.parse(localStorage?.getItem("booking_checkout"))
            setCheckoutInfo(data)
            setDiscountPrice(data?.discountPrice ? data?.discountPrice : 0)
            setDiscountRate(data?.discountRate ? data?.discountRate : 0)
            setCouponCode(data?.couponCode ? data?.couponCode : "")
            setFinalPrice(data?.Price)
            const IntakeQues = data?.bookingDetails?.IntakeQuestion
            if (IntakeQues?.length > 0) {
                const qa = IntakeQues?.map((q) => ({ Question: q?.Question, Answer: "" }))
                setIntakeQuestion(qa)
            }
            if (getUserInfo()?.customerDetails?.billingAddress) {
                setBillingAddress(getUserInfo()?.customerDetails?.billingAddress)
            }
        } else {
            navigate("/search_listing")
        }
    }, [localStorage?.getItem("booking_checkout")])

    useEffect(() => {
        if (billingAddress?.new_same == 'new') {
            setBillingAddress({
                street: '', appartment: '', country: '', state: '', city: '', zip_code: '', new_same: 'new'
            })
        } else if (getUserInfo()?.customerDetails?.billingAddress) {
            setBillingAddress(getUserInfo()?.customerDetails?.billingAddress)
        }
    }, [billingAddress?.new_same])


    const handleClearCheckout = () => {
        if (localStorage?.getItem("booking_checkout")?.length != 0) {
            localStorage.removeItem("booking_checkout")
        }
        toast.success("booking is removed!")
        navigate("/search_listing")
    }

    const validate = () => {

        if (paymentDetails?.payment_mode == 'card') {
            for (let key in paymentDetails) {
                if ((paymentDetails[key] + "")?.trim() == "" && key != 'save_card' && key != 'cardType') {
                    // console.log(key)
                    return false
                }
            }
        }

        for (let key in billingAddress) {
            if ((billingAddress[key] + "")?.trim() == "" && key !== 'new_same') {
                // console.log(key)
                return false
            }
        }

        for (let key of IntakeQuestion) {
            if (!key?.Answer || (key?.Answer + "")?.trim() == "") {
                //    console.log(key)
                return false
            }
        }
        return true
    }

    const handlePay = () => {


        if (validate()) {
            paymentDetails.cardType = meta?.cardType?.displayName

            let local = localStorage.getItem("booking_checkout")
            let userData = getUserInfo()
            if (localStorage.getItem("booking_checkout") && JSON.parse(local)) {
                let details = JSON.parse(local)
                details.paymentDetails = paymentDetails
                details.billingAddress = billingAddress
                details.IntakeQuestion = IntakeQuestion
                if (userData && billingAddress?.new_same == 'same') {
                    userData.customerDetails.billingAddress = billingAddress
                    localStorage.setItem("userData", JSON.stringify(userData))
                }
                if (finalPrice > 0) {
                    details.PurchaseCost = parseFloat(details.Price)?.toFixed(2)
                    details.Price = parseFloat(finalPrice).toFixed(2)
                    details.discountPrice = parseFloat(discountPrice)?.toFixed(2)
                    details.discountRate = parseInt(discountRate)
                    details.couponCode = couponCode
                } else {
                    details.PurchaseCost = parseFloat(details.Price)?.toFixed(2)
                    details.Price = parseFloat(finalPrice)?.toFixed(2)
                    details.discountPrice = 0
                    details.discountRate = 0
                }
                localStorage.setItem("booking_checkout", JSON.stringify(details))
                if (isAuth()) {
                    navigate("/order_confirmation")
                } else {
                    // navigate("/login")
                    window.location = `${CUSTOMER_LINK}/login`
                }

            }
        } else {
            toast.error("Please fulfill all the fields!")
        }
        // meta?.cardType?.displayName
        // toast.success("Payment successfuly done!")
        // setTimeout(()=>handleClearCheckout(),2000)
    }

    const handleAnswerHandle = (answer, index) => {
        let arr = [...IntakeQuestion]
        arr[index].Answer = answer
        setIntakeQuestion(arr)
    }

    const onCardSelect = (card) => {
        // console.log("card",card)
        if (card) {
            setDetails((s) => ({
                ...s,
                cardType: card?.cardType,
                card_holder_name: card?.card_holder_name,
                card_no: card?.card_no,
                cvv: card?.cvv,
                expiry_date: card?.expiry_date,
                payment_mode: 'card',
                save_card: false
            }))
            setCardModal(false)
        }
    }

    const applyCode = async () => {
        if (couponCode && couponCode?.length > 0) {
            try {
                setLoading(true)
                const codeData = await callApi.get(`${api.getCouponInfo}/${couponCode}`)
                if (codeData?.data?.data && codeData?.data?.data?.length > 0) {
                    if (codeData?.data?.data[0]?.ValidUntil) {
                        const expiredDate = new Date(codeData?.data?.data[0]?.ValidUntil)
                        if (expiredDate < new Date) {
                            toast.warn(`${couponCode} coupon code is expired!`)
                        } else {
                            const discount = codeData?.data?.data[0]?.Discount
                            const price = booking_checkout?.Price ? parseFloat(booking_checkout?.Price)?.toFixed(2) : 0
                            const finalPrice = (price * discount) / 100
                            toast.success(`${discount}% discount is applied!`)
                            setDiscountRate(discount)
                            setDiscountPrice(finalPrice)
                            setFinalPrice((price - finalPrice?.toFixed(2))?.toFixed(2))
                        }
                    }
                } else {
                    setFinalPrice(booking_checkout?.Price)
                    setDiscountRate(0)
                    setDiscountPrice(0)
                }
                setLoading(false)
            } catch (e) {
                setLoading(false)
                console.log("Error", e)
            }
        } else {
            setFinalPrice(booking_checkout?.Price)
            setDiscountRate(0)
            setDiscountPrice(0)
        }
    }

    return (
        <div>
            <Header />
            <section className="hero-banner hero-banner-height" style={{ backgroundColor: 'black' }}>
                <div className="container">
                    <div className="row">
                        <div className=" hero-banner-content">
                            <h2 className="heading-white">
                                Checkout</h2>
                            <p className="desc-white pt-4 text-center">
                                Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis fermentum justo,
                                sit amet convallis arcu
                                interdum vel. Suspendisse vulputate posuere risus, quis consectetur neque scelerisque.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-grey ptb-90">
                <div className="container">
                    <div className="profile-pic-col">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <div className="checkout-screen d-flex">
                                    <div className="checkout-img pt-3">
                                        <figure>
                                            <img style={{ width: 280, height: 210 }} src={(booking_checkout?.bookingDetails && booking_checkout?.bookingDetails?.Images?.length > 0) ? `${api.base_url}/${booking_checkout?.bookingDetails?.Images[0]}` : 'assets/images/order-image.png'} alt="logo" className="img-fluid" />
                                        </figure>
                                    </div>
                                    <div className="sports-info" style={{ width: 400 }}>
                                        <div className="close-img">
                                            <button type="button" onClick={handleClearCheckout} className="close-icon close" data-dismiss="modal"
                                                aria-label="Close">
                                                <span className="material-icons-outlined">
                                                    close
                                                </span>
                                            </button>
                                        </div>
                                        <h3 className="pt-3 text-left">{booking_checkout?.bookingDetails && booking_checkout?.bookingDetails?.facilitiesDetails?.length > 0 ? booking_checkout?.bookingDetails?.facilitiesDetails[0]?.Name : ""}</h3>
                                        <p className="sports-location text-left">{booking_checkout?.bookingDetails && booking_checkout?.bookingDetails?.facilitiesDetails?.length > 0 ? booking_checkout?.bookingDetails?.facilitiesDetails[0]?.Address : ""}</p>
                                        {booking_checkout?.bookingDetails?.Court?.Surface ? (
                                            <>
                                                <h2 className="order-grey  sports-location text-left m-0">Surface
                                                </h2>
                                                <p className="order-p sports-location text-left">{booking_checkout?.bookingDetails?.Court?.Surface}</p>
                                            </>
                                        ) : null}

                                        <div className="sports-eminities">
                                            <div className='justify-content-start pa-left-40 pl-5 pt-0 pb-0'>
                                            <h2 className="order-grey text-left m-0 pb-1">Amenities</h2>
                                                <div className="row">
                                                    {(booking_checkout?.bookingDetails?.amenities && booking_checkout?.bookingDetails?.amenities?.length > 0) ? booking_checkout?.bookingDetails?.amenities?.map((item, key) => <EminitiesItem item={item} key={key} />) : null}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="pt-0 checkout-booking">
                                        <div className=" booking-content-top bg-blue">
                                            {
                                                booking_checkout?.bookingDetails?.type == 'service' ? (
                                                    <>
                                                        <h2 className="booking-price-heading">Total Hours</h2><p className="price"><b>{booking_checkout?.total_hours}</b></p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <h2 className="booking-price-heading">Quantity</h2><p className="price"> <b>{booking_checkout?.quantity}</b> </p>
                                                    </>
                                                )
                                            }
                                        </div>
                                        <div className="booking-content-bottom bg-red">
                                            <h2 className="booking-price-heading">
                                                Booking
                                                <span>PRICE</span>
                                            </h2>
                                            <p className="price">QR<b>{booking_checkout?.Price}</b></p>
                                        </div>
                                    </div>
                                </div>
                                <form className="subscribe-form contact-details mt-4 checkout-screen">
                                    <div className="checkout-form">
                                        <h3 className="text-left m-0">
                                            Payment Options
                                        </h3>
                                        <p className="text-left pt-2">
                                            Please choose your payment method and payment type
                                        </p>
                                        <div className="row pt-5 pb-5">
                                            <div className="col-md-6">
                                                <div className="opt-div d-flex">
                                                    <input type="radio" name="toggler" value="cash" checked={paymentDetails?.payment_mode === "cash"} id="radio_Email_yes" onClick={() => { setDetails((d) => ({ ...d, payment_mode: 'cash', save_card: false })); setCardModal(false) }} /><span
                                                        className="desc-grey-about" >Cash Payment</span>
                                                </div>
                                                {/* <p> aliquet libero, id pellentesque felis luctus non</p> */}
                                            </div>
                                            <div className="col-md-6">
                                                <div className="opt-div d-flex">
                                                    <input type="radio" name="toggler" value="card" id="radio_Email_no" checked={paymentDetails?.payment_mode === "card"} onClick={() => { setDetails((d) => ({ ...d, payment_mode: 'card' })); setCardModal(true) }} /><span
                                                        className="desc-grey-about">Card Payment</span>
                                                </div>
                                                {/* <p> aliquet libero, id pellentesque felis luctus non</p> */}
                                            </div>
                                        </div>
                                        {
                                            paymentDetails?.payment_mode == "card" ? (
                                                <div className="card-details-div" id="div_email">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    {/* <span className="material-icons-outlined">
                                                        credit_card
                                                    </span> */}
                                                                    <svg {...getCardImageProps({ images })} />
                                                                </div>
                                                                <input type="text" className="form-control pl-value active" {...getCardNumberProps({ onChange: (e) => setDetails((c) => ({ ...c, card_no: e.target.value })) })} value={paymentDetails?.card_no} placeholder="8978 5555 2222 2222" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="material-icons-outlined">
                                                                        face
                                                                    </span>
                                                                </div>
                                                                <input type="text" value={paymentDetails?.card_holder_name} onChange={(e) => setDetails((c) => ({ ...c, card_holder_name: e.target.value }))} className="form-control" placeholder="cardholder name" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="input-group pt-4">
                                                                <div className="input-group-prepend">
                                                                    <span className="material-icons-outlined">
                                                                        credit_card
                                                                    </span>
                                                                </div>
                                                                <input type="text" className="form-control" {...getExpiryDateProps({ onChange: (e) => setDetails((c) => ({ ...c, expiry_date: e.target.value })) })} value={paymentDetails?.expiry_date} placeholder="Exipry Date" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-group pt-4">
                                                                <div className="input-group-prepend">
                                                                    <span className="material-icons-outlined">
                                                                        vpn_key
                                                                    </span>
                                                                </div>
                                                                <input type="text" className="form-control" {...getCVCProps({ onChange: (e) => setDetails((c) => ({ ...c, cvv: e.target.value })) })} value={paymentDetails?.cvv} placeholder="CVV" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex pt-4">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <div className="checkmark pl-4">
                                                                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" onClick={() => setDetails((c) => ({ ...c, save_card: !paymentDetails?.save_card }))} />
                                                                </div>
                                                                <label for="vehicle1" className="check-para text-danger">Save this card </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null
                                        }

                                    </div>
                                </form>
                                <form className="subscribe-form contact-details mt-4 checkout-screen">
                                    <div className="checkout-form">
                                        <h3 className="text-left m-0">
                                            Billing Address
                                        </h3>
                                        {/* <p className="text-left pt-2">
                                            Please choose your payment method and payment type
                                        </p> */}
                                        <div className="row pt-5 pb-5">
                                            <div className="col-md-6">
                                                <div className="checkout-address">
                                                    <div className="opt-div d-flex">
                                                        <input type="radio" name="gender" value="same" checked={billingAddress?.new_same === "same"} onClick={() => setBillingAddress((b) => ({ ...b, new_same: 'same' }))} /><span
                                                            className="desc-grey-about">Same as permanent</span>
                                                    </div>
                                                    {/* <p> aliquet libero, id pellentesque felis luctus non</p> */}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="checkout-address">
                                                    <div className="opt-div d-flex">
                                                        <input type="radio" name="gender" value="new" checked={billingAddress?.new_same === "new"} onClick={() => setBillingAddress((b) => ({ ...b, new_same: 'new' }))} /><span
                                                            className="desc-grey-about">New Address</span>
                                                    </div>
                                                    {/* <p> aliquet libero, id pellentesque felis luctus non</p> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="material-icons-outlined">
                                                            apartment
                                                        </span>
                                                    </div>
                                                    <input type="text" maxLength={200} onChange={(e) => setBillingAddress((b) => ({ ...b, street: e.target.value }))} value={billingAddress?.street} className="form-control" placeholder="Street Address" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-group pt-4">
                                                    <div className="input-group-prepend">
                                                        <span className="material-icons-outlined">
                                                            meeting_room
                                                        </span>
                                                    </div>
                                                    <input type="text" maxLength={200} className="form-control" onChange={(e) => setBillingAddress((b) => ({ ...b, appartment: e.target.value }))} value={billingAddress?.appartment} placeholder="Apartment" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-group pt-4">
                                                    <div className="input-group-prepend">
                                                        <span className="material-icons-outlined">
                                                            flag
                                                        </span>
                                                    </div>
                                                    <input type="text" className="form-control" maxLength={200} placeholder="Country" value={billingAddress?.country} onChange={(e) => setBillingAddress((b) => ({ ...b, country: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="input-group pt-4">
                                                    <div className="input-group-prepend">
                                                        <span className="material-icons-outlined">
                                                            account_balance
                                                        </span>
                                                    </div>
                                                    <input type="text" className="form-control" maxLength={200} placeholder="State" value={billingAddress?.state} onChange={(e) => setBillingAddress((b) => ({ ...b, state: e.target.value }))} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="input-group pt-4">
                                                    <div className="input-group-prepend">
                                                        <span className="material-icons-outlined">
                                                            location_city
                                                        </span>
                                                    </div>
                                                    <input type="text" className="form-control" maxLength={200} placeholder="City" onChange={(e) => setBillingAddress((b) => ({ ...b, city: e.target.value }))} value={billingAddress?.city} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="input-group pt-4">
                                                    <div className="input-group-prepend">
                                                        <span className="material-icons-outlined">
                                                            badge
                                                        </span>
                                                    </div>
                                                    <input type="text" className="form-control" maxLength={200} placeholder="Zip Code" value={billingAddress?.zip_code} onChange={(e) => setBillingAddress((b) => ({ ...b, zip_code: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <form className="subscribe-form contact-details mt-4 checkout-screen">
                                    <div className="checkout-form">
                                        <h3 className="text-left m-0">
                                            General Enquiry
                                        </h3>
                                        {/* <p className="text-left pt-2">
                                            Please choose your payment method and payment type
                                        </p> */}
                                        <div className="row">
                                            {
                                                IntakeQuestion?.length > 0 && IntakeQuestion?.map((el, index) => <IntakeQues key={index} answer={(e) => handleAnswerHandle(e, index)} ques={el?.Question} num={index + 1} />)
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="checkout-screen order-screen pt-4">
                                    <div className="sports-img">
                                        <h3 className="text-left m-0">
                                            Order Summary
                                        </h3>
                                        <p className="text-left pt-2">
                                            Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis
                                            fermentum
                                            justo, sit amet convallis arcu interdum vel. Suspendisse vulputate posuere risus,
                                            quis
                                            consectetur neque scelerisque.
                                        </p>
                                        <div className="order-summary-data">
                                            <div className="row mt-4">

                                                <div className="col-md-8">
                                                    <p><b>Transaction Code</b></p>
                                                </div>
                                                <div className="col-md-4">
                                                    <p className="text-grey"><b>0111111</b></p>
                                                </div>
                                                <div className="col-md-8">
                                                    <form className="subscribe-form contact-details">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="material-icons-outlined">
                                                                    credit_card
                                                                </span>
                                                            </div>
                                                            <input type="text" className="form-control" value={couponCode} disabled={loading} onChange={(e) => setCouponCode(e.target.value)} placeholder="Coupon Code" />
                                                            <div className="input-group-append">
                                                                <span className="material-icons-outlined text-danger question-icon">
                                                                    help_outline
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="col-md-4">
                                                    <button type="button"
                                                        className="blue-btn-lg hvr-icon-push checkout-btn search-list-btn" disabled={loading} onClick={() => applyCode()}>Apply</button>
                                                </div>
                                                <div className="col-md-8">
                                                    <p className="purchase-cost"><b>Purchase Cost</b></p>
                                                </div>
                                                <div className="col-md-4">
                                                    <p><b><span className="pr-1">QR</span>{booking_checkout?.Price}</b></p>
                                                </div>
                                                <div className="col-md-8">
                                                    <p><b>Transaction Changes</b></p>
                                                </div>
                                                <div className="col-md-4">
                                                    <p><b><span className="pr-1">QR</span> 00.0</b></p>
                                                </div>
                                                <div className="col-md-8">
                                                    <p><b>Commision</b></p>
                                                </div>
                                                <div className="col-md-4">
                                                    <p><b><span className="pr-1">QR</span> 00.0</b></p>
                                                </div>
                                                <div className="col-md-8">
                                                    <p><b>Discount Applied (%)</b></p>
                                                </div>
                                                <div className="col-md-4">
                                                    <p><b><span className="pr-1"></span> {discountRate}%</b></p>
                                                </div>
                                                <div className="col-md-8">
                                                    <p><b>Discount (Amount)</b></p>
                                                </div>
                                                <div className="col-md-4">
                                                    <p><b><span className="pr-1">QR</span> {discountPrice ? parseFloat(discountPrice).toFixed(2) : parseFloat(0).toFixed(2)}</b></p>
                                                </div>
                                            </div>



                                        </div>
                                        <hr />
                                        <div className="row">

                                            <div className="col-md-8">
                                                <div className="discount-cost">
                                                    <p>Total Cost</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="discount-cost">
                                                    <p><span className="pr-3">QR </span> {finalPrice}</p>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="btn-wrap pt-4 pb-5">
                                                <button className="red-btn-lg hvr-icon-push pay-btn common-btn" disabled={loading} onClick={handlePay}>Pay Now !</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </section>
            <CardModal isModal={cardModal} handleToggle={() => setCardModal(false)} onCardSelect={onCardSelect} />
            <Footer />
        </div>
    )
}

export default Checkout