import "./App.css";
import SignUp from "./components/singup";
import Login from "./components/login";
import Home from "./components/home";
import ForgotPassword from "./components/forgot_pass_modal";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import Tnc from "./components/StaticCompnents/Tnc";
import PrivacyPolicy from "./components/StaticCompnents/PrivacyPolicy";
import AboutUs from "./components/StaticCompnents/AboutUs";
import ContactUs from "./components/StaticCompnents/ContactUs";
import Profile from './components/Profile'
import MyBooking from "./components/MyBooking"
import SearchListing from './components/SearchListing'
import CategoryListing from './components/SearchListing'
import history from "./history";
import FavoriteSports from "./components/FavoriteSports";
import MyCard from "./components/MyCard";
import Booking from "./components/Booking";
import Checkout from './components/Checkout'
import OrderConfirmation from './components/OrderConfirmation'
import { useEffect } from "react";
import api from './api.json'
import callApi from "./service/callApi";
import axios from "axios";
import PrivateComp from './components/PrivateComp'

function App() {

  useEffect(async()=>{
   try{
    const res = await axios.get('https://geolocation-db.com/json/')
    const IP4 = res?.data?.IPv4
    if(IP4 && res?.data){
     const res2 = await callApi.post(api.visitsOnWeek_IP,{IPAddress:IP4})
     console.log('res2',res2)
    }
   }catch(e){
     console.log('Error in visit api',e)
   }
  },[])
  
  return (
    <div className="App">
    <ToastContainer position="top-center" />
      <BrowserRouter history={history}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route  path="/signUp" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/tnc" element={<Tnc />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/my_profile"  element={<PrivateComp><Profile /></PrivateComp>} />
          <Route path="/my_booking"  element={<PrivateComp><MyBooking /></PrivateComp>} />
          <Route path="/search_listing"  element={<SearchListing title={"search"} />} />
          <Route path="/category_listing"  element={<CategoryListing title={"category"} />} />
          <Route path="/favorite_sports"  element={<PrivateComp><FavoriteSports /></PrivateComp>} />
          <Route path="/my_card"  element={<PrivateComp><MyCard /></PrivateComp>} />
          <Route path="/service_class_details/:_id"  element={<Booking />} />
          <Route path="/checkout"  element={<PrivateComp><Checkout /></PrivateComp>} />
          <Route path="/order_confirmation"  element={<PrivateComp><OrderConfirmation /></PrivateComp>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App
