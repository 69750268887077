import React from 'react'
import Header from '../Layouts/Header'
import Footer from '../Layouts/Footer'
//PrivacyPolicy component

const PrivacyPolicy = () => {
    return (
        <div>
            <Header />
            {/* <!-- hero section banner --> */}
            <section className="hero-banner hero-banner-height" style={{ backgroundImage: 'url(/assets/images/about-us.jpg)' }}>
                <div className="container">
                    <div className="row">
                        <div className="hero-banner-aboutus hero-banner-content">
                            <h2 className="heading-white ">
                                PRIVACY POLICY
                            </h2>
                            <p className="desc-white pt-4 text-center">
                                Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- body part --> */}
            <section className="sports-list-wrap bg-grey ptb-90 about-us">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="heading-grey">
                                Introduction</h2>
                            <h6 className="terms-heading pt-4">Welcome to the My Local Pitch Limited, trading as "Playfit", privacy
                                notice.</h6>
                            <p className="desc-grey">
                                Last updated 21st July 2021
                            </p>
                        </div>
                        <div className="col-md-12 plr-70">
                            <div className="pt-5">
                                <h4 className="heading-grey-term text-left pt-4">
                                    Playfit respects your privacy and is committed to protecting your personal data.</h4>
                                <p className="pt-4 desc-grey-about text-justify">
                                    his privacy notice will inform you as to how we look after your personal data when you visit our
                                    website (Site) (regardless of where you visit it from) and/or download or stream any of our
                                    applications for mobile, tablet and other smart devices and application interfaces (Application)
                                    and tell you about your privacy rights and how the law protects you.
                                </p>


                                <p className="pt-4 desc-grey-about text-justify">This privacy notice is provided in a layered format so
                                    you can click through to the specific areas set out below. Please also use the Glossary to
                                    understand the meaning of some of the terms used in this privacy notice.</p>
                            </div>

                            <ol className="column-break pt-5">
                                <li className="policy-list-bullet d-flex">
                                    <p className=" desc-grey-about text-left pl-2">
                                        Important Infornmation & Who We Are</p>
                                </li>
                                <li className="policy-list-bullet d-flex">
                                    <p className=" desc-grey-about text-left pl-2">
                                        The Data We Collect About You</p>
                                </li>
                                <li className="policy-list-bullet d-flex">
                                    <p className=" desc-grey-about text-left pl-2">
                                        How Is Your Personal Data Collected </p>
                                </li>

                                <li className="policy-list-bullet d-flex">
                                    <p className=" desc-grey-about text-left pl-2">
                                        How We Use Your Personal Data</p>
                                </li>
                                <li className="policy-list-bullet d-flex">
                                    <p className=" desc-grey-about text-left pl-2">
                                        Disclosures Of Your Personal Data</p>
                                </li>
                                <li className="policy-list-bullet d-flex">
                                    <p className=" desc-grey-about text-left pl-2">
                                        International Transfers</p>
                                </li>
                                <li className="policy-list-bullet d-flex">
                                    <p className=" desc-grey-about text-left pl-2">
                                        Data Security</p>
                                </li>
                                <li className="policy-list-bullet d-flex">
                                    <p className=" desc-grey-about text-left pl-2">
                                        Data Retention
                                    </p>
                                </li>
                                <li className="policy-list-bullet d-flex">
                                    <p className=" desc-grey-about text-left pl-2">Your Legal Rights</p>
                                </li>

                                <li className="policy-list-bullet d-flex">
                                    <p className=" desc-grey-about text-left pl-2"> Glossary</p>
                                </li>
                            </ol>

                        </div>
                        <div className="col-md-12 plr-70">
                            <div className="pt-5">
                                <ul className="p-0">
                                    <li>
                                        <h4 className="heading-grey-term text-left">
                                            1. Important Information & Who We Are</h4>
                                        <ol className="pa-left-4 pt-4">
                                            <li>
                                                <h6 className="heading-grey-term policy-heading text-left">Purpose of this privacy
                                                    notice
                                                </h6>

                                                <p className=" desc-grey-about text-justify  pt-4">
                                                    This privacy notice aims to give you information on how Playfinder collects and
                                                    processes your personal data through your use of our Site or Application,
                                                    including any data you may provide through our Site or Application when you
                                                    purchase a service or sign up to our newsletter.
                                                </p>
                                                <p className=" desc-grey-about text-justify pt-4 ">
                                                    Please read these Terms carefully before you start to Use the Site and/or
                                                    Application, as they apply to your Use of this Site or Application and We
                                                    recommend that
                                                    you print and keep a copy of them for your future reference.
                                                </p>
                                                <p className=" desc-grey-about text-justify pt-4">
                                                    Our Site and Application are not intended for children and we do not knowingly
                                                    collect data relating to children.
                                                </p>
                                            </li>


                                        </ol>
                                        <ol className="pa-left-4 pt-4">
                                            <li>
                                                <h6 className="heading-grey-term  policy-heading text-left">Controller
                                                </h6>

                                                <p className="desc-grey-about text-justify  pt-4">
                                                    Playfit is the controller and responsible for your personal data (collectively
                                                    referred to as <b>"Playfit", "we", "us"</b> or <b>"our"</b> in this privacy notice).
                                                    We have appointed a data privacy manager who is responsible for overseeing
                                                    questions in relation to this privacy notice. If you have any questions about
                                                    this privacy notice, including any requests to exercise your legal rights,
                                                    please contact the data privacy manager using the details set out below.
                                                </p>

                                            </li>


                                        </ol>
                                        <ol className="pa-left-4 pt-4">
                                            <li>
                                                <h6 className="heading-grey-term  policy-heading text-left">Changes to the privacy
                                                    notice and your duty to inform us of changes
                                                </h6>

                                                <p className="desc-grey-about text-justify  pt-4">
                                                    This version was last updated on <b>21st July 2021</b>

                                                </p>

                                                <p className="desc-grey-about text-justify  pt-4">

                                                    It is important that the personal data we hold about you is accurate and
                                                    current. Please keep us informed if your personal data changes during your
                                                    relationship with us..
                                                </p>

                                            </li>


                                        </ol>
                                        <ol className="pa-left-4 pt-4">
                                            <li>
                                                <h6 className="heading-grey-term policy-heading text-left">Third-party links
                                                </h6>

                                                <p className="desc-grey-about text-justify  pt-4">
                                                    Our Site may include links to third-party websites, plug-ins and applications.
                                                    Clicking on those links or enabling those connections may allow third parties to
                                                    collect or share data about you. We do not control these third-party websites
                                                    and are not responsible for their privacy statements. When you leave our Site,
                                                    we encourage you to read the privacy notice of every website you visit.
                                                </p>

                                            </li>

                                        </ol>
                                        <li className="term-list-bullet num-list pt-5">
                                            <h4 className="heading-grey-term text-left">
                                                2. The Data We Collect About You</h4>
                                            <ol className="p-0">

                                                <li className="d-flex pt-4">
                                                    <p className="desc-grey-about text-justify pa-left-4">
                                                        Personal data, or personal information, means any information about an
                                                        individual from which that person can be identified. It does not include data
                                                        where the identity has been removed (anonymous data).
                                                    </p>
                                                </li>
                                                <li className="pt-4">
                                                    <p className="desc-grey-about text-justify pa-left-4">
                                                        We may collect, use, store and transfer different kinds of personal data about
                                                        you which we have grouped together follows:
                                                    </p>

                                                    <ol className="pa-left-4">
                                                        <li className="d-flex pt-4">
                                                            <h6 className="heading-grey-term policy-heading text-left"> Identity Data </h6>
                                                            <p className="desc-grey-about pa-left-40">
                                                                We also collect, use and share Aggregated Data such as statistical or
                                                                demographic data for any purpose. Aggregated Data may be derived from your
                                                                personal data but is not considered personal data in law as this data does
                                                                not directly or indirectly reveal your identity. For example, we may
                                                                aggregate your Usage Data to calculate the percentage of users accessing a
                                                                specific Site and/or Application feature. However, if we combine or connect
                                                                Aggregated Data with your personal data so that it can directly or
                                                                indirectly identify you, we treat the combined data as personal data which
                                                                will be used in accordance with this privacy notice.


                                                            </p>
                                                        </li>
                                                        <li className="d-flex pt-4">
                                                            <h6 className="heading-grey-term policy-heading text-left"> Contact Data </h6>
                                                            <p className="desc-grey-about  pa-left-40">
                                                                Our Site may include links to third-party websites, plug-ins and
                                                                applications. Clicking on those links or enabling those connections may
                                                                allow third parties to collect or share data about you. We do not control
                                                                these third-party websites and are not responsible for their privacy
                                                                statements. When you leave our Site, we encourage you to read the privacy
                                                                notice of every website you visit.


                                                            </p>
                                                        </li>
                                                        <li className="d-flex pt-4">
                                                            <h6 className="heading-grey-term policy-heading text-left"> Profile Data </h6>
                                                            <p className="desc-grey-about pa-left-40">
                                                                Where we need to collect personal data by law, or under the terms of a
                                                                contract we have with you and you fail to provide that data when requested,
                                                                we may not be able to perform the contract we have or are trying to enter
                                                                into with you (for example, to provide you with goods or services). In this
                                                                case, we may have to cancel a product or service you have with us but we
                                                                will notify you if this is the case at the time.

                                                            </p>
                                                        </li>
                                                        <li className="d-flex pt-4">
                                                            <h6 className="heading-grey-term policy-heading text-left"> Financial Data
                                                            </h6>
                                                            <p className="desc-grey-about pa-left-40">
                                                                We do not collect any Special Categories of Personal Data about you (this
                                                                includes details about your race or ethnicity, religious or philosophical
                                                                beliefs, sex life, sexual orientation, political opinions, trade union
                                                                membership, information about your health and genetic and biometric data).
                                                                Nor do we collect any information about criminal convictions and offences.
                                                            </p>
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>

                                        </li>
                                        <li className="policy-list-bullet num-list pt-5">
                                            <h4 className="heading-grey-term text-left">
                                                3. How Is Your Personal Data Collected </h4>
                                            <ol className="p-0">

                                                <li className="d-flex pt-4">
                                                    <p className=" desc-grey-about text-justify pa-left-4">
                                                        Your Use of this Site and Application's published links, messages, material or
                                                        information ("Content") must only be for lawful purposes and must not be in a
                                                        way that infringes Our or anyone else's rights or restricts or inhibits ours or
                                                        anyone else's enjoyment of this Site or Application.
                                                    </p>
                                                </li>
                                                <li className="num-list-3 pt-4">
                                                    <p className=" desc-grey-about text-justify pa-left-4">
                                                        Your Use of this Site or Application is solely for your own personal use. Except
                                                        where the relevant page of this Site and Application and these Site Terms
                                                        expressly permit you to do so, or We consent in writing to you doing so, you may
                                                        not Use this Site or Application:

                                                        <ol>
                                                            <li className="d-flex pt-4">
                                                                <div className="float-left right-icon">
                                                                    <span className="material-icons-outlined">
                                                                        radio_button_checked
                                                                    </span>
                                                                </div>
                                                                <p className="desc-grey-about text-justify pa-left-40">
                                                                    to copy, reproduce, use, re-sell or otherwise deal with any Content on
                                                                    this Site or Application</p>
                                                            </li>
                                                            <li className="d-flex pt-4">
                                                                <div className="float-left right-icon">
                                                                    <span className="material-icons-outlined">
                                                                        radio_button_checked
                                                                    </span>
                                                                </div>
                                                                <p className="desc-grey-about text-justify pa-left-40">
                                                                    for the purpose of commercially exploiting the Content or any part of it
                                                                    (except where you first obtain a licence to do so from Us or, as
                                                                    applicable, from the relevant nominated partner and in such case We
                                                                    reserve the right to charge you for such commercial exploitation)</p>
                                                            </li>
                                                            <li className="d-flex pt-4">
                                                                <div className="float-left right-icon">
                                                                    <span className="material-icons-outlined">
                                                                        radio_button_checked
                                                                    </span>
                                                                </div>
                                                                <p className="desc-grey-about text-justify pa-left-40">
                                                                    in any way that breaches any applicable law or regulation or in a way
                                                                    that is unlawful or fraudulent or has an unlawful or fraudulent purpose
                                                                    or effect</p>
                                                            </li>
                                                            <li className="d-flex pt-4">
                                                                <div className="float-left right-icon">
                                                                    <span className="material-icons-outlined">
                                                                        radio_button_checked
                                                                    </span>
                                                                </div>
                                                                <p className="desc-grey-about text-justify pa-left-40">
                                                                    to transmit, or procure the sending of, any unsolicited or unauthorised
                                                                    advertising or promotional material or any other form of similar
                                                                    solicitation</p>
                                                            </li>
                                                        </ol>
                                                    </p>
                                                </li>
                                                <li className="d-flex pt-4">
                                                    <p className="desc-grey-about text-justify pa-left-4">
                                                        If you are a consumer user, please note that these Site Terms, its subject
                                                        matter and its formation, are governed by English law. You and We both agree
                                                        that any matter or dispute arising out of or in connection with Use of this Site
                                                        or Application (including any contract entered by you with Us through the Site
                                                        or Application) is subject to the non-exclusive jurisdiction of the courts of
                                                        England and Wales. However, if you are a resident of Northern Ireland you may
                                                        also bring proceedings in Northern Ireland, and if you are resident of Scotland,
                                                        you may also bring proceedings in Scotland.
                                                    </p>
                                                </li>
                                                <li className="d-flex pt-4">
                                                    <p className="desc-grey-about text-justify pa-left-4">
                                                        If you are a consumer user, please note that these Site Terms, its subject
                                                        matter and its formation, are governed by English law.
                                                    </p>
                                                </li>
                                            </ol>

                                        </li>
                                    </li>
                                </ul>
                            </div>



                        </div>

                    </div>
                </div>

            </section>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy
