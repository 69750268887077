import { useState, useEffect } from 'react'
import { Calendar } from 'react-date-range';
import { format, isWeekend } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { isInArray } from '../../helpers';
import moment from 'moment'

const MyCalendar = ({ selectedDate, slotDateSelected, oldDates,EndDate, availableSlots }) => {
  const [date, setDate] = useState(null);
  const [liveDates, setDates] = useState([]);

  // console.log("liveDates", liveDates)
  // console.log("oldDates", oldDates)

  useEffect(() => {
    if (date) {
      // console.log("date333333",date)
      selectedDate(date)
    }else{
      let dates = availableSlots?.map(data =>  new Date(data?.date))?.filter((d)=>moment().endOf('day')<=moment(d).endOf('day'))
       dates = dates.sort((a,b)=>a.getTime()-b.getTime())
      if(dates?.length>0){
        //more than the current date
        let newDates = dates.filter((d)=>d>new Date())
        if(newDates?.length>0){
          setTimeout(()=>setDate(new Date(newDates[0])),1000)
        }
      }
    }
    if (availableSlots?.length) {
      let dates = availableSlots?.map(data => new Date(data?.date))
      setDates(dates)
    }
  }, [date,availableSlots])



  function customDayContent(day) {
    let extraDot = null;
    // console.log("date---?",);
    if (oldDates?.length != 0 && isInArray(oldDates, day)) {
      extraDot = (
        <div
          style={{
            height: "8px",
            width: "8px",
            borderRadius: "100%",
            background: "orange",
            position: "absolute",
            top: 4, right: 30
          }}
        />
      )
    }else if (liveDates?.length != 0 && isInArray(liveDates, day)) {
      extraDot = (
        <div
          style={{
            height: "8px",
            width: "8px",
            borderRadius: "100%",
            background: "green",
            position: "absolute",
            top: 4, right: 30
          }}
        />
      )
    }
    if (slotDateSelected[day] && slotDateSelected[day]?.length > 0) {
      extraDot = (
        <div
          style={{
            height: 10,
            width: 10,
            borderRadius: '100%',
            background: '#ca0f2c',
            position: 'absolute',
            top: 5,
            right: 30
          }}
        />
      )
    }
    return (
      <div>
        {extraDot}
        <span>{format(day, "d")}</span>
      </div>
    )
  }
  const today = new Date()
const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

  return (
    <Calendar
      minDate={tomorrow}
      maxDate={new Date(moment(EndDate).endOf('day'))}
      dayContentRenderer={customDayContent}
      onChange={item => setDate(item)} date={date}
    />
  )
}

export default MyCalendar