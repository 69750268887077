import React from "react";

const ResetPass = () => {
  return (
    <React.Fragment>
      <div
        class="modal fade"
        id="reset-password"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <button
              type="button"
              class="close-icon close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span class="material-icons-outlined">close</span>
            </button>

            <div class="modal-body">
              <div class="d-flex justify-content-center align-items-center">
                <div class="card login-card py-5 px-3">
                  <h2 class="m-0 text-center modal-heading">
                    Reset your Password
                  </h2>
                  <span class=" mobile-text pt-4">
                    Please set up a new password by filling the details below.
                  </span>
                  <div class="row subscribe-form contact-details">
                    <div class="col-md-12 pt-4">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="material-icons-outlined">lock</span>
                        </div>
                        <input
                          type="password"
                          class="form-control pl-value"
                          placeholder=" ************"
                        />
                        <div class="input-group-append password-create password">
                          <span class="material-icons-outlined">
                            visibility
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 pt-4">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="material-icons-outlined">lock</span>
                        </div>
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Confirm Password"
                        />
                        <div class="input-group-append password password-create">
                          <span class="material-icons-outlined">
                            visibility
                          </span>
                        </div>
                      </div>
                      <p class="desc-para-about text-danger bg-light mt-4 pa-left-4">
                        Fusce eu odio eget metus vulputate facilisis in non
                        tellus. Phasellus facilisis fermentum{" "}
                      </p>
                    </div>
                  </div>

                  <div class=" btn-wrap col-auto pt-4">
                    <button
                      type="submit"
                      class="red-btn-lg hvr-icon-push contact-button profile-btn"
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ResetPass;
