import React from 'react'
import api from '../../api.json'

const ImageItem = ({file}) => {
    return (
        <div className="slide">
            <figure>
                <img src={file?`${api.base_url}/${file}`:"/assets/images/order-image.png"} style={{maxHeight:490}} alt="img" className="img-fluid" />
            </figure>
        </div>
    )
}

export default ImageItem