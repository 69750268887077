import React from "react";
import Slider from "react-slick";

export default function ImageSlider({children}) {
    var settings = {
        infinite: true,
        autoplay: true,
        dots: true,
        arrows: false,
        autoplaySpeed: 4000,
        lazyLoad:true
    };
    return (
      <Slider {...settings}>
        {children}
      </Slider>
    );
  }