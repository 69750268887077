import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import LogoutModal from '../Modal/LogoutModal';
import serverApi from '../../api.json'

const LoggedInNav = ({categories,user,clearUser}) => {

    const [isModal, setModal] = useState(false)

    const logoutHandler = () => {

        if (user && localStorage.getItem('userData')) {
            toast.success("Logged-out successfully!")
            localStorage.clear()
            clearUser()
            setModal(false)
        }
    }

    const  toggleMenu=()=>{
        // toggle menu-js
        var mobileMenu = document.getElementById("siteMenu");
        mobileMenu.classList.toggle("OpenNav");
        // toggle overflow js
        var body = document.body;
        body.classList.toggle("overflow_hidden");
        // toggle button animate js
        var togglemenuIcon = document.getElementById("menuIcon");
        togglemenuIcon.classList.toggle("active");
    }

  return (
    user && (
        <nav className="navbar navbar-expand-lg">
        <Link to={"/"}><a className="navbar-brand">
        <img src="/assets/images/logo.png" style={{height: 60,width: 55}} alt="logo" />
    </a></Link>
    <div className="loggedIn-user-wrap">
        <img src={user?.customerDetails?.profilePic ? `${serverApi.profilePicCustomer}/${user?.customerDetails?.profilePic}` : "/assets/images/pic_placeholder.png"} alt="user" id="dropdownMenu3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
        <div className="dropdown dropdown-profile dropdown-profile-mobile">

            <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                <div className="dropdown-menu-profile dropdown-menu-mobile">
                <Link to={"/my_profile"}>
                    <div className="profile-icon d-flex">
                        <span className="material-icons-outlined">

                            account_circle

                        </span>
                        <a className="dropdown-item">My Profile</a>
                    </div>
                    </Link>
                    <Link to={"/my_booking"}>
                        <div className="profile-icon d-flex">
                            <span className="material-icons-outlined">

                                admin_panel_settings

                            </span>
                            <a className="dropdown-item" href="my-booking.html">My Booking</a>
                        </div>
                    </Link>
                    <div className="profile-icon d-flex">
                        <span className="material-icons-outlined">
                            sports_soccer
                        </span>
                        <a className="dropdown-item" href="favorite-sports.html">Favorite Sports

                        </a>
                    </div>
                    <div className="profile-icon d-flex">
                        <span className="material-icons-outlined">
                            credit_card
                        </span>
                        <a className="dropdown-item" href="my-card.html">

                            My Card

                        </a>
                    </div>
                    <div className="profile-icon logout d-flex" onClick={() => setModal(true)}>
                                <span className="material-icons-outlined text-danger">

                                    logout

                                </span>
                                <button className="dropdown-item text-danger">

                                    Logout

                                </button>
                     </div>
                </div>

            </div>
        </div>
        <button className="toggleMenu" onClick={toggleMenu}>
            <span className="menu-icon move-left close-icon" id="menuIcon">
                <span></span>
             </span>
         </button>
    </div>

    <div className="site-menu profile-top-main" id="siteMenu">
        <ul className="navbar-nav ml-auto">
            <li className="nav-item">
                <Link to={"/home"}><a className="nav-link">Home</a></Link>
            </li>
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle dropbtn" onClick="dropdownToggle()" href="#"
                    id="navDropdownLink" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    Categories <span
                        className="material-icons-outlined arrow-down-icon">arrow_drop_down</span>
                </a>
                <div className="dropdown-menu dropdown-content" id="dropdownMenu"
                    aria-labelledby="navDropdownLink">
                 {categories?.length>0?categories?.map((c,index)=><Link to={`/category_listing?category=${c?.Category}`} key={index}><span className="dropdown-item">{c?.Category}</span></Link>):null}
                </div>
            </li>
            <li className="nav-item">
                <Link to={"/contact_us"}><a className="nav-link">Support</a></Link>
            </li>
            <li className="nav-item login-user-nav profile-top-main">
                <a className="nav-link login-user bdr-left-red dropdown-toggle" href="#" id="dropdownMenu2"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src={user?.customerDetails?.profilePic ? `${serverApi.profilePicCustomer}/${user?.customerDetails?.profilePic}` : "/assets/images/pic_placeholder.png"} alt="user" />

                    <h2 className="user-heading"><small>Welcome</small>{`${user?.customerDetails?.firstName} ${user?.customerDetails?.lastName}`}</h2>

                </a>
                <div className="dropdown dropdown-profile">

                    <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <div className="dropdown-menu-profile">
                            <Link to={"/my_profile"}>
                            <div className="profile-icon d-flex">
                                <span className="material-icons-outlined">

                                    account_circle

                                </span>

                                <a className="dropdown-item">My Profile</a>
                            </div>
                            </Link>

                            <Link to={"/my_booking"}>
                                <div className="profile-icon d-flex">
                                    <span className="material-icons-outlined">

                                        admin_panel_settings

                                    </span>
                                    <a className="dropdown-item">My Booking</a>
                                </div>
                            </Link>
                            <Link to={"/favorite_sports"}>
                            <div className="profile-icon d-flex">
                                <span className="material-icons-outlined">
                                    sports_soccer
                                </span>
                                <a className="dropdown-item">Favorite Sports

                                </a>
                            </div>
                            </Link>
                            <Link to={"/my_card"}>
                            <div className="profile-icon d-flex">
                                <span className="material-icons-outlined">
                                    credit_card
                                </span>
                                <a className="dropdown-item">

                                    My Card

                                </a>
                            </div>
                            </Link>
                            <div className="profile-icon logout d-flex" onClick={() => setModal(true)}>
                                <span className="material-icons-outlined text-danger">

                                    logout

                                </span>
                                <button className="dropdown-item text-danger">

                                    Logout

                                </button>
                     </div>
                        </div>

                    </div>
                </div>

            </li>

        </ul>
    </div>
    <LogoutModal isModal={isModal} OkCb={() => logoutHandler()} handleToggle={() => setModal(!isModal)} />
</nav>
    )
  )
};

export default LoggedInNav;
