import React,{useState} from 'react';
import CVV from '../MyCard/CVV';


const CardItem = ({card,onCardSelect}) => {
    const cardStyle = {
        background: 'linear-gradient(to right, #146d76 , #333e4a)',color: '#fff',fontWeight:'bold',borderRadius:15,maxHeight:176,zIndex:0
    }

    const [show,setShow] = useState(false)
   
  return <div className="col-md-10" style={{display:'block',margin:'auto',cursor:'pointer'}} onClick={()=>onCardSelect(card)}>
  <div className="card-one pt-4" >
      <div className="card mycard"  style={cardStyle}>
          <div className="card-body">
              <img src="/assets/images/credit-card.png" style={{width:40,float:'left'}} alt="" />
              <p className='text-right'>{card?.cardType?card?.cardType:'VISA'}</p>
              <p className='text-center'><h5>{!show?"**** **** **** ****":card?.card_no} <span style={{zIndex:999,cursor:'pointer'}} onClick={()=>setShow(!show)}>{show?<i className="fa fa-eye"></i>:<i className="fa fa-eye-slash"></i>}</span></h5></p>
              <p className='text-left'>{card?.card_holder_name} <br/> {card?.expiry_date} <CVV card={card}/> </p>
          </div>
      </div>
  </div>
</div>
};

export default CardItem;