import React, { useEffect, useState } from 'react';
import callApi from '../../service/callApi';
import BookItem from './BookItem';
import api from '../../api.json'
import { toast } from 'react-toastify';
import { Logout } from '../../helpers';

const Previous = ({downloadPDF,isReload}) => {
    const [bookings, setBooking] = useState([])
    const [isLoader, setLoader] = useState(false)


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        setLoader(true)
        await callApi.get(api.previousBooking)
            .then((res) => {
                setLoader(false)
                if (res?.data && res?.data?.data?.length > 0) {
                    setBooking(res?.data?.data)
                } else {
                    setBooking([])
                }
            }).catch((e) => {
                setLoader(false)
                console.log(e);
                if (e?.response && e?.response?.data?.error) {
                    toast.error(e?.response?.data?.error)
                }else {
                    toast.error("Error while processing your request")
                }
            })
    }, [isReload])

    return <div className="tab-pane fade show active" id="previous" role="tabpanel" aria-labelledby="previous-tab">
        {isLoader ? <div style={{display:'block',margin:'auto'}}>
            <div class="spinner-border" role="status" style={{padding:30}}>
            <span class="sr-only">Loading...</span>
        </div> 
        </div>: bookings?.length > 0 ? bookings?.map((data, index) => <BookItem key={index} data={data} downloadPDF={downloadPDF} />) : <p className='text-center'>No previous booking</p>}
    </div>
};

export default Previous;
