import React from 'react';
import Footer from '../Layouts/Footer';
import Header from '../Layouts/Header';
import PrintModal from '../Modal/PrintModal';
import Previous from './Previous';
import Upcomming from './Upcomming';


const Index = () => {
    const [modal,setModal] = React.useState(false)
    const [isReload,setReload] = React.useState(false)
    const downloadPDF =(data)=>{
        setModal(data)
    }

  return <div>
        <Header/>
        <section className="hero-banner hero-banner-height" style={{backgroundColor: "black"}}>
            <div className="container">
                <div className="row">
                    <div className="hero-banner-aboutus hero-banner-content">
                        <h2 className="heading-white">
                            My Booking</h2>
                        <p className="desc-white pt-4 text-center">
                            Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis fermentum justo,
                            sit amet convallis arcu
                            interdum vel. Suspendisse vulputate posuere risus, quis consectetur neque scelerisque.
                        </p>
                    </div>
                </div>
            </div>
        </section>

    <section className="tab-section bg-grey ptb-90">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="tab-div ">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
    
                            <a className="nav-link active" id="previous-tab" data-toggle="tab" href="#previous" role="tab" aria-controls="previous" aria-selected="true">Previous Booking</a>
    
                            </li>
    
                            <li className="nav-item">
    
                            <a className="nav-link ml-4" id="upcoming-tab" data-toggle="tab" href="#upcoming" role="tab" aria-controls="upcoming" aria-selected="true">Upcoming Booking</a>
    
                            </li>
                        </ul>
                        <div className="tab-content profile-tab pt-5" id="myTabContent">
                            <Previous downloadPDF={downloadPDF} isReload={isReload}/>
                            <Upcomming downloadPDF={downloadPDF} isReloadCb={(e)=>setReload(e)} />
                         </div> 
                    </div>
                </div>
            </div>
        </div>
    </section>
    <PrintModal isModal={modal} handleToggle={()=>setModal(false)}/>
        <Footer/>
  </div>;
};

export default Index;
