/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import callApi from '../../service/callApi'
import serverApi from "../../api.json";
import NotLoggedInNav from './NotLoggedInNav'
import LoggedInNav from './LoggedInNav'
import { CUSTOMER_LINK } from '../../config';


const Header = () => {
    const [user,setUser] = useState(null)
    const [categories,setCategories] = useState([])
    const navigate = useNavigate()

    useEffect(()=>{
        if(localStorage?.getItem("userData")){
            setUser(JSON.parse(localStorage.getItem("userData")))
        }else{
            setUser(null)
        }

        return ()=>{
            setUser(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[localStorage.getItem("userData")])

    useEffect(async()=>{
       await callApi.get(serverApi.categoriesList)
       .then(({data})=>{
           if(data && data?.data){
             setCategories(data?.data)
           }else{
            setCategories([])
           }
       }).catch((e)=>{
           console.log(e)
       })
    },[])
    
    const NavBar = () => {
        return <div className="row">
        {(!localStorage.getItem("userData")||!JSON.parse(localStorage.getItem("userData"))?.token)?<NotLoggedInNav categories={categories} />:<LoggedInNav user={user} clearUser={()=>{setUser(null); window.location = `${CUSTOMER_LINK}/login`}} categories={categories} />}
      </div>
      };

    return (
        <header className="site-header">
        <div className="container">
        <NavBar/>
        </div>
    </header>
    )
}

export default Header
