import React from 'react';
import {Modal,ModalBody} from 'reactstrap'

const ForgetModalContainer = ({isModal,handleToggle,children}) => {
  return (
    <Modal isOpen={isModal} toggle={handleToggle}>
    <ModalBody>
    <button
            type="button"
            class="close-icon close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleToggle}
          >
            <span class="material-icons-outlined">close</span>
          </button>
        {children}
    </ModalBody>
    </Modal>
  )
};

export default ForgetModalContainer;
