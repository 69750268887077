/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState} from "react";
import CategoryOption from "./Layouts/CategoryOption";
import Footer from "./Layouts/Footer";
import Header from "./Layouts/Header";
import {isAuth} from '../helpers'
import GuestFacilities from "./Layouts/MainHome/GuestFacilities";
import ProtectedRecommendedLayout from "./Layouts/MainHome/ProtectedRecommendedLayout";
import ProtectedPreviousLayout from "./Layouts/MainHome/ProtectedPreviousLayout";
import BottomSubscribe from "./Layouts/MainHome/BottomSubscribe";
import { useNavigate } from "react-router-dom";
import queryString from 'query-string'
import WhatsAppUI from "./Layouts/WhatsAppUI";

const HomeDashboard = () => {
    
    const navigate  = useNavigate()

    const GotoSearch = (data)=>{
        let url = "/search_listing"
        let req ={}
        if(data.category){
            req.category = data.category
        }
        if(data.location){
            req.location = data.location
        }
        const query = queryString.stringify(req)
        if(query){
            url = `${url}?${queryString.stringify(data)}`
        }
        navigate(url)
    }

    return (
        <React.Fragment>
            <Header />
            <section class="hero-banner" style={{ backgroundImage: "url(/assets/images/banner.png)" }}>
                <div class="container">
                    <div class="row">
                        <div class="hero-banner-content">
                            <h2 class="heading-white">
                                We Work With The <br />
                                Best Sports Facilities
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section class="sports-list-wrap bg-grey ptb-90">
                <div class="container">
                    <div class="row">
                        <CategoryOption  searchData={GotoSearch} />
                        {/* main content here */}
                        {isAuth()===true?<ProtectedPreviousLayout  />:<GuestFacilities />}
                        {/* main contentn ends here */}
                    </div>
                </div>
            </section>

           {isAuth()===true && <ProtectedRecommendedLayout/>} 

           <WhatsAppUI/>

            <BottomSubscribe/>

            <Footer />
        </React.Fragment>
    );
};
export default HomeDashboard;
