import React, { useEffect, useState } from "react";
// import OtpModel from "./otpModal";
// import callApi from "../service/callApi";
// import serverApi from "../api.json";
// import ModalContainer from "./ModalContainer";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
// import Step4 from "./Step4";
import ForgetModalContainer from './Modal/ForgetModalContainer'

const ForgotPassword = ({isModal,onClose}) => {
  const [step, setStep] = useState(1);
  const [mobile, setMobile] = useState(0);

  useEffect(()=>{
    if(!isModal){
      setStep(1)
      setMobile(0)
    }
  },[isModal])

  const resetDiloge = () => {
    // console.log("closeeeeb");
    setMobile(0)
    setTimeout(() => setStep(1), 500);
  };

  return (
    <React.Fragment>
      {/* <ModalContainer id="forgot" handleClose={resetDiloge}>
        {step === 1 && <Step1 setStep={setStep} setMobileNumber={setMobile} />}
        {step === 2 && <Step2 setStep={setStep} mobile={mobile} />}
        {step === 3 && <Step3 setStep={setStep} mobile={mobile} />}
        {step === 4 && <Step4 />}
      </ModalContainer> */}
      <ForgetModalContainer isModal={isModal} handleToggle={()=>onClose()}>
        {step === 1 && <Step1 setStep={setStep} setMobileNumber={setMobile} />}
        {step === 2 && <Step2 setStep={setStep} mobile={mobile} />}
        {step === 3 && <Step3 setStep={setStep} mobile={mobile} onDone={()=>onClose()} />}
        {/* {step === 4 && <Step4 />} */}
      </ForgetModalContainer>
    </React.Fragment>
  );
};
export default ForgotPassword;
