import React from 'react'
import Header from '../Layouts/Header'
import Footer from '../Layouts/Footer'
//TermAndCondition component

const Tnc = () => {
    return (
        <div>
            <Header />
            {/* <!-- hero section banner --> */}
            <section className="hero-banner hero-banner-height" style={{ backgroundImage: 'url(/assets/images/about-us.jpg)' }}>
                <div className="container">
                    <div className="row">
                        <div className="hero-banner-aboutus hero-banner-content">
                            <h2 className="heading-white ">
                                Terms and Conditions

                            </h2>
                            <p className="desc-white pt-4 text-center">
                                Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- body part --> */}

            <section className="sports-list-wrap bg-grey ptb-90 about-us">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="heading-grey">
                                Our Website and Application Terms of Use</h2>
                            <h6 className="terms-heading pt-4">PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY</h6>
                            <p className="desc-grey">
                                Last updated 21st July 2020
                            </p>
                        </div>
                        <div className="col-md-12 plr-70 pt-5">
                            <h4 className="heading-grey-term heading-grey-about text-left pt-4">
                                COVID-19 Update to Terms</h4>
                            <p className="pt-4 desc-grey-about text-justify">
                                Changes to government guidance on physical activity and sport were announced on 26 June 2020 and
                                came into effect on 4 July 2020 across England.
                            </p>


                            <p className="pt-4 desc-grey-about text-justify">Since the announcement, national governing bodies
                                (NGBs) have been working alongside the Department for Culture Media and Sport (DCMS) to
                                interpret the new guidance for their individual sports and setting out sport-specific advice for
                                individuals, clubs and local authorities.</p>
                            <p className="pt-4 desc-grey-about text-justify">
                                For a summary of the sport-specific guidance published by NGBs regarding COVID-19, specifically
                                in relation to the re-introduction and return to sport and physical activity in England, please
                                visit the London Sport page here:
                                <a
                                    href="https://londonsport.org/covid-19/return-to-sport-and-physical-activity-guidan">(see link)</a>
                            </p>
                            <p className="pt-4 desc-grey-about text-justify">
                                This page will be updated weekly, more regular updates will be shared on ours and the relevant
                                NGBs’ social media.</p>
                        </div>
                        <div className="col-md-12 plr-70 pt-5">
                            <ol className="p-0">
                                <li value="1" className="term-list-bullet num-list">
                                    <h4 className="heading-grey-term heading-grey-about text-left">
                                        1.TERMS OF WEBSITE USE</h4>
                                    <ol >
                                        <li className="term-list-bullet pt-4 d-flex">
                                            <p className=" desc-grey-about text-justify pa-left-4">
                                                Duis pellentesque in orci eget consectetur. Integer mollis lobortis congue.
                                                Suspendisse sit amet lectus elementum, vulputate metus ut, tincidunt quam. Donec lacinia
                                                dui non magna tempor, at lacinia quam iaculis. Integer fermentum urna id elementum
                                                euismod. Fusce cursus magna urna, sed laoreet ante dignissim id. Integer mattis, lectus
                                                sed viverra commodo, augue neque sagittis erat, id tempor lectus nulla finibus ex. Ut in
                                                odio lorem. In volutpat libero id sagittis vehicula. Interdum et malesuada fames ac ante
                                                ipsum primis in faucibus.
                                            </p>
                                        </li>
                                        <li className="term-list-bullet d-flex pt-4">
                                            <p className=" desc-grey-about text-justify pa-left-4">
                                                Please read these Terms carefully before you start to Use the Site and/or
                                                Application, as they apply to your Use of this Site or Application and We recommend that
                                                you print and keep a copy of them for your future reference.
                                            </p>
                                        </li>
                                        <li className="term-list-bullet d-flex pt-4">
                                            <p className=" desc-grey-about text-justify pa-left-4">
                                                These Site Terms supplement and are in addition to Our Privacy Policy, which also apply to your Use of this Site and Application. Our Privacy Policy <a href="#">(see link)</a> explains the personal data that We collect about you and process when you Use the Site or Application. By Using this Site or Application, you are deemed to have read, understood and agreed to Our Privacy Statement in its entirety, consented to the processing of your personal data and you warrant that all data provided by you is accurate.
                                            </p>
                                        </li>
                                        <li className="term-list-bullet d-flex pt-4">
                                            <p className=" desc-grey-about text-justify pa-left-4">
                                                There may also be legal notices on other areas of this Site and Application that relate to your Use of the Site or Application, all of which will, together with these Site Terms and Our Privacy Policy govern your Use.
                                            </p>
                                        </li>
                                    </ol>

                                </li>
                                <li value="2" className="term-list-bullet num-list pt-5">
                                    <h4 className="heading-grey-term heading-grey-about text-left">
                                        2.  INFORMATION ABOUT US</h4>
                                    <ol>

                                        <li className="term-list-bullet d-flex pt-4">
                                            <p className=" desc-grey-about text-justify pa-left-4">
                                                We are a limited company registered in England and Wales under registration number 08427059. Our registered office is 43-45 Charlotte Street, London, W1T 1RS.
                                            </p>
                                        </li>
                                        <li className="term-list-bullet d-flex pt-4">
                                            <p className=" desc-grey-about text-justify pa-left-4">
                                                We are an Agent that act on behalf of suppliers, operators or owners of sports venues, facilities, clubs, activities, coaches, referees and/or umpires, leagues, ladders and tournaments ("Facilities").
                                            </p>
                                        </li>
                                        <li className="term-list-bullet d-flex pt-4">
                                            <p className=" desc-grey-about text-justify pa-left-4">
                                                These Site Terms supplement and are in addition to Our Privacy Policy, which also apply to your Use of this Site and Application. Our Privacy Policy <a href="#">(see link)</a> explains the personal data that We collect about you and process when you Use the Site or Application. By Using this Site or Application, you are deemed to have read, understood and agreed to Our Privacy Statement in its entirety, consented to the processing of your personal data and you warrant that all data provided by you is accurate.
                                            </p>
                                        </li>
                                    </ol>

                                </li>
                                <li value="3" className="term-list-bullet num-list pt-5">
                                    <h4 className="heading-grey-term  heading-grey-about text-left">
                                        3.  YOUR USE OF THIS SITE</h4>
                                    <ol>

                                        <li className="term-list-bullet d-flex pt-4">
                                            <p className="desc-grey-about text-justify pa-left-4">
                                                Your Use of this Site and Application's published links, messages, material or information ("Content") must only be for lawful purposes and must not be in a way that infringes Our or anyone else's rights or restricts or inhibits ours or anyone else's enjoyment of this Site or Application.
                                            </p>
                                        </li>

                                        <li className="term-list-bullet d-flex pt-4">
                                            <p className="desc-grey-about text-justify pa-left-4">
                                                Your Use of this Site or Application is solely for your own personal use. Except where the relevant page of this Site and Application and these Site Terms expressly permit you to do so, or We consent in writing to you doing so, you may not Use this Site or Application:

                                                <ol>
                                                    <li className="d-flex pt-4">
                                                        <div className="float-left right-icon">
                                                            <span className="material-icons-outlined">
                                                                radio_button_checked
                                                            </span>
                                                        </div>
                                                        <p className=" desc-grey-about text-justify pa-left-40">
                                                            to copy, reproduce, use, re-sell or otherwise deal with any Content on this Site or Application</p>
                                                    </li>
                                                    <li className=" d-flex pt-4">
                                                        <div className="float-left right-icon">
                                                            <span className="material-icons-outlined">
                                                                radio_button_checked
                                                            </span>
                                                        </div>
                                                        <p className=" desc-grey-about text-justify pa-left-40">
                                                            for the purpose of commercially exploiting the Content or any part of it (except where you first obtain a licence to do so from Us or, as applicable, from the relevant nominated partner and in such case We reserve the right to charge you for such commercial exploitation)</p>
                                                    </li>
                                                    <li className=" d-flex pt-4">
                                                        <div className="float-left right-icon">
                                                            <span className="material-icons-outlined">
                                                                radio_button_checked
                                                            </span>
                                                        </div>
                                                        <p className=" desc-grey-about text-justify pa-left-40">
                                                            in any way that breaches any applicable law or regulation or in a way that is unlawful or fraudulent or has an unlawful or fraudulent purpose or effec</p>
                                                    </li>
                                                    <li className=" d-flex pt-4">
                                                        <div className="float-left right-icon">
                                                            <span className="material-icons-outlined">
                                                                radio_button_checked
                                                            </span>
                                                        </div>
                                                        <p className=" desc-grey-about text-justify pa-left-40">
                                                            to transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam or</p>
                                                    </li>
                                                </ol>

                                            </p>

                                        </li>

                                        <li className="term-list-bullet d-flex pt-4">
                                            <p className=" desc-grey-about text-justify pa-left-40">
                                                If you are a consumer user, please note that these Site Terms, its subject matter and its formation, are governed by English law. You and We both agree that any matter or dispute arising out of or in connection with Use of this Site or Application (including any contract entered by you with Us through the Site or Application) is subject to the non-exclusive jurisdiction of the courts of England and Wales. However, if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland.
                                            </p>
                                        </li>
                                        <li className="term-list-bullet d-flex pt-4">
                                            <p className=" desc-grey-about text-justify pa-left-40">
                                                If you are a consumer user, please note that these Site Terms, its subject matter and its formation, are governed by English law.
                                            </p>
                                        </li>
                                    </ol>

                                </li>
                            </ol>


                        </div>

                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default Tnc
