/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import CategoryOption from '../Layouts/CategoryOption';
import Footer from '../Layouts/Footer';
import Header from '../Layouts/Header';
import SearchFilters from './SearchFilters';
import { useLocation } from 'react-router-dom'
import { parseParams, scrollNow } from '../../helpers';
import serverApi from '../../api.json'
import callApi from "../../service/callApi";
import { toast } from 'react-toastify';
import ServiceClassItem from './ServiceClassItem';
import { useNavigate } from 'react-router-dom'
import Loader from '../Layouts/Loader'
import queryString from 'query-string'

const SearchListing = (props) => {
    const [selectedFilter, setFilter] = useState({
        category: "",
        location: "",
        status: "",
        format: "",
        sortBy: "",
        facility: "",
        page: "",
        radius: []
    })

    const [enableBtn, setEnableBtn] = useState({ previous: false, next: false })
    const [pageLabel, setPageLabel] = useState("")
    const [totalList, setTotalList] = useState(0)

    const [serviceClassList, setServiceClassList] = useState([])
    const [isLoading, setLoader] = useState(false)

    const locationPorps = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (locationPorps?.search && queryString.parse(locationPorps?.search)) {
            const stringified = queryString.parse(locationPorps?.search)
            if (stringified?.radius) {
                stringified.initialRadius = [parseInt(stringified?.radius[0]), parseInt(stringified?.radius[1])]
            }
            // console.log("stringified--->",stringified);
            setFilter(stringified)
            fetchServiceClass(stringified)
        } else {
            fetchServiceClass()
        }
    }, [locationPorps?.search])

    useEffect(() => {
        // console.log("selectedFilter",selectedFilter)
        let url = props?.title == "search" ? "/search_listing" : "/category_listing"
        let request = {}
        if (selectedFilter.category) {
            request.category = selectedFilter?.category ? selectedFilter?.category : ""
        }

        if (selectedFilter.location) {
            request.location = selectedFilter?.location ? selectedFilter?.location : ""
        }

        if (selectedFilter.format) {
            request.format = selectedFilter?.format ? selectedFilter?.format : ""
        }

        if (selectedFilter.status) {
            request.status = selectedFilter?.status ? selectedFilter?.status : ""
        }

        if (selectedFilter.sortBy) {
            request.sortBy = selectedFilter?.sortBy ? selectedFilter?.sortBy : ""
        }

        if (selectedFilter.facility) {
            request.facility = selectedFilter?.facility ? selectedFilter?.facility : ""
        }

        if (selectedFilter.page) {
            request.page = selectedFilter?.page
        }

        if (selectedFilter.radius?.length > 0) {
            request.radius = selectedFilter?.radius
        }

        if (selectedFilter.latValue && selectedFilter.lngValue) {
            request.lngValue = selectedFilter?.lngValue
            request.latValue = selectedFilter?.latValue
        }

        const stringified = queryString.stringify(request);
        // console.log("stringifiedasdasd",stringified);
        if (stringified) {
            navigate(`${url}?${stringified}`)
        }
    }, [selectedFilter, selectedFilter?.radius])


    const fetchServiceClass = async (query = {}) => {
        setLoader(true)
        await callApi.post(serverApi.searchListing, query)
            .then((res) => {
                setLoader(false)
                // if(locationPorps?.search && parseParams(locationPorps?.search)){
                //     navigate('/search_listing')
                // }
                if (res?.data && res?.data?.results?.serviceClassList?.length > 0) {
                    setServiceClassList(res?.data?.results?.serviceClassList)
                } else {
                    setServiceClassList([])
                }
                if (res?.data && res?.data?.results) {
                    setEnableBtn({
                        next: res?.data?.results?.Next, previous: res?.data?.results?.Previous
                    })
                    setPageLabel(`Total ${res?.data?.results?.startOfItem} / ${res?.data?.results?.totalInDb}`)
                    setTotalList(res?.data?.results?.totalInDb)
                }
            }).catch((e) => {
                setLoader(false)
                console.log('error', e);
                if (e?.response && e?.response?.data?.error) {
                    toast.error(e?.response?.data?.error)
                } else {
                    toast.error("Error! while processing your request")
                }
            })
    }

    const handleCategorySearch = (data) => {
        // console.log("handleCategorySearch",data);
        if (!data?.category && !data?.lngValue && !data?.latValue && (data?.location + "")?.trim()?.length == 0) {
            let url = "/search_listing"
            navigate(url)
        }
        scrollNow("scroll2")
        setFilter((s) => ({ ...s, latValue: data?.latValue ? data?.latValue : '', lngValue: data?.lngValue ? data?.lngValue : '', category: data?.category ? data?.category : "", facility: "", format: '', sortBy: '', status: '', page: "", location: data?.location }))
    }

    const handlePage = (name) => {
        scrollNow("scroll2")
        if (name == "Next") {
            setFilter((s) => ({ ...s, page: selectedFilter?.page ? parseInt(selectedFilter?.page) + 1 : 2 }))
        } else {
            setFilter((s) => ({ ...s, page: selectedFilter?.page ? parseInt(selectedFilter?.page) - 1 : 1 }))
        }
    }

    const showInfo = () => {
        let message = `There are ${totalList} places to play basketball within a `
        if (selectedFilter?.radius?.length > 0) {
            const km = (parseInt(selectedFilter?.radius[1]) - parseInt(selectedFilter?.radius[0]))
            message += `${km} Km radius of your desired location`
        } else {
            const km = 100
            message += `${km} km radius of your desired location`
        }
        return message
    }


    return (
        <div>
            <Header />

            {/* banner starts */}
            <section className="hero-banner hero_banner__category hero-banner-height" style={{ backgroundColor: 'black' }}>
                <div className="container">
                    <div className="row">
                        <div className="hero-banner-content pb-0">
                            <h2 className="heading-white ">
                                {props?.title == "search" ? 'Search Listing' : 'Category Listing'}

                            </h2>
                            <p className="desc-white pt-4 text-center">
                                Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis fermentum justo,
                                sit amet convallis arcu
                                interdum vel. Suspendisse vulputate posuere risus, quis consectetur neque scelerisque.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* banner ends */}

            {/* starts main  */}

            <section className="sports-list-wrap bg-white ptb-90" >
                <div className="container-fluid">
                    <div className="row">
                        <CategoryOption selectedFilter={selectedFilter} searchData={handleCategorySearch} />
                        <SearchFilters selectedFilter={selectedFilter} onfiltered={(name, value) => setFilter((s) => ({ ...s, [name]: value, page: "" }))} />
                    </div>
                </div>
            </section>
            <section className="sports-list-wrap bg-grey" id="scroll2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="p-0">
                                <li className="search-list-details pt-5">
                                    <div className="col-md-8">
                                        <div className="warning-msg bg-white ">
                                            <p>{showInfo()}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="search-dropdown d-service-para d-flex float-right align-items-center">
                                            {/* <!-- <form className="subscribe-form"> --> */}


                                            <p className="pr-4">Sort BY</p>

                                            <div className="form-group mb-0">

                                                <div className="select-wrap opt-select">
                                                    <select className="form-select select-form-sort" onChange={(e) => setFilter((s) => ({ ...s, "sortBy": e.target.value }))}>
                                                        <option value="a-z">Sort by Name: (A to Z)</option>
                                                        <option value="z-a">Sort by Name: (Z to A)</option>
                                                        <option value="l-h">Sort by Price: Low to High</option>
                                                        <option value="h-l">Sort by Price: High to Low</option>
                                                    </select>
                                                    <span className="material-icons-outlined arrow-down-icon">
                                                        arrow_drop_down
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>


                        </div>
                        <div className="col-md-12">
                            {/* <div className="row mb-30 search-para" style={{float:'right',marginTop: -28}}>{pageLabel?<p>{pageLabel}</p>:null}</div> */}
                            <div className="sports-list booking-list">
                                <div className="ptb-70-50 pt-3 row">

                                    {
                                        isLoading ? <Loader /> : serviceClassList?.length != 0 ? serviceClassList?.map((item, key) => <ServiceClassItem selectedFilter={selectedFilter} key={key} item={item} />) : <div className="col-lg-12"><p style={{ textAlign: 'center', fontWeight: 'bold' }}>No Service or class is available</p></div>
                                    }

                                </div>
                                {
                                    (!isLoading && serviceClassList?.length != 0) ? (<div className='row mb-4 mt-0'>
                                        <div style={{ display: 'block', marginRight: 'auto', marginLeft: 'auto' }}>
                                            <button className='btn btn-secondary' disabled={enableBtn?.previous == false} onClick={() => handlePage("Previous")}>Previous</button>{" "}
                                            <button className='btn btn-secondary' disabled={enableBtn?.next == false} onClick={() => handlePage("Next")}>Next</button>
                                        </div>
                                    </div>) : null
                                }

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* ends main */}
            <Footer />
        </div>
    )
};

export default SearchListing;
