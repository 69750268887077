/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import callApi from "../../service/callApi";
import serverApi from "../../api.json";
import RangeSlider from "./RangeSlider"
import queryString from 'query-string'

const SearchListing = ({ selectedFilter, onfiltered }) => {
    const [facilities, setFacilities] = useState([])
    const [filter, setFilter] = useState({
        facility: "",
        status: "",
        format: "",
        radius: [0, 100]
    })

    useEffect(() => {
        if (selectedFilter?.facility) {
            setFilter((f) => ({ ...f, facility: selectedFilter?.facility }))
        } else {
            setFilter((f) => ({ ...f, facility: "" }))
        }
    }, [selectedFilter?.facility])

    useEffect(() => {
        if (selectedFilter?.initialRadius?.length > 0) {
            setFilter((f) => ({ ...f, radius: selectedFilter?.initialRadius }))
        }
    }, [selectedFilter?.initialRadius])

    const categoryHandler = (name, value) => {
        onfiltered(name, value)
        setFilter((s) => ({ ...s, [name]: value }))
    }

    useEffect(async () => {
        let req = {}
        let apiUrl = `${serverApi.getFacilitiesList}`
        if (selectedFilter?.category) {
            req.category = selectedFilter?.category
        }
        if (selectedFilter?.location) {
            req.city = selectedFilter?.location
        }
        const query = queryString.stringify(req)
        if (query) {
            apiUrl = `${apiUrl}?${query}`
        }
        await callApi.get(apiUrl)
            .then(({ data }) => {
                if (data && data?.data?.length > 0) {
                    setFacilities(data?.data)
                } else {
                    setFacilities([])
                }
            }).catch((e) => {
                console.log(e)
            })
    }, [selectedFilter?.category, selectedFilter?.location])

    return (
        <div className="col-md-12 plr-70">
            <ul>
                <li>
                    <div className="justify-content-start">
                        <h2 className="sports-name text-left">
                            Filter by</h2>

                        <ul className="p-0">
                            <li className="search-list-details d-service-para row">
                                <div className="col-md-12">
                                    <div className="filter_options">
                                        <div className='filter_options__container'>
                                            <p className="pt-2">Lock-down Status</p>
                                            <div className="opt-div pt-4">
                                                <input type="radio" name="Status" value="open" checked={selectedFilter?.status == 'open'} onClick={(e) => categoryHandler("status", "open")} /><span
                                                    className="desc-grey-about pr-2">Open</span>
                                                <input type="radio" name="Status" value="close" checked={selectedFilter?.status == 'close'} onClick={(e) => categoryHandler("status", "close")} /><span
                                                    className="desc-grey-about pr-2">Close</span>
                                            </div>
                                        </div>
                                        <div className='filter_options__container'>
                                            <p className="pt-2">Format of Service/Class</p>
                                            <div className="opt-div pt-4">
                                                <input type="radio" name="format" value="na" checked={selectedFilter?.format == 'na'} onClick={(e) => categoryHandler("format", "na")} /><span
                                                    className="desc-grey-about pr-4">Na</span>
                                                <input type="radio" name="format" value="Indoor" checked={selectedFilter?.format == 'Indoor'} onClick={(e) => categoryHandler("format", "Indoor")} /><span
                                                    className="desc-grey-about pr-4">Indoor</span>
                                                <input type="radio" name="format" value="Outdoor" checked={selectedFilter?.format == 'Outdoor'} onClick={(e) => categoryHandler("format", "Outdoor")} /><span
                                                    className="desc-grey-about">Outdoor</span>
                                            </div>
                                        </div>
                                        <div className='filter_options__container'>
                                            <div className="radius-data pt-2">
                                                <p>Radius</p>
                                                <div className="pt-2">
                                                    <div className="d-flex range-point justify-content-between">
                                                        <small>{filter?.radius?.length > 0 && filter?.radius[0]} </small>
                                                        <small className="radius-data-range">{filter?.radius?.length > 0 && filter?.radius[1]}</small>
                                                    </div>
                                                    <RangeSlider
                                                        value={filter?.radius}
                                                        max={100}
                                                        min={0}
                                                        onChange={(e, v) => categoryHandler("radius", v)}
                                                        valueLabelDisplay="auto"
                                                        aria-labelledby="range-slider"
                                                        onChangeCommitted={(e, v) => categoryHandler("radius", v)}
                                                    />
                                                    {/* <figure>
                                                <img src="assets/images/radius.jpg" alt="img" className="img-fluid" />
                                            </figure> */}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter_options__container">
                                            <form className="subscribe-form service-form pt-2">


                                                <p>Facilities</p>
                                                <div className="form-group pt-2">

                                                    <div className="select-wrap req-select">
                                                        <select className="form-select select-form" value={filter?.facility} onChange={(e) => categoryHandler("facility", e.target.value)}>
                                                            <option value="">Facilities are required ?</option>
                                                            {(facilities && facilities?.length > 0) ? facilities.map((op, key) => <option key={key} value={op?._id}>{op?.Name}</option>) : null}
                                                        </select>
                                                        <span className="material-icons-outlined arrow-down-icon">arrow_drop_down</span>
                                                    </div>

                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    )
};

export default SearchListing;
