import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import callApi from "../../service/callApi";
import serverApi from "../../api.json";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import validator from 'validator'
import ErrorShow from "../Forms/ErrorShow";

const NewMailUpdateForm = ({ data, handleToggle, isModal, resendOtp, doneCB }) => {

    const [email, setEmail] = useState("")
    const [erros, setErros] = useState({ email: "" })
    const navigate = useNavigate()

    const validate = (e) => {
        if (!validator.isEmail(e.target.value)) {
            setErros({ email: "Invalid email address!" })
        } else {
            setErros({ email: "" })
        }
        setEmail(e.target.value)
    }

    const onSubmit = async(e)=>{
        e.preventDefault()
        await callApi.put(`${serverApi.sendEmailVerify}/${data?.token}`,{
            newEmail:email
        }).then((res)=>{
            if(res?.data && res?.data?.message){
                toast.success(res?.data?.message)
                handleToggle()
                setTimeout(()=>navigate("/my_profile"),1000)
            }
        }).catch((e)=>{
            if(e?.response && e?.response?.data?.error){
                toast.error(e?.response?.data?.error)
            }else{
                toast.error("Error while updating details")
            }
        })
    }

    return (
        // <Modal isOpen={isModal} toggle={handleToggle}>
        <Modal isOpen={isModal} toggle={handleToggle}>

            <ModalBody>
                <button
                    type="button"
                    className="close close-icon"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleToggle}
                >
                    <span className="material-icons-outlined">
                        close
                    </span>
                </button>


                <div class="d-flex justify-content-center align-items-center container">
                    <div class="card login-card py-5 px-3">
                        <h5 class="m-0 text-center modal-heading">Add new mail id</h5>
                        <span class=" mobile-text pt-4"> Enter new email address </span>
                        <form autoComplete="off" onSubmit={onSubmit}>
                            <div class="subscribe-form contact-details">
                                <div class="col-md-12 pt-4">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="material-icons-outlined">mail_outline</span>
                                        </div>
                                        <input type="email" value={email} onChange={validate} class="form-control" placeholder="Email Id" required />
                                    </div>
                                    <ErrorShow errorMsg={erros.email} />
                                </div>
                            </div>
                            <div class=" btn-wrap  col-auto pt-5">
                                <button type="submit" class="blue-btn-lg hvr-icon-push profile-btn common-btn">Add Email
                                    Id</button>
                            </div>
                        </form>
                    </div>
                </div>

            </ModalBody>
        </Modal>
    )
}

export default NewMailUpdateForm
