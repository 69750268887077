import axios from "axios";
import { toast } from "react-toastify";
import { isAuth, Logout } from "../helpers";

axios.interceptors.request.use((req) => {
  if (localStorage.getItem('userData')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('userData'))?.token}`;
  }

  return req;
});

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

    if(error?.response?.status==404){
       if(isAuth()){
         toast.error('Session is expired!')
         Logout()
       }
    }

  if (!expectedError) {
    return "Logging the error", error;
  }
  return Promise.reject(error);
})

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
