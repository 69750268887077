import React, { useEffect, useState } from 'react';
import { PaymentInputsContainer,usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { toast } from 'react-toastify';
import validator from 'validator';
import ErrorShow from '../Forms/ErrorShow';
import serverApi from "../../api.json";
import callApi from "../../service/callApi";
import Loader from '../Layouts/Loader'

const AddCard = ({ doneCb }) => {

    const [cardDetails, setCardDetails] = useState({
        card_no: "",
        card_holder_name: "",
        expiry_date: "",
        cvv: "",
        cardType:""
    })

    const [isLoading, setLoader] = useState(false)

    useEffect(()=>{
        return ()=>{
            setCardDetails({
                card_no: "",
                card_holder_name: "",
                expiry_date: "",
                cvv: "",
                cardType:""
            })
        }
    },[])

   
const { meta,getCardImageProps,getCardNumberProps,getExpiryDateProps,getCVCProps } = usePaymentInputs();


    const [errors,setErrors] = useState(null)

    const onSubmit = async(e) => {
        e.preventDefault()
        const name = (cardDetails?.card_holder_name+"")?.replace(/\s+/g,'')
        if(!validator.isAlpha(name)){
            setErrors((s)=>({...s,name:"Invalid name!"}))
            return false
        }else{
            setErrors((s)=>({...s,name:null}))
        }
        if (!meta?.error) {
            const request = {
                card_no: cardDetails?.card_no,
                card_holder_name: cardDetails?.card_holder_name,
                expiry_date: cardDetails?.expiry_date,
                cvv: cardDetails?.cvv,
                cardType:meta?.cardType?.displayName
            }
            setLoader(true)
            await callApi.post(serverApi?.addmycard,request)
            .then((res)=>{
            setLoader(false)

                if(res && res?.data){
                    toast.success(res?.data?.message)
                }
                doneCb()
            }).catch((e)=>{
            setLoader(false)

                if(e?.response && e?.response?.data?.error){
                    toast.error(e?.response?.data?.error)
                }else{
                    toast.error("Error! while processing your request")
                }
            })

        }else{
            toast.error(meta?.error)
        }
    }
    return (
        <form className="subscribe-form contact-details" onSubmit={onSubmit}>
                            <div className="row pt-4">
                                <div className="col-md-6">
                                    <label>Card Number </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            {/* <span className="material-icons-outlined">
                                                credit_card
                                            </span> */}
                                            <svg {...getCardImageProps({ images })} />
                                        </div>
                                        
                                        <input {...getCardNumberProps({onChange:(e)=>setCardDetails((c)=>({...c,card_no:e.target.value}))})} value={cardDetails?.card_no} className="form-control"  placeholder="your credit/debit card no" required />
                                        {/* <input type="text" className="form-control"  onChange={(e)=>setCardDetails((c)=>({...c,card_no:e.target.value}))} placeholder="your credit/debit card no" /> */}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Card Holder Name </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="material-icons-outlined">person_outline</span>
                                        </div>
                                        <input type="text" className="form-control" value={cardDetails?.card_holder_name} onChange={(e) => setCardDetails((c) => ({ ...c, card_holder_name: e.target.value }))} placeholder="place your name" required />
                                    </div>
                                    <ErrorShow errorMsg={errors?.name}/>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-md-6">
                                    <label>Exipry Date </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="material-icons-outlined">
                                                credit_card
                                            </span>
                                        </div>
                                        <input {...getExpiryDateProps({onChange:(e)=>setCardDetails((c)=>({...c,expiry_date:e.target.value}))})} value={cardDetails?.expiry_date} className="form-control" placeholder="06/24" required />
                                        {/* <input type="text" className="form-control" placeholder="06/24" onChange={(e)=>setCardDetails((c)=>({...c,expiry_date:e.target.value}))} /> */}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>CVV </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="material-icons-outlined">
                                                credit_card
                                            </span>
                                        </div>
                                        <input {...getCVCProps({onChange:(e)=>setCardDetails((c)=>({...c,cvv:e.target.value}))})} value={cardDetails?.cvv} className="form-control" placeholder="000" required />
                                        {/* <input type="text"  className="form-control" placeholder="32"  onChange={(e)=>setCardDetails((c)=>({...c,cvv:e.target.value}))} /> */}
                                    </div>
                                </div>
                            </div>
                            <p style={{color:'red'}}>{meta.isTouched && meta.error && <span>{meta.error}</span>}</p>
                            {isLoading?<Loader/>:
                                <div className="btn-wrap col-auto pt-5" disabled={meta.error}>
                                <input type="submit" value="Add Card" disabled={meta.error} className="red-btn-lg hvr-icon-push contact-button" />
                            </div>}
        </form>
    )
}

export default AddCard;
