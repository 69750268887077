import React, { useEffect, useState } from "react";
import callApi from "../service/callApi";
import serverApi from "../api.json";
import { toast } from "react-toastify";
import validator from 'validator'
import ErrorShow from './Forms/ErrorShow'

const Step1 = ({ setStep, setMobileNumber }) => {
  const [mobileNumber, setMobile] = useState("");
  const [error, setError] = useState("");

  useEffect(()=>{
    setMobile("")
      setError("")
    return ()=>{
      setMobile("")
      setError("")
    }
  },[])

  const sendOtp = async (e) => {
    e.preventDefault()
    if (validator.isEmpty(mobileNumber + "")) {
      setError("Phone no. is required!")
      setMobileNumber("")
    } else if (!validator.isMobilePhone(mobileNumber + "")) {
      setError("Please enter a valid 10 digit number!")
      setMobileNumber("")
    } else if ((mobileNumber + "")?.length!==10) {
      setError("Phone no. must be 10 digit!")
      setMobileNumber("")
    }else if (!validator.isEmpty(mobileNumber + "") && validator.isMobilePhone(mobileNumber + "")) {
      try {
        setError("")
        let apiObj = await callApi.post(serverApi.sendOtp, {
          phone: mobileNumber,
          type: "Forgot",
        });
        if (apiObj?.data?.data == "No customer with this phone no.") {
          toast.error("No customer with this phone no.")
        } else {
          setMobileNumber(mobileNumber);
          setStep(2);
        }
      } catch (e) {
        setError("")
        setMobileNumber("")
        if (e?.response && e?.response?.data?.error) {
          toast.error(e?.response?.data?.error)
        } else {
          toast.error("Error while updating details")
        }
      }
    }
  };

  return (
    <React.Fragment>
      <div class="d-flex justify-content-center align-items-center">
        <div class="card login-card py-5 px-3">
          <h2 class="m-0 text-center modal-heading">Forgot password?</h2>
          <span class="mobile-text pt-4">
            Enter your Registered mobile number below to recieve an OTP(One time
            password)
          </span>
          <form onSubmit={sendOtp}>
            <div class="subscribe-form contact-details">
              <div class="col-md-12 pt-4">
                <div class="forgot-number">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Phone no."
                    maxLength={10}
                    value={mobileNumber}
                    onChange={(e) => setMobile(e.target.value)}
                    required
                  />
                </div>
                <ErrorShow errorMsg={error} />
              </div>
            </div>
            <div class=" btn-wrap col-auto pt-4">
                <button
                type="submit"
                data-toggle="modal"
                data-target={""}
                class="red-btn-lg hvr-icon-push contact-button profile-btn"
                data-dismiss={""}
                aria-label="Close">
                  Send
                </button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Step1;
