import React from 'react'

const WhatsAppUI = () => {
  return (
    <section class="whatsapp-wrap" style={{ backgroundImage: "url(/assets/images/whatsapp-bg.png)" }}>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-12 col-lg-8">
                            <div class="whatsapp-content-left">
                                <div class="whatsapp-img">
                                    <img src="assets/images/whatsap-icon.png" alt="img" class="img-fluid" />
                                </div>
                                <div class="whatsapp-content">
                                    <h3 class="heading-green">
                                        Nunc facilisis quam massa
                                    </h3>
                                    <h2 class="heading-white-lg">
                                        Join Us On Whatsapp
                                    </h2>
                                    <p class="desc-white pt-4">
                                        Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis<br />
                                        fermentum justo, sit amet convallis arcu interdum vel. Suspendisse<br />
                                        vulputate posuere risus, quis consectetur neque scelerisque.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-4">
                            <div class="whatsapp-content-right">
                                <div class="btn-wrap">
                                    <a class="update-btn white-btn-lg hvr-icon-push" target={"_blank"} href="https://chat.whatsapp.com/invite/8IrHMhzWmcuCQpZcII7ynC">
                                        <span>Click Here To Get <b>More Updated</b></span>
                                        <img src="assets/images/mouse-icon.png" alt="mouse" class="hvr-icon" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
  )
}

export default WhatsAppUI