import React,{useState} from 'react';
import CVV from './CVV';


const CardItem = ({setSelected,selected,card}) => {
    const cardStyle = {
        background: 'linear-gradient(to right, #146d76 , #333e4a)',color: '#fff',fontWeight:'bold',borderRadius:15,maxHeight:176,zIndex:0
    }

    const [show,setShow] = useState(false)
    const activeStyle = {
        border:'1px solid #dc3545',borderRadius: 10,paddingBottom:15
    }
  return <div className="col-md-4" style={(selected==card?._id)?activeStyle:{}}>
  <div className="card-one pt-4" >
      <div className="card mycard"  style={cardStyle}  onClick={()=>setSelected(card?._id)}>
          <div className="card-body">
              <img src="/assets/images/credit-card.png" style={{maxWidth:40,float:'left'}} alt="" />
              <p className='text-right'>{card?.cardType?card?.cardType:'VISA'}</p>
              <p className='text-center'><strong>{!show?"**** **** **** ****":card?.card_no} <span style={{zIndex:999,cursor:'pointer'}} onClick={()=>setShow(!show)}>{show?<i className="fa fa-eye"></i>:<i className="fa fa-eye-slash"></i>}</span></strong></p>
              <p className='text-left'>{card?.card_holder_name} <br/> {card?.expiry_date} <CVV card={card}/> </p>
          </div>
      </div>
  </div>
</div>
};

export default CardItem;
