import React from 'react';

const EminitiesItem = ({item}) => {
  return  <li>
  <div className="eminities-content">
      <span className="material-icons-outlined check-icon">
          done
      </span>
      <p>
          {item?.name}
      </p>
  </div>
</li>
};

export default EminitiesItem;
