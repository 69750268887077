/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import callApi from "../service/callApi";
import serverApi from "../api.json";
import { useNavigate } from "react-router";
// import ForgotPass from "./forgot_pass_modal";
import ForgotPassword from "./ForgotPassword";
import ReCAPTCHA from "react-google-recaptcha";
import validator from "validator";
import ErrorShow from "./Forms/ErrorShow";
import { toast } from "react-toastify";
import { withCookies } from "react-cookie";
import { getCheckoutInfo, isAuth } from "../helpers";
import { CUSTOMER_LINK, GUEST_LINK, SITE_KEY } from "../config";

const Login = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("userData")) {
      navigate("/");
    }
  });

  const { cookies } = props;

  const [email, setEmail] = useState(
    cookies.get("email") ? cookies.get("email") : ""
  );
  const [password, setPassword] = useState(
    cookies.get("password") ? cookies.get("password") : ""
  );
  const [isCaptchaVerified, setCaptcha] = useState(false);
  const [togglee, setToggle] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isModal, setShowModal] = useState(false);
  const [cookieChecked, setCookieChecked] = useState(
    cookies.get("email") && cookies.get("password") ? true : false
  );

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const loginCustomer = async () => {
    if (validFields()) {
      if (email && password && isCaptchaVerified) {
        setLoading(true);
        // console.log("cookieChecked",cookieChecked)
        if (cookieChecked) {
          //save to cookie
          cookies.set("email", email, { path: "/login" });
          cookies.set("email", email, { path: "/" });
          cookies.set("password", password, { path: "/" });
          cookies.set("password", password, { path: "/login" });
        } else {
          //remove from cookie
          cookies.set("email", "", { path: "/login" });
          cookies.set("password", "", { path: "/login" });
          cookies.set("email", "", { path: "/" });
          cookies.set("password", "", { path: "/" });
        }
        let userData = { email: email, password: password };
        let loginUser = await callApi.post(serverApi.login, userData);
        setLoading(false);

        if (loginUser?.data?.message && loginUser?.data?.statusCode == 400) {
          toast.warn(loginUser?.data?.message);
        } else if (loginUser?.data?.statusCode == 200) {
          //set to local storage
          if (loginUser && loginUser.data.data.customerCredentials) {
            localStorage?.setItem(
              "userData",
              JSON.stringify(loginUser.data.data.customerCredentials)
            );
          }
          toast.success("Logged-in successfully!");
        }

        if (loginUser.data.data.customerCredentials) {
          if (getCheckoutInfo() == false) {
            setTimeout(() => navigate("/home"), 2000);
          } else {
            let id = getCheckoutInfo()?.serviceClassId;
            if (id) {
              setTimeout(() => navigate(`/service_class_details/${id}`), 2000);
            }
          }
        }
      }
    }
  };

  function onChange(value) {
    // console.log("Captcha value:", value);
    setCaptcha(true);
  }
  const handleShowPassword = () => {
    let toggle = togglee;
    toggle = !toggle;
    setToggle(toggle);
  };

  const validFields = () => {
    if (validator.isEmpty(email)) {
      setErrors((s) => ({ ...s, email: "Email is required" }));
      return false;
    } else if (!validator.isEmpty(email) && !validator.isEmail(email)) {
      setErrors((s) => ({ ...s, email: "Invalid email address" }));
      return false;
    } else {
      setErrors((s) => ({ ...s, email: "" }));
    }

    if (validator.isEmpty(password)) {
      setErrors((s) => ({ ...s, password: "Password is required" }));
      return false;
    } else {
      setErrors((s) => ({ ...s, password: "" }));
    }

    if (!validator.isEmpty("" + password)) {
      if (
        !validator.isLength("" + password, { min: 8 }) ||
        !validator.isLength("" + password, { max: 16 }) ||
        !/[!@#$%^&*?_~]{1,}/.test(password) ||
        !/[a-z]{1,}/.test(password) ||
        !/[A-Z]{1,}/.test(password) ||
        !/[0-9]{1,}/.test(password)
      ) {
        setErrors((s) => ({
          ...s,
          password:
            "Password must have atleast 8-16 characters, should contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric",
        }));
        setErrors((s) => ({ ...s, matchPass: "" }));
        return false;
      } else {
        setErrors((s) => ({ ...s, password: "" }));
      }
    }
    return true;
  };

  // useEffect(() => {}, [passwordToggle]);
  return (
    <React.Fragment>
      <section class="login-section">
        <div class="container-fluid">
          <div class="row 0">
            <div class="col-md-6 signup-page plr-70">
              <nav class="navbar navbar-expand-lg">
                <div className="navbar-brand">
                  <a href={`${GUEST_LINK}`}>
                    <img
                      src="/assets/images/logo.png"
                      style={{ height: 60, width: 55 }}
                      alt="logo"
                    />
                  </a>
                </div>
              </nav>
              <div class="heading pt-5">
                <h2 class="heading-grey text-left ">Welcome Back ! </h2>
                <h2 class="al-text pt-4 text-left">
                  Didn't have an account?{" "}
                  <a href={`${CUSTOMER_LINK}/signUp`}>Sign up</a>
                </h2>
              </div>

              <form class="subscribe-form contact-details " autoComplete="off">
                <div class="row">
                  <div class="col-md-12 pt-4">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="material-icons-outlined">
                          mail_outline
                        </span>
                      </div>
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Email Id"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <ErrorShow errorMsg={errors.email} />
                  </div>
                  <div class="col-md-12 pt-4">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="material-icons-outlined">lock</span>
                      </div>
                      <input
                        type={togglee ? "text" : "password"}
                        class="form-control"
                        id="password"
                        value={password}
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />

                      <div
                        class="input-group-append password"
                        onClick={handleShowPassword}
                      >
                        <span
                          class="material-icons-outlined"
                          id="togglePassword"
                          style={{ cursor: "pointer" }}
                        >
                          visibility
                        </span>
                      </div>
                    </div>
                    <ErrorShow errorMsg={errors.password} />
                  </div>
                  <div class="col-md-12 pt-4">
                    <div class="input-group">
                      <div class="input-group-prepend pt-3">
                        <div class="checkmark pl-3">
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            checked={cookieChecked}
                            onChange={(e) => setCookieChecked(!cookieChecked)}
                          />
                        </div>

                        <label for="vehicle1" class="check-para">
                          Keep me logged in
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group cpatcha p-3 mt-4">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="checkmark"></div>
                      <ReCAPTCHA
                        class="text-black check-para login-para pl-3"
                        sitekey={SITE_KEY}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="btn-wrap col-auto pt-4"
                  style={{ cursor: "pointer" }}
                >
                  {isLoading ? (
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <a
                      class="red-btn-lg hvr-icon-push contact-button"
                      onClick={loginCustomer}
                    >
                      {" "}
                      Log In
                    </a>
                  )}
                </div>
                <p class="resend-code text-center pt-4">
                  <span
                    style={{
                      color: "#2d53a0",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    onClick={setShowModal}
                  >
                    Forgot Password?
                  </span>
                </p>
              </form>
            </div>
            <div class="col-md-6 p-0">
              <div class="banner-signup">
                <img
                  src="assets/images/login-image.png"
                  alt="img"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ForgotPassword isModal={isModal} onClose={() => setShowModal(false)} />
      {/* <ForgotPass /> */}
    </React.Fragment>
  );
};
export default withCookies(Login);
