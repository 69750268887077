import React from 'react'

const SubcribeForm = ({onSubmitted}) => {

    const [fields,setFields] = React.useState({email:"",name:""})

    const submit = (e)=>{
        e.preventDefault()
        onSubmitted({EMAIL:fields.email,NAME:fields.name})
        setFields({email:"",name:""})
    }

    return (
        <form class="subscribe-form" onSubmit={submit} autoComplete='off'>
            <div class="row form-row">
                <div class="col-md-4">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="material-icons-outlined">person_outline</span>
                        </div>
                        <input type="text" class="form-control" onChange={(e)=>setFields((f)=>({...f,name:e.target.value}))} value={fields.name} name="NAME" placeholder="Enter your name..." />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="material-icons-outlined">mail_outline</span>
                        </div>
                        <input type="email" class="form-control" onChange={(e)=>setFields((f)=>({...f,email:e.target.value}))}  value={fields.email} name="EMAIL" placeholder="Enter your email id address..." />
                    </div>
                </div>
            </div>
            <div class="btn-wrap col-auto">
                <button type="submit" class="submit-btn common-btn red-btn-lg hvr-icon-push"><span class="material-icons-outlined hvr-icon">drafts</span>Subscribe Now!</button>
            </div>
        </form>
    )
}

export default SubcribeForm
