import React from 'react'

const Banner = () => {
  return (
    <section className="hero-banner hero-banner-height" style={{ backgroundColor: 'black' }}>
                <div className="container">
                    <div className="row">
                        <div className="hero-banner-aboutus hero-banner-content">
                            <h2 className="heading-white ">
                                Order Confirmation

                            </h2>
                            <p className="desc-white pt-4 text-center">
                                Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis fermentum
                                justo,
                                sit amet convallis arcu
                                interdum vel. Suspendisse vulputate posuere risus, quis consectetur neque scelerisque.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
  )
}

export default Banner