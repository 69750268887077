/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import React,{useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
import callApi from "../../service/callApi";
import serverApi from "../../api.json";

const Footer = () => {
    const [categories,setCategories] = useState([])

    useEffect(async()=>{
        await callApi.get(serverApi.Top5categories)
        .then(({data})=>{
            if(data && data?.data){
              setCategories(data?.data)
            }else{
             setCategories([])
            }
        }).catch((e)=>{
            console.log(e)
        })
     },[])

    return (
        <footer className="site-footer pt_90">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="footer-logo-wrap">
                        <a href="#"><img src="/assets/images/logo.png" alt="logo" /></a>
                        <p className="footer-desc">
                            Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis fermentum
                            justo, sit amet convallis arcu interdum vel. Suspendisse vulputate posuere risus, quis
                            consectetur neque scelerisque.
                        </p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-2">
                    <div className="page-links">
                        <h2 className="footer-heading">
                            Navigation
                        </h2>
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/contact_us">Contact Us</Link>
                            </li>
                            <li>
                                <Link to="/about_us">About Us</Link>
                            </li>
                            <li>
                                <Link to="/tnc">Terms and Conditions</Link>
                            </li>
                            <li>
                                <Link to="/privacy_policy">Privacy Policy</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-2">
                    <div className="page-links">
                        <h2 className="footer-heading">
                            Categories
                        </h2>
                        <ul>
                        {categories?.length>0?categories.map((c,i)=>(
                            <li key={i}>
                                <Link to={`/category_listing?category=${c?.Category}`}><a>{c?.Category}</a></Link>
                            </li>
                        )):null}
                        </ul>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="page-links contact-info">
                        <h2 className="footer-heading">
                            Location
                        </h2>
                        <ul>
                            <li>
                                <p>
                                    <span>Office:</span>
                                    Praesent non pharetra nulla
                                </p>
                            </li>
                            <li>
                                <p>
                                    <span>Address:</span>
                                    1293 Raoul Wallenberg Place
                                    Bloomfield, Connecticut
                                    06002
                                </p>
                            </li>
                            <li>
                                <p>
                                    <span>Phone no:</span>
                                    <a href="tel:203-809-0822">203-809-0822</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <span>Email Id:</span>
                                    <a href="mailto:7x1enq@temporary-mail.net">7x1enq@temporary-mail.net</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                <span>Social Media:</span>{" "}
                                  <a href="https://www.facebook.com" target={"_blank"} rel='noreferrer' style={{fontSize:30}}><i className="fa fa-facebook-square ml-1 mr-3"></i></a>  
                                  <a href="https://www.twitter.com" target={"_blank"} rel='noreferrer' style={{fontSize:30}}><i className="fa fa-twitter-square ml-1 mr-3"></i></a>
                                  <a href="https://www.linkedin.com" target={"_blank"} rel='noreferrer' style={{fontSize:30}}><i className="fa fa-linkedin-square ml-1 mr-3"></i></a>
                                  <a href="https://www.instagram.com" target={"_blank"} rel='noreferrer' style={{fontSize:30}}><i className="fa fa-instagram ml-1"></i></a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="pt_90">
                        <div className="footer-bottom-link">
                            <p>© 2021 Playit. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    )
}

export default Footer
