import React, { useState, useEffect } from "react";

import RestPassowrd from "./resetPass";
import callApi from "../service/callApi";
import serverApi from "../api.json";
const OtpModal = (data) => {
  // console.log(data);
  let [otp1, setOtp1] = useState("");
  let [otp2, setOtp2] = useState("");
  let [otp3, setOtp3] = useState("");
  let [otp4, setOtp4] = useState("");
  const [areaOfModal, setAreaModal] = useState(true);
  const [optError, setOtpError] = useState(false);
  const [errorOtp, setErrorOtp] = useState("");
  const [successfully, setSuccess] = useState(false);

  const [counter, setCounter] = useState(62);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const otpVerification = (e, index) => {
    // console.log(e, index);
    if (index == 1) {
      setOtp1(e);
    } else if (index == 2) {
      setOtp2(e);
    } else if (index == 3) {
      setOtp3(e);
    } else {
      setOtp4(e);
    }
  };
  const verifyOtp = async () => {};

  const resendOtp = async () => {
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    setErrorOtp("");
    setOtpError(false);

    let otp;
    otp = await callApi.post(serverApi.sendOtp, {
      phone: data.phone,
    });
  };

  const resetOtp = () => {
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    setErrorOtp("");
    setOtpError(false);
  };

  const ChangePassword = () => {
    // console.log("Clicked");
  };

  return (
    <React.Fragment>
      <div
        class="modal fade"
        id="otp-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <a
              href="#"
              data-toggle="modal"
              data-target="#forgot-pwd"
              data-dismiss="modal"
            >
              <span class="material-icons-outlined text-danger pt-2 pl-3">
                arrow_back
              </span>
            </a>

            <button
              type="button"
              class="close close-icon"
              data-dismiss="modal"
              aria-label="Close"
              onClick={resetOtp}
            >
              <span class="material-icons-outlined">close</span>
            </button>

            <div class="modal-body">
              <div class="d-flex justify-content-center align-items-center">
                <div className="card login-card py-5 px-3">
                  {successfully ? null : (
                    <h5 className="m-0 text-center modal-heading">
                      Enter Your Verification code
                    </h5>
                  )}

                  {optError ? (
                    <h5 className="m-0 text-center modal-heading text-danger">
                      {errorOtp}
                    </h5>
                  ) : (
                    <h5 className="m-0 text-center modal-heading text-success">
                      {errorOtp}
                    </h5>
                  )}

                  {successfully ? null : (
                    <div>
                      <span className="mobile-text text-center pt-4">
                        We sent it on your mobile phone
                        <br /> <b className="text-dark pt-4">{data.phone}</b>
                      </span>
                      <div className="">
                        <div className="d-flex flex-row pt-4 text-center otp-input">
                          <input
                            type="text"
                            className="form-control text-center "
                            placeholder="3"
                            autoFocus=""
                            maxLength="1"
                            value={otp1}
                            onChange={(e) => otpVerification(e.target.value, 1)}
                          />
                          <input
                            type="text"
                            className="form-control"
                            placeholder="3"
                            maxLength="1"
                            value={otp2}
                            onChange={(e) => otpVerification(e.target.value, 2)}
                          />
                          <input
                            type="text"
                            className="form-control"
                            placeholder="3"
                            maxLength="1"
                            value={otp3}
                            onChange={(e) => otpVerification(e.target.value, 3)}
                          />
                          <input
                            type="text"
                            className="form-control"
                            placeholder="3"
                            maxLength="1"
                            value={otp4}
                            onChange={(e) => otpVerification(e.target.value, 4)}
                          />
                        </div>
                      </div>
                      <p className="text-danger desc-grey" id="timer">
                        {counter} Sec
                      </p>
                      <div className="btn-wrap col-auto pt-4">
                        <button
                          className="red-btn-lg hvr-icon-push contact-button profile-btn"
                          data-toggle="modal"
                          data-target={""}
                          class="red-btn-lg hvr-icon-push contact-button profile-btn"
                          data-dismiss={""}
                          aria-label="Close"
                          onClick={() => ChangePassword()}
                        >
                          Verify and proceed
                        </button>
                      </div>
                      <div className="text-center pt-4 resend-code">
                        <span className="d-block mobile-text">
                          Didn't receive the code?
                        </span>
                        <button
                          type="submit"
                          className="#"
                          onClick={() => resendOtp()}
                        >
                          Resend code
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RestPassowrd />
    </React.Fragment>
  );
};
export default OtpModal;
