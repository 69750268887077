/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import callApi from "../../service/callApi";
import serverApi from "../../api.json";
import Autocomplete from "react-google-autocomplete";
import {MAP_KEY} from '../../config'

const CategoryOption = ({ searchData, selectedFilter }) => {

    const [categories, setCategories] = useState([])
    const [filter, setFilter] = useState({ category: selectedFilter?.category ? selectedFilter?.category : "", location: selectedFilter?.location ? selectedFilter?.location : "" })

    useEffect(() => {
        if (selectedFilter?.category) {
            setFilter((f) => ({ ...f, category: selectedFilter?.category }))
        }
        if (selectedFilter?.location) {
            setFilter((f) => ({ ...f, location: selectedFilter?.location }))
        }
    }, [selectedFilter?.category, selectedFilter?.location])

    const categoryHandler = (e) => {
        setFilter((s) => ({ ...s, [e.target.name]: e.target.value }))
    }

    useEffect(async () => {
        await callApi.get(serverApi.categoriesList)
            .then(({ data }) => {
                if (data && data?.data?.length > 0) {
                    setCategories(data?.data)
                } else {
                    setCategories([])
                }
            }).catch((e) => {
                console.log(e)
            })
    }, [])

    const submitSearch = (e) => {
        e.preventDefault()
        searchData(filter)
    }

    const placeChange = (place)=>{
        if(place?.type=='change'){
            setFilter((s) => ({ ...s, location: place.target.value,latValue:'',lngValue:'' }))
        }else{
        // console.log( 'plc', place );
		const address = place.formatted_address,
		      latValue = place.geometry.location.lat(),
		      lngValue = place.geometry.location.lng();
		// Set these values in the state.
        setFilter((s) => ({ ...s, location: address,latValue,lngValue }))
        }
    }



    return (
        <div class="col-md-12">
            <div class="search-form-wrap container_sm">
                <form class="search-form display-margin-neg" autoComplete='off' onSubmit={submitSearch}>
                    <div class="row form-row">
                        <div class="col-md-5">
                            <div class="form-group">
                                <label>
                                    <span class="label-icon">
                                        <span class="material-icons-outlined">sports_soccer</span>
                                    </span>
                                    Category
                                </label>
                                <div class="select-wrap">
                                    <select class="form-select" name="category" onChange={categoryHandler} value={filter?.category} defaultValue={""}>
                                        <option value="">Game you love to play?</option>
                                        {categories?.length > 0 && categories.map((option, index) => <option value={option?.Category} key={index}>{option?.Category}</option>)}
                                    </select>
                                    <span class="material-icons-outlined arrow-down-icon">arrow_drop_down</span>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-1">
                            <div class="seprator">
                                <img src="assets/images/seprator.png" alt="img" />
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <label>
                                    <span class="label-icon">
                                        <span class="material-icons-outlined">place</span>
                                    </span>
                                    Location
                                </label>

                                <Autocomplete
                                   className="form-control" placeholder="Enter your location..."
                                    apiKey={MAP_KEY}
                                    onPlaceSelected={ placeChange }
                                    onChange={ placeChange }
                                    defaultValue={filter?.location}
                                    types={['(regions)']}
                                />
                                {/* <input type="text" name="location" onChange={categoryHandler} value={filter?.location} class="form-control" placeholder="Enter your location..." /> */}
                            </div>
                        </div>
                        <div class="btn-wrap">
                            <button type="submit" class="search-btn red-btn hvr-icon-push">
                                <span class="material-icons-round hvr-icon">search</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CategoryOption
