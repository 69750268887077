/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import './PrintModal.css'
import { downloadHTMLPDF, getUserInfo } from '../../helpers'
import TimeDateItem from '../OrderConfirmation/TimeDateItem'

const PrintModal = ({ isModal, handleToggle }) => {
    // console.log("isModal",isModal)
    useEffect(() => {
        return () => {
            handleToggle()
        }
    }, [])

    const user = getUserInfo()

    return (
        <Modal isOpen={isModal != false} toggle={handleToggle} style={{ maxWidth: 800 }}>
            <ModalBody>
                <button
                    type="button"
                    class="close-icon close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleToggle}
                >
                    <span class="material-icons-outlined">close</span>
                </button>
                <div className="container-fluid">
                    <div className="row">
                        <div className="top-section">
                            <h5 className="heading-grey prof-heading text-left">Print details</h5>
                            <p className="desc-grey text-left pt-4">Booking details</p>
                        </div>
                        <div class="table-responsive" id='printBox'>
                            <div className="invoice-box">
                                <table cellpadding="0" cellspacing="0">
                                    <tr className="top">
                                        <td colspan="2">
                                            <table>
                                                <tr>
                                                    <td className="title">
                                                        <img src={"/assets/images/logo.png"} style={{ width: '100%', maxWidth: 50 }} />
                                                    </td>

                                                    <td><br />
                                                        Created on: {moment().format('DD-MM-YYYY')}<br />
                                                        Booked on: {moment(isModal?.CreationDate).format('DD-MM-YYYY')}
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr className="information">
                                        <td colspan="2">
                                            <table>
                                                <tr>
                                                    <td>
                                                        {isModal?.facilitiesDetails?.Name},<br />
                                                        {isModal?.facilitiesDetails?.Address}
                                                    </td>

                                                    <td>
                                                        {`${user?.customerDetails?.firstName} ${user?.customerDetails?.lastName}`}, <br />
                                                        {user?.customerDetails?.email}
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                                    {/* <tr className="heading">
                                        <td>Booked Date/Slots</td>
                                        <td></td>
                                    </tr> */}

                                    <tr className="details">
                                        <td>
                                            {
                                                isModal?.slots? (
                                                    <div className='response-table'>
                                                        {(isModal?.slots && Object.keys(isModal?.slots)?.length != 0) ? (
                                                            <table className='table' >
                                                                <tr style={{background:'#eee'}}>
                                                                    <th className='text-left' style={{width:135}}>Booked Dates</th>
                                                                    <th className='text-left'>Time Slots</th>
                                                                </tr>
                                                                {Object.entries(isModal?.slots)?.map((s, index) => <TimeDateItem item={s} key={index} />)}
                                                            </table>
                                                        ) : null}
                                                    </div>
                                                ) : null
                                            }
                                            {/* {isModal?.slots ? Object.entries(isModal?.slots)?.map((s, index) => <TimeDateItem item={s} key={index} />) : null} */}
                                        </td>
                                    </tr>

                                    <tr className="heading">
                                        <td>Payment Method</td>
                                        <td></td>
                                    </tr>

                                    <tr className="details">
                                        <td>{isModal?.paymentMode}</td>
                                    </tr>

                                    <tr className="heading">
                                        <td>Payment Details</td>

                                        <td>Price</td>
                                    </tr>

                                    <tr className="item">
                                        <td>{isModal?.serviceClassDetails?.type == 'service' ? 'Service' : 'Class'} - {isModal?.serviceClassDetails?.Name}</td>

                                        <td>{`QR ${(parseFloat(isModal?.paymentDetails?.PurchaseCost))?.toFixed(2)}`}</td>
                                    </tr>

                                    {/* <tr className="item">
                                        <td>Actual Cost</td>

                                        <td>{`QR ${(isModal?.serviceClassDetails?.Price)?.toFixed(2)}`}</td>
                                    </tr> */}

                                    <tr className="item last">
                                        <td>Discount</td>

                                        <td>{`${isModal?.paymentDetails?.DiscountApplied ? isModal?.paymentDetails?.DiscountApplied : 0} %`}</td>
                                    </tr>

                                    <tr className="total">
                                        <td></td>

                                        <td>Total: {`QR ${(isModal?.Price)?.toFixed(2)}`}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='mb-3 mt-3' style={{ marginRight: 'auto', marginLeft: 'auto', display: 'block' }}>
                            <button className="red-btn-lg hvr-icon-push checkout-btn" onClick={() => downloadHTMLPDF('printBox')}>Save As PDF</button>
                        </div>
                    </div>
                </div>

            </ModalBody>

        </Modal>
    )
}

export default PrintModal