import React from 'react'

const IntakeQues = ({num,ques,answer}) => {
    return (
        <div className="col-md-12 text-left">
            <label className="p-0 mt-4 m-0"> {num}. {ques}</label>
            <div className="input-group">
                <input type="text" className="form-control pl-0" maxLength={150} placeholder="Enter your Answer here (Max 150 characters only)"  onChange={(e)=>answer(e.target.value)} />
            </div>
        </div>
    )
}

export default IntakeQues