import React from 'react';
import { Link } from 'react-router-dom';
import { CUSTOMER_LINK, GUEST_LINK } from '../../config';
import { isAuth } from '../../helpers';

const NotLoggedInNav = ({categories}) => {

    const dropdownToggle=()=>{
        document.getElementById("dropdownMenu").classList.toggle("show");
        document.getElementById("navDropdownLink").classList.toggle("active_menu");
      }

      const  toggleMenu=()=>{
        // toggle menu-js
        var mobileMenu = document.getElementById("siteMenu");
        mobileMenu.classList.toggle("OpenNav");
        // toggle overflow js
        var body = document.body;
        body.classList.toggle("overflow_hidden");
        // toggle button animate js
        var togglemenuIcon = document.getElementById("menuIcon");
        togglemenuIcon.classList.toggle("active");
    }

    return (
        <nav className="navbar navbar-expand-lg">
             <div className="navbar-brand">
                {!isAuth()?<a href={`${GUEST_LINK}`}><img src="/assets/images/logo.png" style={{height: 60,width: 55}} alt="logo" /></a>:<Link to={"/"}><img src="/assets/images/logo.png" style={{height: 60,width: 55}} alt="logo" /></Link>}
             </div>
            <button className="toggleMenu" onClick={toggleMenu}>
                <span className="menu-icon move-left close-icon" id="menuIcon">
                    <span></span>
                </span>
            </button>
            <div className="site-menu" id="siteMenu">
                <ul className="navbar-nav ml-auto" style={{textAlign:'justify'}}>
                    <li className="nav-item">
                        <Link to={"/"}><a className="nav-link">Home</a></Link>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle dropbtn" onClick={dropdownToggle} href="#" id="navDropdownLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Categories <span className="material-icons-outlined arrow-down-icon">arrow_drop_down</span>
                        </a>

                        <div className="dropdown-menu dropdown-content" id="dropdownMenu" aria-labelledby="navDropdownLink">
                            {categories?.length>0?categories?.map((c,index)=><Link to={`/category_listing?category=${c?.Category}`} key={index}><span className="dropdown-item">{c?.Category}</span></Link>):null}
                        </div>
                    </li>
                    <li className="nav-item">
                    <Link to={"/contact_us"}><a className="nav-link">Support</a></Link>
                    </li>
                    <li className="nav-item">
                    <a href={`${CUSTOMER_LINK}/login`} className="nav-link login-link">Login</a>
                    </li>
                    <li className="nav-item">
                    <a href={`${CUSTOMER_LINK}/signUp`} className="nav-link signup-link red-btn">Sign Up</a>
                    </li>
                </ul>
            </div>
        </nav>
    )
};

export default NotLoggedInNav;
