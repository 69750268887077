import React, { useEffect } from 'react'
import {isAuth} from '../helpers'
import  { useNavigate } from 'react-router-dom'


const PrivateComp = ({children})=>{

    const navigate = useNavigate()

    useEffect(()=>{
        if(!isAuth()){
            navigate('/login')
        }
    },[isAuth()])

    return (
        <>
            {isAuth() && children}
        </>
    )
}

export default PrivateComp