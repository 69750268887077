import React,{useEffect,useState} from 'react';
import callApi from '../../service/callApi'
import serverApi from '../../api.json'
import { toast } from 'react-toastify';

const SportItem = ({sport}) => {
   
   let [isStar,setIsStart] = useState(false)
   let [isLoading,setLoader] = useState(false)

   useEffect(()=>{
       if(sport?.favoritesports && sport?.favoritesports?.length>0){
        sport?.favoritesports?.forEach((s) => {
            if(s?.sport_id==sport?._id){
                setIsStart(true)
            }
       })
     }
   },[])

   const onFavorite = async(isSport_id)=>{
    setLoader(true)
    await callApi.post(`${serverApi?.addRemovefavoritesport}/${isSport_id}`)
    .then(({ data }) => {
    setLoader(false)
        setIsStart(!isStar)
        if(data &&  data?.message){
            toast.success(`${sport?.Category} - ${data?.message}`)
        }
    }).catch((e) => {
    setLoader(false)
        console.log(e)
    })
 }
   
    return (
        <div className="col-md-4 mt-3 mb-3">
            <div className="sports-list-card" disabled={isLoading} >
                <div className="sports-img">
                    <figure>
                        <img src={`${serverApi?.base_url}/${sport?.ProfileImage}`} alt="img" className="img-fluid" />
                    </figure>
                </div>
                <div className="sports-info">
                    <h3 className="sports-name text-left">{sport?.Category}</h3>
                    <div className="sports-icon d-flex p-0" style={{cursor:'pointer'}} onClick={()=>onFavorite(sport?._id)}>
                        <p className="sports-location text-left p-0">{sport?.description}</p>
                        <span className="material-icons-outlined star-icon">
                            grade
                        </span>
                        {isStar ? <span className="material-icons-outlined filled-icon fav-icon">star</span>:null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SportItem;
