import React,{useState} from 'react'
import Header from '../Layouts/Header'
import Footer from '../Layouts/Footer'
import validator from 'validator'
import ErrorShow from '../Forms/ErrorShow'
import WhatsAppUI from '../Layouts/WhatsAppUI'
import callApi from '../../service/callApi'
import api from '../../api.json'
import { toast } from 'react-toastify'
import Loader from '../Layouts/Loader'
//PrivacyPolicy component


const ContactUs = () => {
    const inital = {
        name:"",email:"",subject:"",message:""
    }
    const [state,setState] = useState(inital)
    const [isLoader,setLoader] = useState(false)
    const [errors,setErrors] = useState({
        name:"",email:"",subject:"",message:""
    })

    const validFields =()=>{
        if((state?.name+"")?.trim()?.length==0){
          setErrors((s)=>({...s,name:"Full name is required"}))
        }else if(!validator.isEmpty(state?.name) && !validator.isAlpha(state?.name)){
          setErrors((s)=>({...s,name:"Full name must be alphabet"}))
        }else{
          setErrors((s)=>({...s,name:""}))
        }

        if(validator.isEmpty(state?.email)){
            setErrors((s)=>({...s,email:"Email is required"}))
          }else if(!validator.isEmpty(state?.email) && !validator.isEmail(state?.email)){
            setErrors((s)=>({...s,email:"Invalid email address"}))
          }else{
            setErrors((s)=>({...s,email:""}))
          }

          if((state?.subject+"")?.trim()?.length==0){
            setErrors((s)=>({...s,subject:"Subject is required"}))
          }else{
            setErrors((s)=>({...s,subject:""}))
          }

          if((state?.message+"")?.trim()?.length==0){
            setErrors((s)=>({...s,message:"Message is required"}))
          }else{
            setErrors((s)=>({...s,message:""}))
          }
    }

    const isValid=()=>{
        let valid = true
        console.log('errors',errors)
        Object.keys(errors).forEach((key)=>{
          if(errors[key]){
            valid = false
            return;
          }
        })
        return valid
      }

      const sendMessage=async()=>{
        if(isValid()){
            try{
                setLoader(true)
               const res = await callApi.post(api.customer_contact_us,state)
            //    console.log('res',res)
               if(res?.data && res?.status==200){
                   setState(inital)
                   toast.success(res?.data?.message)
               }else{
                   toast.error("Failed to send the message!")
               }
               setLoader(false)
            }catch(e){
               setLoader(false)
                toast.error('Failed to send the message!')
                console.log(e)
            }
        }
      }

    const submitHandler =(e)=>{
        e.preventDefault()
        validFields()
        setTimeout(()=>sendMessage(),100)
    }

    return (
        <div>
            <Header />
            <section className="hero-banner hero-banner-contact ptb-90" style={{backgroundImage: "url(assets/images/about-us.jpg)"}}>
                <div className="container">
                    <div className="row">
                        <div className="hero-banner hero-banner-content hero-banner-contact">
                            <h2 className="heading-white">
                                Contact Us
                            </h2>
                            <div className="desc-white pt-4">
                                <p className="text-center">
                                    Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="contact-us-sec desktop-view plr-70">
                        <div className="row">

                            <div className="col-md-4 get-in-touch p-0">
                                <div className="text-left plr-50">
                                    <h4 className="heading-white heading-get-in text-left">
                                        Get In Touch
                                    </h4>
                                    <div className="desc-white  pt-4 ">
                                        <p>
                                            Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis
                                        </p>
                                    </div>

                                    <div className="text-white d-flex pt-4">
                                        <span className="material-icons-outlined">
                                            phone
                                        </span>
                                        <a href="tel:2038090822">2038090822</a>

                                    </div>
                                    <div className="text-white d-flex pt-4">
                                        <span className="material-icons-outlined">mail_outline</span>
                                        <a href="mailto:7x1enq@temporary-mail.net">7x1enq@temporary-mail.net</a>
                                    </div>
                                    <div className="text-white d-flex pt-4">
                                        <span className="material-icons-outlined">
                                            location_on
                                        </span>
                                        <a href="#">505 Pinnickinnick Street
                                            Wayne, New Jersey,
                                            Postal code	07477</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 p-0">
                                <div className="contact-form  plr-50">
                                    <form className="subscribe-form" onSubmit={submitHandler}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="material-icons-outlined">person_outline</span>
                                                    </div>
                                                    <input type="text" value={state.name} onChange={(e)=>setState((s)=>({...s,name:e.target.value}))} className="form-control" placeholder="Full Name " required/>
                                                </div>
                                                <ErrorShow errorMsg={errors?.name}/>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="material-icons-outlined">mail_outline</span>
                                                    </div>
                                                    <input type="email" value={state.email} onChange={(e)=>setState((s)=>({...s,email:e.target.value}))} className="form-control" placeholder="Email Id" required/>
                                                </div>
                                                <ErrorShow errorMsg={errors?.email}/>
                                            </div>
                                        </div>

                                        <div className="row pt-4">
                                            <div className="col-md-12">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="material-icons-outlined">
                                                            class
                                                        </span>
                                                    </div>
                                                    <input type="text" value={state.subject} onChange={(e)=>setState((s)=>({...s,subject:e.target.value}))} className="form-control" placeholder="Subject" required/>
                                                </div>
                                                <ErrorShow errorMsg={errors?.subject}/>

                                            </div>
                                        </div>
                                        <div className="row pt-4">
                                            <div className="col-md-12">
                                                <div className="input-group">
                                                    <div className="input-group-prepend message-box">
                                                        <span className="material-icons-outlined">
                                                            message
                                                        </span>
                                                    </div>
                                                    <textarea value={state.message} onChange={(e)=>setState((s)=>({...s,message:e.target.value}))} className="form-control textarea-box" rows="3" placeholder="Message" required></textarea>
                                                </div>
                                                <ErrorShow errorMsg={errors?.message}/>
                                            </div>
                                        </div>
                                        <div className="btn-wrap col-auto pt-5">
                                            {isLoader?<Loader/>:<button type="submit" className="red-btn-lg hvr-icon-push contact-button"> Submit Now!</button>}
                                            
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="subscribe-wrap contact-us-sec mobile-view plr-70">
                <div className="container">
                    <div className="row contact-us-sec mobile-hide plr-70">

                        <div className="col-md-4">
                            <div className="get-in-touch text-left plr-50">
                                <h4 className="heading-white heading-get-in text-left">
                                    Get In Touch
                                </h4>
                                <div className="desc-white pt-4">
                                    <p>
                                        Fusce eu odio eget metus vulputate facilisis in non tellus. Phasellus facilisis
                                    </p>
                                </div>

                                <div className="text-white d-flex pt-4">
                                    <span className="material-icons-outlined">
                                        phone
                                    </span>
                                    <a href="tel:2038090822">2038090822</a>

                                </div>
                                <div className="text-white d-flex pt-4">
                                    <span className="material-icons-outlined">mail_outline</span>
                                    <a href="mailto:7x1enq@temporary-mail.net">7x1enq@temporary-mail.net</a>
                                </div>
                                <div className="text-white d-flex pt-4">
                                    <span className="material-icons-outlined">
                                        location_on
                                    </span>
                                    <a href="#">505 Pinnickinnick Street
                                        Wayne, New Jersey,
                                        Postal code	07477</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form  plr-50">
                                <form className="subscribe-form contact-details">
                                    <div className="row ">
                                        <div className="col-md-6">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="material-icons-outlined">person_outline</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder="Full Name" required/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="material-icons-outlined">mail_outline</span>
                                                </div>
                                                <input type="email" className="form-control" placeholder="Email Id" required/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="input-group pt-4">
                                                <div className="input-group-prepend">
                                                    <span className="material-icons-outlined">class</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder="Subject" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="input-group pt-4">
                                                <div className="input-group-prepend message-box">
                                                    <span className="material-icons-outlined">
                                                        message
                                                    </span>
                                                </div>
                                                <textarea className="form-control textarea-box" rows="3" placeholder="Message" required></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn-wrap col-auto pt-5">
                                        <button type="submit"  className="red-btn-lg hvr-icon-push contact-button"> Submit Now!</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
            <WhatsAppUI/>
            <Footer />
        </div>
    )
}

export default ContactUs
