import React from 'react';
import { Link } from 'react-router-dom';
import EminitiesItem from './EminitiesItem';
import moment from 'moment'
import api from '../../api.json'
import {daysArray} from '../../helpers'

const ServiceClassItem = ({item,selectedFilter}) => {
    // console.log("selectedFilter",selectedFilter);
    // console.log("myTime",moment().valueOf());
    // console.log("COmpare",item?.facilitiesDetails?.timeNumber?.end>=moment().valueOf());
    // console.log("day",new Date().getDay(),daysArray[new Date().getDay()])

    const style = {
        textAlign:'center',
        background: '#ca0f2c',
        color: '#ffffff',
        padding: 1,
        fontWeight: 'bold',
        borderRadius: '0px 0px 5px 5px',
        position: 'relative',
        top: -23,
        fontSize: 14
    }

    const checkOpenOrClose = ()=>{
        let status = false
        const currentDateTime = new Date(2022,0,1,new Date().getHours(),new Date().getMinutes())
        console.log("currentDateTime",currentDateTime)
        if(item?.facilitiesDetails?.length!==0 && item?.facilitiesDetails[0]?.TimeSetting && item?.facilitiesDetails[0]?.TimeSetting?.length!==0){
            item?.facilitiesDetails[0]?.TimeSetting?.forEach(e => {
                const day = daysArray[new Date().getDay()]
                console.log("item",item)
                if(day==e?.name){
                    const times = e?.time
                    if(times?.length>0){
                        times.forEach((t)=>{
                            if(new Date(t?.start_mil)<=currentDateTime && new Date(t?.end_mil)>=currentDateTime){
                                status=true
                                return
                            }else if(new Date(t?.end_mil)<=currentDateTime){
                                status=false
                                return
                            }
                        })
                    }
                }
            });
        }
        if(selectedFilter?.status){
            if(selectedFilter?.status=='close'){
                status=false
            }else if(selectedFilter?.status=='open'){
                status=true
            }
        }
        return status
    }

    const redirectToMap = item?.facilitiesDetails?.length>0?`http://maps.google.com?q=${item?.facilitiesDetails[0]?.MarkerPosition?.lat},${item?.facilitiesDetails[0]?.MarkerPosition?.lng}&zoom=12`:"http://maps.google.com"

  return (
    <div className="col-md-12 col-lg-4 pb-4">
    <div className="sports-list-card bookingListcard">
        <div className="sports-img">
            <figure>
                <img src={(item?.Images && item?.Images?.length>0 && item?.Images[0])?`${api.base_url}/${item?.Images[0]}` :"assets/images/image-01.png"} alt="img" className="img-fluid" />
            </figure>
        </div>
        {checkOpenOrClose()?<button type="button" className="blue-btn-lg hvr-icon-push checkout-btn search-list-btn">Open</button>:<button type="button" className="red-btn-lg hvr-icon-push checkout-btn search-list-btn">Close</button>}
        <div className="sports-info">
            { moment(item?.EndDate).startOf('day')<=moment().startOf('day')?<div style={style}>Booking not available</div>:null}
            <h3 className="sports-name">{(item?.facilitiesDetails && item?.facilitiesDetails?.length>0 && item?.facilitiesDetails[0]?.Name)?item?.facilitiesDetails[0]?.Name:""} </h3>
            <p className="sports-location">{(item?.facilitiesDetails && item?.facilitiesDetails?.length>0 && item?.facilitiesDetails[0]?.Address)?item?.facilitiesDetails[0]?.Address:""}</p>
            <div className="location-area d-flex">
                <div className="col-md-8">
                    <div className="search-para text-left sports-location pr-0">
                        <p>Surface <br /><span>{(item?.facilitiesDetails && item?.facilitiesDetails?.length>0 && item?.facilitiesDetails[0]?.Court?.length!=0)?item?.facilitiesDetails[0]?.Court?.map((c)=>c?.Surface)?.join(", "):"---"}</span></p>
                    </div>
                </div>
                <div className="col-md-4 p-0">
                    <a href={redirectToMap} target={'_blank'}>
                    <div className="location_map d-flex">
                        <p>Location</p>
                        <div className="label-icon">
                            <span className="material-icons-outlined">place</span>
                        </div>
                    </div>
                    </a>
                </div>
            </div>
            <div className="sports-eminities">
                <ul>
                    {(item?.amenities && item?.amenities?.length>0)?item?.amenities?.map((item,key)=><EminitiesItem item={item} key={key}/>):null}
                </ul>
                <p className='search-para text-center'><span>{`${item?.type=='service'?'Service:':'Class:'} ${item?.Name}`}</span></p>
                
            </div>
            <div className="hidden-booking">
                <div className="sports-booking-wrap">
                    <div className="booking-content-left bg-red">
                        <h2 className="booking-price-heading">
                            Booking
                            <span>PRICE</span>
                        </h2>
                        <p className="price">QR <b>{(item?.Price)?.toFixed(2)}</b></p>
                    </div>
                    <div className="booking-content-right bg-blue">
                        <Link to={`/service_class_details/${item?._id}`}><a>Book Now ! <span className="material-icons-outlined arrow-right">east</span></a></Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
};

export default ServiceClassItem;
